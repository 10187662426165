export interface IMeasurementMobileProps {}

export const measurementType = [
  {
    measureType: "",
    name: "All",
  },
  {
    measureType: "PF",
    name: "Spirometer",
  },
  {
    measureType: "IN",
    name: "PT/INR",
  },
  {
    measureType: "PH",
    name: "Photos",
  },
  {
    measureType: "HB",
    name: "HbA1c",
  },
  {
    measureType: "GL",
    name: "Glucose",
  },
  {
    measureType: "EC",
    name: "ECG",
  },
  {
    measureType: "MS",
    name: "Digital Microscope",
  },
  {
    measureType: "CH",
    name: "Cholesterol",
  },
  {
    measureType: "CG",
    name: "Cholesterol",
  },
  {
    measureType: "TE",
    name: "Body Temperature",
  },
  {
    measureType: "BP",
    name: "Blood Pressure",
  },
  {
    measureType: "PF",
    name: "Spirometer",
  },
  {
    measureType: "OX",
    name: "SpO₂",
  },
  {
    measureType: "WR",
    name: "Steps",
  },
  {
    measureType: "ST",
    name: "Stethoscope",
  },
  {
    measureType: "UR",
    name: "Urinalysis",
  },
  {
    measureType: "WA",
    name: "Waist",
  },
  {
    measureType: "WE",
    name: "Weight",
  },
  {
    measureType: "AC",
    name: "Activity",
  },
  {
    measureType: "HR",
    name: "Heart",
  },
  {
    measureType: "BC",
    name: "Body Composition",
  },
  {
    measureType: "LI",
    name: "LifeMonito",
  },
];

export const measureItemText = [
  {
    measureType: "BP",
    measureItem: [{ text: "SYS." }, { text: "DIA." }, { text: "PUL." }],
    measureUnit: [{ text: "mmHs" }, { text: "mmHs" }, { text: "bpm" }],
  },
  {
    measureType: "CH",
    measureItem: [
      { text: "TC" },
      { text: "HDL" },
      { text: "LDL" },
      { text: "Trig." },
      { text: "TC/HDL" },
    ],
  },
  {
    measureType: "GL",
    measureItem: [{ text: "Glucose" }, { text: "HCT" }],
  },
  {
    measureType: "OX",
    measureItem: [{ text: "SpO₂" }, { text: "PUL." }],
  },
  {
    measureType: "TE",
    measureItem: [{ text: "TEMP" }],
  },
  {
    measureType: "UC",
    measureItem: [{ text: "UricAcid" }],
  },
  {
    measureType: "WE",
    measureItem: [{ text: "Weight" }],
  },
  {
    measureType: "BM",
    measureItem: [{ text: "Height" }, { text: "Weight" }, { text: "BMI" }],
  },

  {
    measureType: "BC",
    measureItem: [
      { text: "Weight" },
      { text: "BMI" },
      { text: "BodyFat" },
      { text: "BodyWater" },
    ],
  },
  {
    measureType: "CG",
    measureItem: [
      { text: "EtCO₂" },
      { text: "RR" },
      { text: "SpO₂" },
      { text: "Pulse" },
    ],
  },

  {
    measureType: "EC",
    measureItem: [{ text: "HR" }],
    measureItem2: [
      { text: "Vent. Rate" },
      { text: "PR Interval" },
      { text: "QRS Duration" },
    ],
  },
  {
    measureType: "IN",
    measureItem: [{ text: "INR" }, { text: "INR(sec.)" }, { text: "INR(%Q)" }],
    measureItem2: [{ text: "INR" }, { text: "PT(sec.)" }],
  },
  {
    measureType: "ST",
    measureItem: [{ text: "Reconding files" }],
  },
];
