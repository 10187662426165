import $ from "jquery";
import { DocotrDataProvider } from "../service/DocotrDataProvider";

import "moment/locale/ko";
import "moment/locale/es-us";
import "moment/locale/de";
import "moment/locale/th";
import "moment/locale/it";
import moment from "moment";
import * as _ from "lodash";

export class Common {
  public ResizePage(): void {
    var $select = $("select");
    var $menuAll = $(".menu-all");
    var $utilAll = $(".util-all");
    var $nav = $menuAll.parents("header").children("nav");
    var $utilList = $utilAll.siblings(".util-list");
    var $tabNav = $(".tab-nav li");
    var $slideOpen = $(".slide-open");
    var $tipIcon = $(".tip-icon");
    var $popUp = $(".pop-layer");
    var $popWrap = $(".pop-layer__wrap");
    var $layoutLink = $(".layout-thumb a");
    var $layoutCont = $(".video-call__cont");
    var $chatIcon = $(".icon-chat");
    var $chatClose = $(".chat-close");
    var $btn = $(".docBtn");
    var speed = 500;

    moment.locale("" + localStorage.getItem("lang"));
    // select focus
    $select.on("focus", function () {
      $(this).parents(".opt").addClass("focus");
    });
    $select.on("blur", function () {
      $(this).parents(".opt").removeClass("focus");
    });

    // tooltip
    $tipIcon.on("mouseover", function () {
      $(this).next(".tip").toggleClass("on");
    });
    $tipIcon.on("focus", function () {
      $(this).next(".tip").addClass("on");
    });
    $tipIcon.on("blur", function () {
      $(this).next(".tip").removeClass("on");
    });

    // menu-all
    $menuAll.on("click", function () {
      var isOn = $menuAll.hasClass("on");
      $menuAll.toggleClass("on");

      if (isOn !== true) {
        $nav.slideDown(speed);
      } else {
        $nav.slideUp(speed);
      }
    });

    // appointment slide button
    // $slideOpen.on("click", function () {
    //   console.log(this);
    //   var isOn = $(this).hasClass("on");
    //   var $slideCont = $(this).parents(".cont").siblings("footer");

    //   $(this).toggleClass("on");
    //   if (isOn !== true) {
    //     $slideCont.slideDown(speed);
    //   } else {
    //     $slideCont.slideUp(speed);
    //   }
    // });

    // 탭
    $tabNav.on("click", function () {
      var TabOn = $(this).attr("rel");
      $(this).parent().children("li").removeClass("on");
      $(this).addClass("on");
      $(this).parent().siblings(".tab-cont").removeClass("on");
      $("#" + TabOn).addClass("on");
    });

    $chatClose.on("click", function () {
      $chatIcon.removeClass("on");
      $layoutCont.removeClass("on");
    });

    $btn.hover(
      function () {},
      function () {
        $(this).blur();
      }
    );

    // 리사이즈시
    $(function () {
      var innerWidth: any;
      var chkPc: any;
      innerWidth = $(window).innerWidth();
      chkPc = innerWidth = innerWidth > 769 ? true : false;
      if (chkPc === true) {
        $(".wrap").addClass("pc");
      } else {
        $(".wrap").removeClass("pc");
      }

      popRespon();
    });

    // pop-layer 반응형 위치체크
    function popRespon() {
      var popWrapHT: any;
      var winHT: any;
      popWrapHT = $popWrap.innerHeight();
      winHT = $(window).innerHeight();

      if (popWrapHT >= winHT) {
        $popUp.css("height", "100%");
      } else if (popWrapHT < winHT) {
        $popUp.css("height", "auto");
      }
    }
  }
  public openLayer = (el: any) => {
    var temp = $("#" + el);
    if (!$(".pop-layer:visible").length) {
      $("<div/>", {
        class: "dimmed",
      })
        .appendTo("body")
        .fadeIn(200);
    }
    temp.fadeIn(200);
    this.popRespon();
    $("html").addClass("full");
    //.dimmed 클래스 추가시 외부영역 클릭시 팝업 닫힘
    $(".popClose").on("click", function (d) {
      temp.fadeOut(200);

      if ($(".dimmed").length) {
        $(".dimmed").fadeOut(200, function () {
          $(this).remove();
        });
      }
      $("html").removeClass("full");
    });
  };
  public popRespon() {
    var $popUp = $(".pop-layer");
    var $popWrap = $(".pop-layer__wrap");

    var popWrapHT: any;
    var winHT: any;
    popWrapHT = $popWrap.innerHeight();
    winHT = $(window).innerHeight();
    // console.log(popWrapHT + " / " + winHT);

    if (popWrapHT >= winHT) {
      $popUp.css("height", "100%");
    } else if (popWrapHT < winHT) {
      $popUp.css("height", "auto");
    }
  }
  public valueChk = (txt: any) => {
    const chk = [undefined, null, ""];

    if (chk.indexOf(txt) !== -1) {
      return false;
    } else {
      return true;
    }
  };
  public accountChk = () => {
    const jwt = localStorage.getItem("jwt") !== null ? true : false;
    const date =
      moment(localStorage.getItem("time")).add(10000, "seconds").valueOf() >
      moment(new Date().toString()).valueOf();
    return jwt && date;
  };
}

export class webPush {
  public async send() {
    const publicVapidKey =
      "BCsuYyKiln0c7ibhCVKCyp50GNIqjU1jbg4YwaAJcW7WqR2wYSidZuXdZYu0tNo0aVJ0U3h54aqFcIkv_Usg6SY";

    const register = await navigator.serviceWorker.register("../worker4.js", {
      scope: "/",
    });
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: this.urlBase64ToUint8Array(publicVapidKey),
    });
    const doctorService = new DocotrDataProvider();
    await doctorService.addSubscribeInfo(subscription);
  }
  public async update() {
    const publicVapidKey =
      "BCsuYyKiln0c7ibhCVKCyp50GNIqjU1jbg4YwaAJcW7WqR2wYSidZuXdZYu0tNo0aVJ0U3h54aqFcIkv_Usg6SY";

    console.log("업데이트 서비스워커 등록중...");
    const register = await navigator.serviceWorker.register("../worker3.js", {
      scope: "/",
    });
    console.log("업데이트 서비스워커 등록됨");
    console.log("업데이트 푸쉬 등록중..");
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: this.urlBase64ToUint8Array(publicVapidKey),
    });
    console.log("업데이트 푸쉬 등록 됨");
    console.log("업데이트 푸쉬 보내기");
    console.log(JSON.stringify(subscription));
    const doctorService = new DocotrDataProvider();

    await doctorService.updateSubscribeInfo(subscription);

    console.log("업데이트 푸쉬 보냄");
  }

  public urlBase64ToUint8Array(base64String: any) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}
