/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, { Column, Pager, Paging } from "devextreme-react/data-grid";
import { IPopAlertEditProps } from "./IPopAlertEdit";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DocotrDataProvider } from "../../../lib/service/DocotrDataProvider";
import { SelectBox, TextBox, Button } from "devextreme-react";
import * as _ from "lodash";
import tBox from "devextreme/ui/text_box";
import sBox from "devextreme/ui/select_box";
import moment from "moment";

import * as dialog from "devextreme/ui/dialog";
import { lang } from "../../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";

loadMessages(lang);
locale("en");

export const PopAlertEdit: FunctionComponent<IPopAlertEditProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [alert, setAlert] = useState([] as any);
  const [alertHistory, setAlertHistory] = useState([] as any);
  const [alertValue, setAlertValue] = useState({} as any);
  const [alertDetail, setAlertDetail] = useState({} as any);

  const [txtDisabled, setTxtDisabled] = useState(true);
  const doctorService = new DocotrDataProvider();
  useEffect(() => {
    common.ResizePage();

    const fetchArticles = async () => {
      try {
        initData(1);
      } catch (err: any) {}
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const initData = async (e: any) => {
    // e = 1 정상적인 로직  e = 2 reset
    const main = await doctorService.GetAlertList();
    const patientFilter = _.filter(main.data, { insungSeq: props.seq });
    let doctocFilter = _.filter(main.data, { insungSeq: null });

    doctocFilter =
      doctocFilter.length > 0
        ? doctocFilter
        : _.filter(main.data, { insungSeq: null, doctorSeq: null });

    setAlert(
      patientFilter.length > 0
        ? patientFilter
        : props.seq === null
        ? doctocFilter
        : []
    );
    !common.valueChk(props.seq)
      ? setAlertValue(doctocFilter[0])
      : setAlertValue(
          patientFilter.length > 0 ? patientFilter[0] : doctocFilter[0]
        );
    let dt;

    if (e === 1) {
      dt = !common.valueChk(props.seq)
        ? doctocFilter[0]
        : patientFilter.length > 0
        ? patientFilter[0]
        : doctocFilter[0];
    } else {
      dt = doctocFilter[0];
    }

    _dev("txtInp1", tBox).option("value", valueChk(dt.systolicHigher));
    _dev("txtInp2", tBox).option("value", valueChk(dt.systolicLower));
    _dev("txtInp3", tBox).option("value", valueChk(dt.diastolicHigher));
    _dev("txtInp4", tBox).option("value", valueChk(dt.diastolicLower));
    _dev("txtInp5", tBox).option("value", valueChk(dt.bpHrHigher));
    _dev("txtInp6", tBox).option("value", valueChk(dt.bpHrLower));
    _dev("txtInp7", tBox).option("value", valueChk(dt.oxygenHigher));
    _dev("txtInp8", tBox).option("value", valueChk(dt.oxygenLower));
    _dev("txtInp9", tBox).option("value", valueChk(dt.oxHrHigher));
    _dev("txtInp10", tBox).option("value", valueChk(dt.oxHrLower));
    _dev("txtInp11", tBox).option("value", valueChk(dt.bodytempHigher));
    _dev("txtInp12", tBox).option("value", valueChk(dt.bodytempLower));
    _dev("selTemp", sBox).option("value", valueChk(dt.bodytempUnit));
  };

  const valueChk = (e: any) => {
    return common.valueChk(e) ? e : "";
  };
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };

  const btnSave = async (d: any) => {
    const doctorService = new DocotrDataProvider();

    const txtInp1 = _dev("txtInp1", tBox).option("value");
    const txtInp2 = _dev("txtInp2", tBox).option("value");
    const txtInp3 = _dev("txtInp3", tBox).option("value");
    const txtInp4 = _dev("txtInp4", tBox).option("value");
    const txtInp5 = _dev("txtInp5", tBox).option("value");
    const txtInp6 = _dev("txtInp6", tBox).option("value");
    const txtInp7 = _dev("txtInp7", tBox).option("value");
    const txtInp8 = _dev("txtInp8", tBox).option("value");
    const txtInp9 = _dev("txtInp9", tBox).option("value");
    const txtInp10 = _dev("txtInp10", tBox).option("value");
    const txtInp11 = _dev("txtInp11", tBox).option("value");
    const txtInp12 = _dev("txtInp12", tBox).option("value");

    const selTemp = _dev("selTemp", sBox).option("value");

    const json = {
      txtInp1: txtInp1,
      txtInp2: txtInp2,
      txtInp3: txtInp3,
      txtInp4: txtInp4,
      txtInp5: txtInp5,
      txtInp6: txtInp6,
      txtInp7: txtInp7,
      txtInp8: txtInp8,
      txtInp9: txtInp9,
      txtInp10: txtInp10,
      txtInp11: txtInp11,
      txtInp12: txtInp12,
      insungSeq: props.seq,
      selTemp: selTemp,
      changeItem: _.uniq(alertHistory).join(","),
    };

    if (common.valueChk(_.uniq(alertHistory).join(","))) {
      let result = dialog.confirm(
        "<i>" +
          formatMessage("txt_alert_settings_guide") +
          "<br>" +
          formatMessage("txt_alert_settings_guide1") +
          "<br>" +
          formatMessage("txt_alert_settings_guide2") +
          "<br>" +
          formatMessage("txt_alert_settings_guide3") +
          "</i>",
        "Confirm"
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          doctorService.addAlert(json).then(async (e: any) => {
            setTxtDisabled(true);
            initData(1);
            $(".popClose").trigger("click");
          });
        }
      });
    } else {
      dialog.alert(formatMessage("txt_no_change"), "alert");
    }
  };

  const valueChanged = (e: any, num: any) => {
    if (e.previousValue !== "" && e.previousValue !== null) {
      if (e.previousValue !== e.value) {
        if (num > 6 && num < 11) {
          alertHistory.push("OX");
        } else if (num > 10) {
          alertHistory.push("TE");
        } else {
          alertHistory.push("BP");
        }
        setAlertHistory(alertHistory);
      }
    }
  };
  return (
    <div className="pop-layer pop-layer--sm" id="popAlertLvInput">
      <section className="pop-layer__wrap">
        <header>
          <h3>
            <strong>Alert Level</strong>
          </h3>
          <span className="icon icon--close lg popClose"></span>
        </header>

        <div className="cont">
          <div className="cont__wrap">
            <h4>
              <strong>
                {props.info === null ? "All Patients" : props.info}
              </strong>
            </h4>
            <p
              className="alert-notice mb10"
              style={{ display: props.seq === null ? "" : "none" }}
            >
              <i className="fa fa-info-circle"></i>
              <span>
                To set the normal range for each individual, select the patient
                name and set it on the patient detail page.
              </span>
            </p>
            <div className="update">
              <span className="info">
                Last update :
                {moment(alertValue.inputUtcDt).format("MM/DD/YYYY hh:mm:ss")}
              </span>
              <button
                className="btn xsm blue square ml10 mr10"
                onClick={() => {
                  common.openLayer("popHistory");
                  $("#popHistory").height(500);
                }}
              >
                <i className="fa fa-list-ul icon"></i>
              </button>
              <Button
                className="btn xsm blue"
                text="Edit"
                type="success"
                stylingMode="contained"
                onClick={() => {
                  txtDisabled === true
                    ? setTxtDisabled(false)
                    : setTxtDisabled(true);
                }}
              />
              {"ㅤ"}
              <Button
                className="btn xsm blue"
                text="Reset"
                type="success"
                stylingMode="contained"
                disabled={txtDisabled}
                style={{ display: props.seq === null ? "none" : "" }}
                onClick={() => {
                  initData(2);
                }}
              />
            </div>
            <article className="mb20 mt20">
              <h4>
                <i className="fa fa-medkit"></i>
                <strong>Blood pressure</strong>
              </h4>
              <div className="level-wrap">
                <div className="block">
                  <strong>Systolic</strong>
                  <div className="record-wrap">
                    <ul className="record">
                      <li>
                        <span className="txt">Higher</span>
                        <span className="sign">&#62;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp1"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 1);
                            }}
                          />
                          <label htmlFor="txtInp1" className="blind"></label>
                        </span>
                      </li>
                      <li>
                        <span className="txt">Lower</span>
                        <span className="sign">&#60;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp2"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 2);
                            }}
                          />
                          <label htmlFor="txtInp2" className="blind"></label>
                        </span>
                      </li>
                    </ul>
                    <div className="unit">
                      <span>mmHg</span>
                    </div>
                  </div>
                </div>
                <div className="block">
                  <strong>Diastolic</strong>
                  <div className="record-wrap">
                    <ul className="record">
                      <li>
                        <span className="txt">Higher</span>
                        <span className="sign">&#62;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp3"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 3);
                            }}
                          />
                          <label htmlFor="txtInp3" className="blind"></label>
                        </span>
                      </li>
                      <li>
                        <span className="txt">Lower</span>
                        <span className="sign">&#60;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp4"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 4);
                            }}
                          />
                          <label htmlFor="txtInp4" className="blind"></label>
                        </span>
                      </li>
                    </ul>
                    <div className="unit">
                      <span>mmHg</span>
                    </div>
                  </div>
                </div>
                <div className="block">
                  <strong>Heart Rate</strong>
                  <div className="record-wrap">
                    <ul className="record">
                      <li>
                        <span className="txt">Higher</span>
                        <span className="sign">&#62;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp5"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 5);
                            }}
                          />
                          <label htmlFor="txtInp5" className="blind"></label>
                        </span>
                      </li>
                      <li>
                        <span className="txt">Lower</span>
                        <span className="sign">&#60;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp6"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 6);
                            }}
                          />
                          <label htmlFor="txtInp6" className="blind"></label>
                        </span>
                      </li>
                    </ul>
                    <div className="unit">
                      <span>bpm</span>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <article className="mb20">
              <h4>
                <i className="fa fa-heartbeat"></i>
                <strong>SpO₂</strong>
              </h4>
              <div className="level-wrap">
                <div className="block">
                  <strong>Oxygen Level</strong>
                  <div className="record-wrap">
                    <ul className="record">
                      <li>
                        <span className="txt">Higher</span>
                        <span className="sign">&#62;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp7"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 7);
                            }}
                          />
                          <label htmlFor="txtInp7" className="blind"></label>
                        </span>
                      </li>
                      <li>
                        <span className="txt">Lower</span>
                        <span className="sign">&#60;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp8"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 8);
                            }}
                          />
                          <label htmlFor="txtInp8" className="blind"></label>
                        </span>
                      </li>
                    </ul>
                    <div className="unit">
                      <span>%</span>
                    </div>
                  </div>
                </div>
                <div className="block">
                  <strong>Heart Rate</strong>
                  <div className="record-wrap">
                    <ul className="record">
                      <li>
                        <span className="txt">Higher</span>
                        <span className="sign">&#62;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp9"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 9);
                            }}
                          />
                          <label htmlFor="txtInp9" className="blind"></label>
                        </span>
                      </li>
                      <li>
                        <span className="txt">Lower</span>
                        <span className="sign">&#60;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp10"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 10);
                            }}
                          />
                          <label htmlFor="txtInp10" className="blind"></label>
                        </span>
                      </li>
                    </ul>
                    <div className="unit">
                      <span>bpm</span>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <article>
              <h4>
                <i className="fa fa-thermometer-three-quarters"></i>
                <strong>Body Temperature</strong>
              </h4>
              <div className="level-wrap">
                <div className="block">
                  <strong>Temperature</strong>
                  <div className="record-wrap">
                    <ul className="record">
                      <li>
                        <span className="txt">Higher</span>
                        <span className="sign">&#62;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp11"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 11);
                            }}
                          />
                          <label htmlFor="txtInp11" className="blind"></label>
                        </span>
                      </li>
                      <li>
                        <span className="txt">Lower</span>
                        <span className="sign">&#60;</span>
                        <span className="tf no-bor">
                          <TextBox
                            id="txtInp12"
                            disabled={txtDisabled}
                            onValueChanged={(e: any) => {
                              valueChanged(e, 12);
                            }}
                          />
                          <label htmlFor="txtInp12" className="blind"></label>
                        </span>
                      </li>
                    </ul>
                    <div className="unit">
                      <div className="opt">
                        <label htmlFor="selTemp" className="blind">
                          단위선택
                        </label>
                        <SelectBox
                          id="selTemp"
                          dataSource={["℉", "℃"]}
                          placeholder=""
                          onValueChanged={(e: any) => {
                            valueChanged(e, 13);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>

        <footer>
          <div className="btn-wrap">
            <Button
              className="btn mlg blue"
              text="Save"
              type="success"
              stylingMode="contained"
              onClick={btnSave}
              disabled={txtDisabled}
            />
            <Button
              className="btn mlg grey"
              text="Close"
              type="default"
              stylingMode="contained"
              onClick={() => $(".popClose").trigger("click")}
            />
          </div>
        </footer>
      </section>

      <div className="pop-layer pop-layer--sm" id="popHistory">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>History</strong>
            </h3>
            <span
              className="icon icon--close lg"
              onClick={() => $("#popHistory").hide()}
            ></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <article>
                <h4>
                  <strong>{props.info === null ? "All" : props.info}</strong>
                </h4>
                <div className="tbl-wrap">
                  {/* type-pop type-ct */}
                  <DataGrid
                    dataSource={alert}
                    noDataText="No data"
                    className="type-pop type-ct "
                  >
                    <Column
                      caption="No"
                      width={40}
                      cellRender={(e) => {
                        return (
                          e.component.pageIndex() * e.component.pageSize() +
                          e.row.rowIndex +
                          1
                        );
                      }}
                    />
                    <Column
                      dataField="changeItem"
                      caption="Change"
                      width={120}
                    />
                    <Column
                      dataField="inputUtcDt"
                      caption="Date"
                      width={100}
                      cellRender={(e: any) => {
                        return moment(e.value)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format(
                            localStorage.getItem("lang") === "ko"
                              ? "YYYY-MM-DD"
                              : "L"
                          );
                      }}
                    />
                    <Column
                      caption="Alert Level"
                      width={130}
                      dataField="seq"
                      cellTemplate={(container: any, options: any) => {
                        $("<a class='link' style='cursor: pointer'>")
                          .on("click", () => {
                            setAlertDetail(
                              _.filter(alert, { seq: options.value })[0]
                            );
                            common.openLayer("popAlertLvView");
                          })
                          .append($("<i>", { class: "fa fa fa-file-o" }))
                          .appendTo(container);
                      }}
                    />
                    <Column
                      caption="Edited by"
                      cellRender={(e: any) => {
                        return props.info === null
                          ? localStorage.getItem("name")
                          : props.info.split("(")[0];
                      }}
                    />
                    <Paging defaultPageSize={5} />
                    <Pager
                      visible={true}
                      allowedPageSizes={[5, 10, "all"]}
                      displayMode={"full"}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />
                  </DataGrid>
                </div>
              </article>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <Button
                className="btn mlg grey"
                text="Close"
                type="default"
                stylingMode="contained"
                onClick={() => $("#popHistory").hide()}
              />
            </div>
          </footer>
        </section>
      </div>
      <div className="pop-layer pop-layer--sm" id="popAlertLvView">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>Alert Level</strong>
            </h3>
            <span
              className="icon icon--close lg"
              onClick={() => $("#popAlertLvView").hide()}
            ></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <div className="update">
                <span className="info">
                  Last update :{" "}
                  {moment(alertDetail.inputUtcDt).format("MM/DD/YYYY hh:mm:ss")}
                </span>
              </div>
              <article className="mb20 mt20">
                <h4>
                  <i className="fa fa-medkit"></i>
                  <strong>Blood pressure</strong>
                </h4>
                <div className="level-wrap">
                  <div className="block">
                    <strong>Systolic</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.systolicHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign"> &#60;</span>
                          <span>{alertDetail.systolicLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>mmHg</span>
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <strong>Diastolic</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.diastolicHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign">&#60;</span>
                          <span>{alertDetail.diastolicLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>mmHg</span>
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <strong>Heart Rate</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.bpHrHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign"> &#60;</span>
                          <span>{alertDetail.bpHrLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>bpm</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article className="mb20">
                <h4>
                  <i className="fa fa-heartbeat"></i>
                  <strong>SpO₂</strong>
                </h4>
                <div className="level-wrap">
                  <div className="block">
                    <strong>Oxygen Level</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.oxygenHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign"> &#60;</span>
                          <span>{alertDetail.oxygenHigher}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>%</span>
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <strong>Heart Rate</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.oxHrHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign"> &#60;</span>
                          <span>{alertDetail.oxHrLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>bpm</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <h4>
                  <i className="fa fa-thermometer-three-quarters"></i>
                  <strong>Body Temperature</strong>
                </h4>
                <div className="level-wrap">
                  <div className="block">
                    <strong>Temperature</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.bodytempHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign"> &#60;</span>
                          <span>{alertDetail.bodytempLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>{alertDetail.bodytempUnit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <Button
                className="btn mlg grey"
                text="Close"
                type="default"
                stylingMode="contained"
                onClick={() => $("#popAlertLvView").hide()}
              />
            </div>
          </footer>
        </section>
      </div>
    </div>
  );
};

export default PopAlertEdit;
