/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { IPatientProps } from "./IPatient";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import {
  Button,
  CheckBox,
  DateBox,
  LoadPanel,
  RadioGroup,
  SelectBox,
  TextArea,
} from "devextreme-react";
import * as _ from "lodash";
import moment from "moment";
import { alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import cBox from "devextreme/ui/check_box";
import rGroup from "devextreme/ui/radio_group";
loadMessages(lang);
export const Patient: FunctionComponent<IPatientProps> = (props) => {
  locale("" + localStorage.getItem("lang"));
  const history = useHistory();
  const common = new Common();
  const [gridList, setGridList] = useState([] as any);
  const [gridListClone, setGridListClone] = useState([] as any);
  const [gridFilterValue, setGridFilterValue] = useState<any>([]);
  const [totalCnt, setTotalCnt] = useState(String);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [name, setName] = useState(String);
  const [panShow, setPanShow] = useState(true as any);
  const [msg, setMsg] = useState(String);
  const [msgInsungSeq, setMsgInsungSeq] = useState(String);
  const [msgList, setMsgList] = useState<any>([]);
  const [msgListClone, setMsgListClone] = useState<any>([]);
  const [sortReg, setSortReg] = useState("desc" as any);

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzOffset = -1 * moment().tz(tz).utcOffset();

  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    $(".dx-toolbar").css("display", "none");
    $(".tap").removeClass("on");
    $("#pagePatient").addClass("on");
    const doctorService = new DocotrDataProvider();

    const fetchArticles = async () => {
      const gridDt = await doctorService.GetPatientList();
      const msgDt = await doctorService.GetMessageList();

      //데이터 초기화
      setMsgList(msgDt.data.data);
      setMsgListClone(msgDt.data.data);
      setGridList(gridDt.data.data);
      setGridListClone(gridDt.data.data);
      setTotalCnt(gridDt.data.totalCount);
      setPanShow(false);
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const showColumn = async () => {
    $(".dx-datagrid-column-chooser-button").click();
  };
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  const onDateSelectChanged = async (e: any) => {
    if (e.value === "1W") {
      let cDate = new Date();
      cDate.setDate(cDate.getDate() - 7);
      setStartDate(cDate);
      setEndDate(new Date());
    } else if (e.value === "1M") {
      let cDate = new Date();
      var monthDate = new Date(cDate.setMonth(cDate.getMonth() - 1));
      setStartDate(monthDate);
      setEndDate(new Date());
    } else if (e.value === "Today") {
      setStartDate(new Date());
      setEndDate(new Date());
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };
  const onNameChanged = async (e: any) => {
    setName(e.value);
  };
  const onSearch = async () => {
    let filter: any;
    filter = [];
    filter.push("and");

    if (!common.valueChk(startDate) && common.valueChk(endDate)) {
      alert(formatMessage("msg_select_start_end_date"), "alert");
      return false;
    }

    if (common.valueChk(startDate) && !common.valueChk(endDate)) {
      alert(formatMessage("msg_select_start_end_date"), "alert");
      return false;
    }

    if (!common.valueChk(startDate) || !common.valueChk(endDate)) {
      setGridList(gridListClone);
    } else if (common.valueChk(startDate) && common.valueChk(endDate)) {
      let sValue = moment(startDate).format("YYYY-MM-DD 00:00:00");
      let eValue = moment(endDate).format("YYYY-MM-DD 23:59:59");
      const a = _.filter(gridListClone, function (dt) {
        return _.inRange(
          moment(dt.inputUtcDt).valueOf(),
          moment(sValue).valueOf(),
          moment(eValue).valueOf()
        );
      });
      setGridList(a);
    } else {
      setGridList(gridListClone);
    }

    if (common.valueChk(name)) {
      filter.push(
        common.valueChk(name) ? ["name", "contains", name] : ["name", "<>", ""]
      );
    } else {
      filter = [];
    }
    setGridFilterValue(filter);
  };
  const onSDateChanged = async (e: any) => {
    setStartDate(e.value);
  };
  const onEDateChanged = async (e: any) => {
    setEndDate(e.value);
  };
  const onMsgChanged = async (e: any) => {
    setMsg(e.value);
  };
  const onSend = async (e: any) => {
    if (msgInsungSeq !== "") {
      const json = {
        message: msg,
        insungSeq: msgInsungSeq,
        doctorSeq: 28,
        senderFlag: "D",
      };
      const doctorService = new DocotrDataProvider();
      doctorService.addMessage(json).then(async (d: any) => {
        const doctorService = new DocotrDataProvider();
        const gridDt = await doctorService.GetMessageList();

        $(".popClose").trigger("click");
        setMsgList(gridDt.data.data);
      });
    } else {
      alert(formatMessage("msg_select_patient"), "alert");
    }
  };
  const onMsgNameChanged = async (e: any) => {
    setMsgList(_.filter(msgList, { insungSeq: e.value }));
    setMsgInsungSeq(e.value);
  };
  const onAlertList = async (e: any) => {
    let alertDt = _.filter(gridListClone, { normalYn: "N" });
    alertDt = _.sortBy(alertDt, "measureUtcDt").reverse();

    setGridList(alertDt);
    setSortReg("aaa");
  };
  const renderTitleHeader = () => {
    return (
      <div>
        <span>Value </span>
        <i
          className="fa fa-bell"
          aria-hidden="true"
          style={{ color: "red", cursor: "pointer" }}
          onClick={onAlertList}
        ></i>
      </div>
    );
  };
  const onCellRenderMeasureValue = (data: any) => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <span className={data.data.normalYn === "N" ? " valRed " : ""}>
        {data.data.measureType === "PH" || data.data.measureType === "MS"
          ? moment(data.data.measureUtcDt).format("YYYYMMDDhhmmss") + ".jpg"
          : data.value}
      </span>
    );
  };
  const onPopupMsgFilter = async (e: any) => {
    let filter: any;
    const rdgVal = _dev("rdgMsg", rGroup).option("value");
    const chkVal = _dev("cBoxUnCheck2", cBox).option("value");

    if (rdgVal === "All") {
      filter = {};
    } else {
      filter = {
        senderFlag: rdgVal,
      };
    }
    if (msgInsungSeq !== "") {
      filter["insungSeq"] = msgInsungSeq;
    }

    if (chkVal) {
      filter["check"] = "Uncheck";
    }
    setMsgList(_.filter(msgListClone, filter));
  };
  return (
    <main id="content" className="content">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: ".wrap" }}
        visible={panShow}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />

      <section className="sub-top">
        <header>
          <div className="tit-area">
            <i className="fa fa-user-o icon-title" aria-hidden="true"></i>
            <div className="tit">
              <h2>{formatMessage("txt_patient")}</h2>
              <span className="num">({totalCnt})</span>
              {/* <button
                type="button"
                onClick={() => {
                  $("#sekk").trigger("click");
                  common.openLayer("popPhone");
                }}
              >
                <i className="fa fa-phone icon-link" aria-hidden="true"></i>
                <span className="blind">phone settings</span>
              </button> */}
            </div>
          </div>
        </header>
        <article className="search-form">
          <form action="" name="searchForm">
            <fieldset>
              <legend className="blind">검색폼</legend>
              <div className="form-item">
                <div className="opt short">
                  <label htmlFor="selDate" className="blind">
                    기간선택
                  </label>
                  <SelectBox
                    items={["All", "Today", "1W", "1M"]}
                    defaultValue={"All"}
                    onValueChanged={onDateSelectChanged}
                  />
                </div>
                <div className="form-wrap date-item">
                  <DateBox
                    id="fromDate"
                    value={startDate}
                    max={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onSDateChanged}
                  />
                  <span className="hyphen"> ~ </span>
                  <DateBox
                    id="toDate"
                    value={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onEDateChanged}
                  />
                </div>
              </div>
              <div className="form-item mt10">
                <div className="opt">
                  <SelectBox
                    dataSource={_.orderBy(
                      gridListClone,
                      [(e) => e.name.toLowerCase()],
                      ["asc"]
                    )}
                    placeholder="Name"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"name"}
                    displayExpr="name"
                    valueExpr="name"
                    onValueChanged={onNameChanged}
                  />
                </div>
                <button
                  type="button"
                  className="btn md white"
                  onClick={onSearch}
                >
                  <i className="fa fa-search icon" aria-hidden="true"></i>
                  <span className="blind">검색</span>
                </button>
              </div>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont mt30">
        <header>
          <button
            type="button"
            id="btnDisplay"
            className="btn sm blue flex-mla"
            onClick={showColumn}
          >
            <span>{formatMessage("btn_displayl")}</span>
          </button>
        </header>

        <div className="tbl-wrap">
          <DataGrid
            id="patientList"
            dataSource={gridList}
            filterValue={gridFilterValue}
            allowColumnResizing={true}
            columnMinWidth={50}
            columnAutoWidth={true}
            showBorders={true}
            noDataText="No data"
            className="type-ct"
          >
            <ColumnChooser enabled={true} mode="select" />
            <Column
              caption="No"
              cellRender={(e) => {
                return (
                  e.component.pageIndex() * e.component.pageSize() +
                  e.row.rowIndex +
                  1
                );
              }}
            />
            <Column
              dataField="inputUtcDt"
              caption={formatMessage("txt_registratered")}
              sortOrder={sortReg}
              cellRender={(e: any) => {
                return moment(e.value)
                  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format(
                    localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L"
                  );
              }}
            />
            <Column
              dataField="name"
              caption={formatMessage("txt_name")}
              cellTemplate={(container: any, options: any) => {
                if (!common.valueChk(options.data.measureValue)) {
                  $("<span>").append(options.value).appendTo(container);
                } else {
                  $(
                    '<a href="/measurement?insungSeq=' +
                      options.data.insungSeq +
                      '"class="link">'
                  )
                    .append(options.value)
                    .appendTo(container);
                }
              }}
            />

            <Column dataField="gender" caption={formatMessage("txt_sex")} />
            <Column dataField="age" caption={formatMessage("txt_age")} />
            <Column
              dataField="measureUtcDt"
              caption={formatMessage("txt_date_measured")}
              cellRender={(e: any) => {
                return moment(e.value)
                  .subtract(tzOffset, "minutes")
                  .format(
                    (localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L") + " HH:mm:ss"
                  );
              }}
            />
            <Column
              dataField="measureType"
              caption={formatMessage("txt_result_type")}
              cellTemplate={(container: any, options: any) => {
                $(
                  '<a href="/measurement?resultType=' +
                    options.value +
                    '"class="link">'
                )
                  .append(options.value)
                  .appendTo(container);
              }}
            />
            <Column
              dataField="measureDevice"
              caption={formatMessage("txt_device")}
              cellTemplate={(container: any, options: any) => {
                $(
                  '<a href="/measurement?resultType=' +
                    options.data.measureType +
                    "&device=" +
                    options.value +
                    '"class="link">'
                )
                  .append(options.value)
                  .appendTo(container);
              }}
            />
            <Column
              dataField="measureValue"
              width={120}
              caption={formatMessage("txt_value")}
              allowSorting={false}
              cellRender={onCellRenderMeasureValue}
              headerCellRender={renderTitleHeader}
            />
            <Column
              dataField="measureUtcDt"
              caption={formatMessage("txt_date_transferred")}
              cellRender={(e: any) => {
                return moment(e.value)
                  .subtract(tzOffset, "minutes")
                  .format(
                    (localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L") + " HH:mm:ss"
                  );
              }}
            />
            <Column
              caption={formatMessage("txt_message")}
              cellTemplate={(container: any, options: any) => {
                $("<a style='cursor: pointer' class='link'>")
                  .on("click", () => {
                    _dev("cBoxUnCheck2", cBox).option("value", false);

                    setMsg("");
                    setMsgList(
                      _.filter(msgListClone, { name: options.data.name })
                    );
                    setMsgInsungSeq(options.data.insungSeq);
                    common.openLayer("popMsg");
                  })
                  .append($("<i>", { class: "fa fa-pencil" }))
                  .appendTo(container);
              }}
            />
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 20, "all"]}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        </div>

        <footer>
          <button
            type="button"
            id="btnSave"
            className="btn sm blue flex-mla"
            onClick={() => common.openLayer("popUnits")}
          >
            <span>{formatMessage("txt_unit_measure")} </span>
          </button>
        </footer>
      </section>
      <div className="pop-layer pop-layer--sm" id="popMsg">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>{formatMessage("txt_message")}</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <article>
                <div className="msg-top">
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <SelectBox
                    dataSource={_.orderBy(
                      gridList,
                      [(e) => e.name.toLowerCase()],
                      ["asc"]
                    )}
                    placeholder="Name"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"name"}
                    displayExpr="name"
                    valueExpr="insungSeq"
                    value={msgInsungSeq}
                    onValueChanged={onMsgNameChanged}
                  />
                  {/* <Button
                    className="btn md blue flex-mla"
                    text="Rewrite"
                    type="success"
                    stylingMode="contained"
                  /> */}
                </div>
                <TextArea
                  height={90}
                  showClearButton={true}
                  value={msg}
                  onValueChanged={onMsgChanged}
                />
                <div className="btn-wrap mt20">
                  <Button
                    className="btn mlg blue"
                    text={formatMessage("btn_send")}
                    type="success"
                    stylingMode="contained"
                    onClick={onSend}
                  />
                  <Button
                    className="btn mlg grey"
                    text={formatMessage("btn_cancel")}
                    type="default"
                    stylingMode="contained"
                    onClick={() => {
                      $(".popClose").trigger("click");
                    }}
                  />
                </div>
              </article>

              <article className="mt30">
                <h4>
                  <strong>{formatMessage("txt_message_list")}</strong>
                </h4>
                <div className="sort form-item">
                  <RadioGroup
                    items={[
                      { name: "All", value: "All" },
                      { name: "Received", value: "P" },
                      { name: "Sent", value: "D" },
                    ]}
                    id="rdgMsg"
                    defaultValue={"All"}
                    displayExpr="name"
                    valueExpr="value"
                    layout="horizontal"
                    onValueChanged={onPopupMsgFilter}
                  />

                  <div style={{ float: "left", marginLeft: "30%" }}>
                    <CheckBox
                      defaultValue={false}
                      id="cBoxUnCheck2"
                      text="Unchecked Only"
                      onValueChanged={onPopupMsgFilter}
                    />
                  </div>
                </div>
                <div className="tbl-wrap">
                  <DataGrid
                    dataSource={msgList}
                    noDataText="No data"
                    className="type-ct"
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    wordWrapEnabled={false}
                    cellHintEnabled={true}
                  >
                    <Column
                      caption="No"
                      width={45}
                      cellRender={(e) => {
                        return (
                          e.component.pageIndex() * e.component.pageSize() +
                          e.row.rowIndex +
                          1
                        );
                      }}
                    />
                    <Column
                      dataField="senderFlag"
                      caption={formatMessage("txt_status")}
                      cellTemplate={(container: any, options: any) => {
                        $("<span>")
                          .append(
                            $("<i>", {
                              class:
                                options.value === "D"
                                  ? "fa fa-paper-plane"
                                  : "fa fa-envelope",
                            })
                          )
                          .appendTo(container);
                      }}
                    />
                    <Column
                      dataField="inputUtcDt"
                      caption={formatMessage("txt_date")}
                      cellRender={(e: any) => {
                        return moment(e.value)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format(
                            localStorage.getItem("lang") === "ko"
                              ? "YYYY-MM-DD"
                              : "L"
                          );
                      }}
                    />
                    <Column dataField="name" caption="Recipents" />
                    <Column dataField="message" caption="Detail" width={100} />
                    <Column
                      dataField="check"
                      caption={formatMessage("txt_title_check")}
                      width={110}
                      cellTemplate={(container: any, options: any) => {
                        $("<span>")
                          .append(
                            options.data.senderFlag === "D"
                              ? options.value
                              : "-"
                          )
                          .appendTo(container);
                      }}
                    />
                    <Paging defaultPageSize={10} />
                  </DataGrid>
                </div>
              </article>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Patient;
