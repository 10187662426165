export const en = {
  btn_add_to_call: "Add to call",
  btn_cancel: "Cancel",
  btn_change_layoutl: "Change Layout",
  btn_closel: "Close",
  btn_copy_urll: "Copy meeting url",
  btn_displayl: "Display",
  btn_editl: "Edit",
  btn_end_calll: "END CALL",
  btn_graphl: "Graph",
  btn_join_call_now: "Join call now",
  btn_ok: "OK",
  btn_origin_view: "Origin View",
  btn_reservation: "Reservation",
  btn_reset: "Reset",
  btn_rewrite: "Rewrite",
  btn_save: "Save",
  btn_save_changes: "Save changes",
  btn_send: "Send",
  btn_send_message: "Send a Message",
  btn_start_recording: "Start Recording",
  btn_stop_recording: "Stop Recording",
  btn_table: "Table",
  btn_trend_chart: "Trend Chart",
  btn_trend_table: "Trend Table",
  btn_video_call: "Video Call",
  datatables_language: "English.json",
  html_privacy: "privacy_korean.html",
  html_terms: "termsofservice_korean.html",
  mnu_appointment: "APPOINTMENT",
  mnu_assessment: "ASSESSMENT",
  mnu_files: "FILES",
  mnu_image: "IMAGE",
  mnu_measurement: "MEASUREMENT",
  mnu_message: "MESSAGE",
  mnu_patient: "PATIENT",
  msg_alert_level_saved: "Alert Level has been saved.",
  msg_appoint_time:
    "You can only select the time available for consultation. Times can be selected for 20 minutes each. To extend the time, select a continuous time.",
  msg_appointment_cancelled: "Your appointment has been cancelled.",
  msg_appointment_changed: "Your appointment has been changed.",
  msg_appointment_created: "Your appointment has been created.",
  msg_call_ended: "Call ended",
  msg_call_setting_saved: "Call Setting has been saved.",
  msg_cancel_appointment: "Are your sure you want to cancel this appointment?",
  msg_canceled: "it has been canceled.",
  msg_choose_photos_videos: "Please, choose at least one of Photos or Videos",
  msg_delete_appoint: "Are your sure you want to cancel this appointment?",
  msg_end_date_after_start_date: '"\'"End date" must be after "Start date"',
  msg_enter_agenda: "Please enter the agenda.",
  msg_enter_id: "Please enter the ID.",
  msg_enter_message: "Please enter a message to send.",
  msg_enter_phone_number: "Please enter the Phone Number.",
  msg_enter_phone_title: "Please enter the Phone Title.",
  msg_enter_pwd: "Please Enter the Password.",
  msg_enter_pwd_confirmed: "Please Enter the Password confirmed",
  msg_enter_title: "Please enter the title.",
  msg_graph_select_name: "To view the graph, you must select a patient.",
  msg_login_fail:
    "The ID you entered could't be found or your password was incorrect. \nPlease try again",
  msg_max_length:
    "Maximum byte is ${length} byte. Exceeded letters will be automatically deleted",
  msg_message_sent: "Message has been sent.",
  msg_missed_call:
    "He/She cannot answer your call<br/>Please, check and call again later.",
  msg_modified: "Has been modified",
  msg_not_support_graph: "Do not support the graph view.",
  msg_offline:
    "Not answer your call.<br/>(The other party may be offline)<br/>Please, check and call again later.",
  msg_offline2: "are offline. Please, try your call again later",
  msg_pwd_changed: "Password has been changed",
  msg_pwd_not_match: "Password does not match. \nPlease check password.",
  msg_record_send: "Your recording file has been sent.",
  msg_select_appointment_date: "Please select the appointment date.",
  msg_select_appointment_time: "Please select the appointment time.",
  msg_select_device: "Select the model name of the device.",
  msg_select_participant: "Please select the participant.",
  msg_select_patient: "Please select the patient.",
  msg_select_start_end_date: "Please, select a start/end date.",
  msg_send_msg: "Plese, enter a message to send.",
  msg_want_change_pwd: "Do you really want change about Password ?",
  msg_want_end_call: "Are you sure you want to end the call?",
  txt_2hours_after_meal: "2hours after meal",
  txt_activity: "Activity",
  txt_activity_minutes: "Activity Minutes",
  txt_add_to_call: "Add to Call",
  txt_age: "Age",
  txt_agenda: "Agenda",
  txt_agreement: "Agreement",
  txt_albumin: "Albumin",
  txt_alert_level: "Alert Level",
  txt_alert_level_guide:
    "To set the normal range for each individual, select the patient name and set it on the patient detail page.",
  txt_alert_settings: "Alert Settings",
  txt_alert_settings_guide:
    "The changed value does not apply to some patients.",
  txt_alert_settings_guide1: "1. Patients whose values are set individually.",
  txt_alert_settings_guide2:
    "2. Patients whose value has already been set by other medical staff.",
  txt_alert_settings_guide3:
    "This patient value cannot be edited because another medical team has already set the value.",
  txt_alerts: "Alerts",
  txt_all: "All",
  txt_all_consent: "All consent",
  txt_all_patients: "All Patients",
  txt_appointment: "Appointment",
  txt_april: "April",
  txt_assessment: "Assessment",
  txt_august: "August",
  txt_bad_session: "Bad session",
  txt_before_5mins: "Before 5mins",
  txt_before15mins: "Before 15mins",
  txt_before1hour: "Before 1hour",
  txt_before30mins: "Before 30mins",
  txt_best: "Best",
  txt_bil: "Bil",
  txt_bilirubin: "Bilirubin",
  txt_blood_pressure: "Blood pressure",
  txt_blood_rbc: "Blood(RBC/㎕)",
  txt_blood_sugar: "Blood sugar",
  txt_bmi: "BMI",
  txt_body_composition: "Body Composition",
  txt_body_fat: "Body Fat",
  txt_body_temperature: "Body Temperature",
  txt_body_water: "Body Water",
  txt_bodyfat: "BodyFat",
  txt_bpm: "bpm",
  txt_bytes: "Bytes",
  txt_calling: "calling",
  txt_calories: "calories",
  txt_calories_burned: "Calories Burned",
  txt_camera_connected: "Camera connected. - ${name} camera",
  txt_camera_disconnected: "Camera disconnected. There is no connected camera.",
  txt_capnography: "CapnoGraphy",
  txt_change: "Change",
  txt_check: "Check",
  txt_cholesterol: "Cholesterol",
  txt_cholesterol_cardiochek: "Cholesterol(CardioChek)",
  txt_comparative_results: "Comparative Results",
  txt_confirm: "Confirm",
  txt_copd: "COPD",
  txt_copd_gold_classification: "COPD(Gold) Classification",
  txt_creatinine: "Creatinine",
  txt_date: "Date",
  txt_date_measured: "Date(measured)",
  txt_date_transferred: "Date(transferred)",
  txt_december: "December",
  txt_detail: "Detail",
  txt_detection_result: "Detection Result",
  txt_device: "Device",
  txt_device_lower: "device",
  txt_diagnosis_result: "Diagnosis Result",
  txt_diastolic: "Diastolic",
  txt_digital_microscope: "Digital Microscope",
  txt_digital_microscope_contents: "Digital Microscope Contents",
  txt_direct_input: "Direct Input",
  txt_distance: "Distance",
  txt_dob: "Date of Birth",
  txt_ecg: "ECG",
  txt_edit: "Edit",
  txt_editedby: "Edited by",
  txt_ela: "ELA",
  txt_enable_disable_noti:
    "You can enable or disable notifications for alerts.",
  txt_end_date: "end date",
  txt_end_date_camel: "End Date",
  txt_end_time: "End Time",
  txt_entered_the_room: "has entered the room.",
  txt_entries: "entries",
  txt_etco2: "EtCO₂",
  txt_ev: "EV",
  txt_fasting: "Fasting",
  txt_february: "February",
  txt_fef: "FEF",
  txt_fet: "FET",
  txt_fev: "FEV",
  txt_fev1: "FEV1",
  txt_fev1_diurnal_var: "FEV1 Diurnal Var",
  txt_fev1_fev6_ratio: "FEV1/FEV6(ratio)",
  txt_fev1_measured_fev1_predicted: "FEV1 Measured/FEV1 Predicted",
  txt_fev6: "FEV6",
  txt_file: "File",
  txt_filter: "Filter",
  txt_fit: "FIT",
  txt_fiv: "FIV",
  txt_fivc: "FIVC",
  txt_from: "from",
  txt_full_text_view: "Full text view",
  txt_fvc: "FVC",
  txt_gain: "Gain",
  txt_gender: "Gender",
  txt_glu: "Glu",
  txt_glucose: "Glucose",
  txt_good_session: "Good session",
  txt_graph: "Graph",
  txt_hba1c: "HbA1c",
  txt_hct: "HCT",
  txt_hdl: "HDL",
  txt_heart_rate: "Heart Rate",
  txt_height: "Height",
  txt_higher: "Higher",
  txt_highest: "Highest",
  txt_history: "History",
  txt_hour: "hour",
  txt_hr: "HR",
  txt_image: "Image",
  txt_input_your_text: "Input your text here",
  txt_inr: "INR",
  txt_inr_sec: "INR(sec.)",
  txt_interval: "Interval",
  txt_invite_participants: "Invite Participants",
  txt_january: "January",
  txt_july: "July",
  txt_june: "June",
  txt_ket: "Ket",
  txt_ketone: "Ketone",
  txt_ketone_blood: "β-Ketone(Blood)",
  txt_ketones: "Ketones",
  txt_last_update: "Last update",
  txt_ldl: "LDL",
  txt_left_the_room: "has left the room.",
  txt_leu: "Leu",
  txt_leukocytes: "Leukocytes(WBC/㎕)",
  txt_life_monitor: "LifeMonitor",
  txt_login_id: "ID",
  txt_logout: "Logout",
  txt_lower: "Lower",
  txt_lowest: "Lowest",
  txt_lung_age: "Lung Age",
  txt_march: "March",
  txt_max: "Max",
  txt_may: "May",
  txt_mean: "Mean",
  txt_measured: "measured",
  txt_measured_cardiac_rythm_events: "Measured Cardiac Rythm Events",
  txt_measurement: "Measurement",
  txt_measurement_item: "measurement item",
  txt_measurement_site: "Measurement site",
  txt_medical_staff: "MEDICAL STAFF",
  txt_message: "Message",
  txt_message_list: "Message List",
  txt_mild: "Mild",
  txt_min: "min",
  txt_mini: "Min",
  txt_missed_call: "Missed call",
  txt_moderate: "Moderate",
  txt_month: "1M",
  txt_muscle_fat_analysis: "Muscle-Fat Analysis",
  txt_my_appointments: "My Appointments",
  txt_name: "Name",
  txt_negative: "negative",
  txt_nit: "Nit",
  txt_nitrite: "Nitrite",
  txt_no: "No",
  txt_no_appointment: "No appointments this day",
  txt_no_change: "No changes were made",
  txt_no_data: "There are no alerts.",
  txt_no_matches: "No matches found for your search",
  txt_no_waiting_list: "No waiting list this day",
  txt_none: "None",
  txt_normal: "Normal",
  txt_normal_sinus_rhythm: "Normal Sinus Rhythm",
  txt_not_answer: "Not answer your call.\nPlease, check and call again later.",
  txt_not_answering_call:
    "Not answering your call<br/>Please, check and call again later.",
  txt_notification: "Notification",
  txt_november: "November",
  txt_number_of_days: "Number of Days",
  txt_number_of_session: "Number of session",
  txt_ob: "OB",
  txt_obesity_analysis: "Obesity Analysis",
  txt_obstructive_index: "Obstructive Index",
  txt_occult_blood: "Occult Blood",
  txt_october: "October",
  txt_offline: "Offline",
  txt_orange: "Orange",
  txt_others: "Others",
  txt_oxygen_level: "Oxygen Level",
  txt_p_qrs_t_qrst_axis: "P/QRS/T/QRST axis",
  txt_pacemaker: "Pacemaker",
  txt_param: "Param",
  txt_participants: "Participants",
  txt_patient: "Patient",
  txt_patient_data: "Patient Data",
  txt_patient_monitoring_biometrics: "Patient Monitoring > biometrics",
  txt_pef: "PEF",
  txt_pef_diurnal_var: "PEF Diurnal Var",
  txt_peft: "PEFt",
  txt_percent_body_fat: "Percent Body Fat",
  txt_percentq: "INR(%Q)",
  txt_pft: "PFT",
  txt_ph: "pH",
  txt_phone_number: "Phone Number",
  txt_phone_number_characters: "Phone number cannot include the characters",
  txt_phone_settings: "Phone Settings",
  txt_phone_title: "Phone Title",
  txt_photo: "Photos",
  txt_pick_time: "Pick Time",
  txt_pif: "PIF",
  txt_positive: "positive",
  txt_pqrstqrst_axis: "P/QRS/T/QRST axis",
  txt_pr: "PR",
  txt_pr_interval: "PR Interval",
  txt_pred: "Pred",
  txt_predicted: "Predicted",
  txt_privacy_policy: "Privacy Policy",
  txt_pro: "Pro",
  txt_protein: "Protein",
  txt_prt_axis: "P/R/T axis",
  txt_pt: "PT",
  txt_ptinr: "PT/INR",
  txt_pulse: "Pulse",
  txt_pulse_bpm: "Pulse(bpm)",
  txt_pwd: "Password",
  txt_pwd_change: "Password Change",
  txt_pwd_confirmed: "Password Confirmed",
  txt_qrs: "QRS",
  txt_qrs_duration: "QRS Duration",
  txt_qt: "QT",
  txt_qt_qtc: "QT/QTc",
  txt_qtc: "QTc",
  txt_qtr: "QTr",
  txt_range: "Range",
  txt_reason_reject: "Tell us your reason for rejecting",
  txt_received: "Received",
  txt_receiver: "Recipients",
  txt_record: "Record",
  txt_red: "RED",
  txt_registratered: "Registratered",
  txt_remember_me: "Remember Me",
  txt_reminder_email: "Reminder E-mail",
  txt_reminder_sms: "Reminder SMS",
  txt_required: "Required",
  txt_reservation_notice:
    "You can enter from 10 minutes before the reservation time.",
  txt_respiration: "Respiration",
  txt_respiratory_rate: "Respiratory Rate(bpm)",
  txt_result: "Result",
  txt_result_actual_measurement:
    "The result of pH(acidity), Specific Gravity, and Nitrite is an actual measurement value.",
  txt_result_type: "Result Type",
  txt_results: "Results",
  txt_rpm: "rpm",
  txt_rr: "RR",
  txt_rv5: "RV5",
  txt_sent: "Sent",
  txt_september: "September",
  txt_service: "Service",
  txt_set_fev1: "Set FEV1",
  txt_set_pef: "Set PEF",
  txt_setup_information: "You can set up information for the App. > phone",
  txt_severe: "Severe",
  txt_sex: "Sex",
  txt_sex_age: "Sex / Age",
  txt_sg: "SG",
  txt_show: "Show",
  txt_sign_in: "Sign In",
  txt_skeletal_muscle_mass: "Skeletal Muscle Mass",
  txt_skin_temp: "Skin temp",
  txt_specific_gravity: "Specific Gravity",
  txt_speed: "Speed",
  txt_spiro: "Spiro",
  txt_spirometer: "Spirometer",
  txt_spo2: "SpO₂",
  txt_stage: "Stage",
  txt_start_date: "start date",
  txt_start_date_camel: "Start Date",
  txt_start_time: "Start Time",
  txt_status: "Status",
  txt_step_count: "Step Count",
  txt_steps: "Steps",
  txt_stethoscope: "Stethoscope",
  txt_summary: "Summary",
  txt_sv1: "SV1",
  txt_svc_agree: "Agree to use the service",
  txt_system_setup: "System Setup",
  txt_systolic: "Systolic",
  txt_table: "Table",
  txt_tc: "TC",
  txt_temperature: "Temperature",
  txt_terms_of_service: "Terms of Service",
  txt_test: "Test",
  txt_test_date: "Test Date",
  txt_test_item: "Test Item(unit)",
  txt_tg: "TG",
  txt_time: "Time",
  txt_title: "Title",
  txt_title_check: "Check",
  txt_title_patient: "PATIENT",
  txt_today: "Today",
  txt_today_activity: "Today's Activity",
  txt_total_cholesterol: "Total Cholesterol(TC)",
  txt_trace: "trace",
  txt_transferred: "transferred",
  txt_trig: "Trig.",
  txt_type: "Type",
  txt_uacr: "UACR",
  txt_uncheck: "Uncheck",
  txt_unchecked_only: "Unchecked Only",
  txt_unconfirmed_report: "Unconfirmed Report To Be Reviewed",
  txt_unit_measure: "Units of Measure",
  txt_units: "Units",
  txt_upcoming_appointment: "you have an upcoming appointment",
  txt_uric_acid: "Uric Acid",
  txt_urinalysis: "Urinalysis",
  txt_urine_spot: "Urine(spot)",
  txt_urine_stick: "Urine(stick)",
  txt_uro: "Uro",
  txt_urobilinogen: "Urobilinogen",
  txt_val: "Val",
  txt_value: "Value",
  txt_vent_rate: "Vent. Rate",
  txt_video: "Video",
  txt_video_call: "VideoChat",
  txt_video_chat_stop: "VideoChat Stop",
  txt_videocall_archives: "Videocall Archives",
  txt_videochat_upcomming_call: "VideoChat > upcoming call",
  txt_videos: "Videos",
  txt_waist: "Waist",
  txt_waiting_list: "Waiting List",
  txt_week: "W",
  txt_weight: "Weight",
  txt_withl: "With",
  txt_yellowl: "Yellow",
};
