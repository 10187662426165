/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { ILoginProps } from "./ILogin";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import { Common } from "../../lib/utils/common";
import { LoadPanel } from "devextreme-react/load-panel";
import { alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import { SelectBox } from "devextreme-react";
loadMessages(lang);
locale("en");
export const Login: FunctionComponent<ILoginProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [panShow, setPanShow] = useState(Boolean);

  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      localStorage.setItem("lang", "en");
    };
    fetchArticles();
    if (localStorage.getItem("id") != null) {
      $("#txtId").val("" + localStorage.getItem("id"));
    }
  });
  const hStyle = { color: "red", display: "none" };

  const authHandler = async () => {
    setPanShow(true);
    const id = "" + $("#txtId").val();
    const pwd = $("#txtPwd").val();
    try {
      const doctorService = new DocotrDataProvider();

      await doctorService.loginDoctor(id, pwd).then(async (d: any) => {
        if (d.jwt == null) {
          setPanShow(false);
          alert(formatMessage("msg_login_fail"), "waring");
          $("lbErrPwd").css("display", "");
        } else {
          localStorage.setItem("jwt", d.jwt);
          localStorage.setItem("time", new Date().toString());
          localStorage.setItem("id", id);
          await doctorService.GetDoctors().then((e: any) => {
            localStorage.setItem("name", e.data.data.name);
            window.location.href = "/main";
          });
        }

        if ($("#chkReId").val() === "on") {
          localStorage.setItem("id", id);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const changeLang = async (e: any) => {
    locale(e.value);
    localStorage.setItem("lang", e.value);
    history.push("/auth/login");
  };
  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      authHandler();
    }
  };
  const position = { of: ".wrap" };
  return (
    <div className="comHeight">
      <div className="skip-navi">
        <a href="#content">본문 바로가기</a>
      </div>
      <React.Fragment>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={panShow}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </React.Fragment>
      <div className="wrap">
        <main id="content" className="content flex-ct">
          <section className="login">
            <header>
              <h1>Hicare Web</h1>
              <div className="opt">
                <label htmlFor="selLang" className="blind">
                  언어선택
                </label>
                <SelectBox
                  id="selLang"
                  className="lang"
                  width={160}
                  displayExpr="name"
                  valueExpr="value"
                  items={[
                    { name: "English(US)", value: "en" },
                    { name: "Italian", value: "it" },
                    { name: "German", value: "de" },
                    { name: "Thailand", value: "th" },
                    { name: "Korean", value: "ko" },
                  ]}
                  defaultValue={"en"}
                  onValueChanged={changeLang}
                />
              </div>
            </header>
            <div className="login__form">
              <h2>{formatMessage("txt_medical_staff")}</h2>
              <form action="" name="loginForm">
                <legend className="blind">로그인 정보 입력 폼</legend>
                <div className="tf">
                  <label htmlFor="lbId" id="lbId" className="blind">
                    ID
                  </label>
                  <input
                    type="text"
                    name="lbId"
                    id="txtId"
                    placeholder="ID"
                    title="아이디 입력"
                  />
                </div>
                <div className="tf">
                  <label htmlFor="lbPwd" id="lbPwd" className="blind">
                    Password
                  </label>
                  <input
                    type="password"
                    name="lbPwd"
                    id="txtPwd"
                    placeholder="Password"
                    title="비밀번호 입력"
                    onKeyPress={onKeyPress}
                  />
                </div>
                <div className="tf">
                  <input type="checkbox" name="chkReId" id="chkReId" />
                  <label htmlFor="chkReId">
                    {formatMessage("txt_remember_me")}
                  </label>
                </div>
                <div id="lbErrPwd" className="tf" style={hStyle}>
                  <label>
                    가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.
                  </label>
                </div>

                <button
                  type="button"
                  id="btnSave"
                  className="btn lg blue full"
                  onClick={authHandler}
                >
                  <span>{formatMessage("txt_sign_in")}</span>
                </button>
              </form>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Login;
