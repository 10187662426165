/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.generic.custom-hicareDoctor.css";
import "jquery-ui/themes/base/jquery-ui.css";
import "../../css/font-awesome.min.css";
import "../../css/layout.css";
import "../../css/custom.css";
import { IMeasurementProps, measurementType } from "./IMeasurement";
import $ from "jquery";
import "jquery-ui";
import { useHistory } from "react-router-dom";
import { Common } from "../../lib/utils/common";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import {
  DateBox,
  LoadPanel,
  SelectBox,
  TextBox,
  Button,
} from "devextreme-react";
import * as _ from "lodash";
import queryString from "query-string";
import "moment-timezone";
//추가
import { Line } from "react-chartjs-2";
import {
  Chart,
  Series,
  Strip,
  StripStyle,
  Legend,
  ValueAxis,
  Label,
  Font,
  VisualRange,
  ConstantLine,
  CommonSeriesSettings,
  Size,
  ArgumentAxis,
  Point,
  Grid,
  LoadingIndicator,
} from "devextreme-react/chart";
import * as d3 from "d3";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import moment from "moment";
import PopAlertEdit from "../popup/popAlert/PopAlertEdit";
import { parseInt } from "lodash";
import { confirm, alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);

export const Measurement: FunctionComponent<IMeasurementProps> = (props) => {
  locale("" + localStorage.getItem("lang"));
  const history = useHistory();
  const common = new Common();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzOffset = -1 * moment().tz(tz).utcOffset();

  const [measureType, setMeasureType] = useState([] as any);
  const [gridList, setGridList] = useState([] as any);
  const [gridListClone, setGridListClone] = useState([] as any);
  const [nameList, setNameList] = useState([] as any);
  const [gridFilterValue, setGridFilterValue] = useState<any>([]);
  const [totalCnt, setTotalCnt] = useState(String);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [name, setName] = useState(String as any);
  const [insungSeq, setInsungSeq] = useState(String as any);
  const [seq, setSeq] = useState(null as any);
  const [age, setAge] = useState(String as any);
  const [gender, setGender] = useState(String as any);
  const [deviceDt, setDeviceDt] = useState([] as any);
  const [device, setDevice] = useState(String as any);
  const [resultType, setResultType] = useState(String as any);
  const [subTap, setSubTap] = useState("none" as any);
  const [panShow, setPanShow] = useState(true as any);
  const [panShowEcg, setPanShowEcg] = useState(false as any);
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [sortReg, setSortReg] = useState("desc" as any);
  //추가
  const [measureChartBPData, setMeasureChartBPData] = useState([] as any);
  const [measureChartPulseData, setMeasureChartPulseData] = useState([] as any);
  const [measureChart1Data, setMeasureChart1Data] = useState([] as any);
  const [measureChart2Data, setMeasureChart2Data] = useState([] as any);
  const [measureChart3Data, setMeasureChart3Data] = useState([] as any);
  const [measureChart4Data, setMeasureChart4Data] = useState([] as any);
  const [seriesName1, setSeriesName1] = useState("");
  const [seriesName2, setSeriesName2] = useState("");
  const [seriesName3, setSeriesName3] = useState("");
  const [seriesName4, setSeriesName4] = useState("");
  const [chartUnit1, setChartUnit1] = useState("");
  const [chartUnit2, setChartUnit2] = useState("");
  const [chartUnit3, setChartUnit3] = useState("");
  const [chartUnit4, setChartUnit4] = useState("");

  const [measureChart1Options, setMeasureChart1Options] = useState([] as any);
  const [measureChart2Options, setMeasureChart2Options] = useState([] as any);
  const [measureChart3Options, setMeasureChart3Options] = useState([] as any);
  const [measureChart4Options, setMeasureChart4Options] = useState([] as any);

  const [asmaChart1Data, setAsmaChart1Data] = useState([] as any);
  const [asmaChart2Data, setAsmaChart2Data] = useState([] as any);

  const [fev1Max, setFev1Max] = useState(0);
  const [personalPef, setPersonalPef] = useState(0);
  const [personalFev1, setPersonalFev1] = useState(0);

  const [asmaChart1Options, setAsmaChart1Options] = useState([] as any);
  const [asmaChart2Options, setAsmaChart2Options] = useState([] as any);

  const [copd6Fev1Max, setCopd6Fev1Max] = useState(0);
  const [copd6Fev6Max, setCopd6Fev6Max] = useState(0);
  const [copd6Chart1Data, setCopd6Chart1Data] = useState([] as any);
  const [copd6Chart2Data, setCopd6Chart2Data] = useState([] as any);
  const [copd6Chart3Data, setCopd6Chart3Data] = useState([] as any);

  const [copd6Chart1Options, setCopd6Chart1Options] = useState([] as any);
  const [copd6Chart2Options, setCopd6Chart2Options] = useState([] as any);
  const [copd6Chart3Options, setCopd6Chart3Options] = useState([] as any);

  const [ecgChartList, setEcgChartList] = useState([] as any);
  const [ecgChart, setEcgChart] = useState([] as any);
  const [ecgMeasureDetailData, setEcgMeasureDetailData] = useState({} as any);
  const [ecgPopHeight, setEcgPopHeight] = useState("");

  const rel1 = { rel: "tab1" };
  const rel2 = { rel: "tab2" };

  const [measureSeq, setMeasureSeq] = useState(0);
  const capnoChartColor = ["#ffc000", "#0070c0", "#660066", "#00b050"];
  const capnoChartUnit = ["%", "bpm", "%", "bpm"];

  const capnoChartEmptyData = { datasets: [{ data: [] }], labels: [] };
  const capnoChartEmptyOptions = {};

  const [capnoMeasureDate, setCapnoMeasureDate] = useState<Date | undefined>(
    undefined
  );
  const [capnoDataTableJson, setCapnoDataTableJson] = useState([] as any);
  const [capnoData, setCapnoData] = useState([] as any);
  const [capnoLabel, setCapnoLabel] = useState([] as any);
  const [capnoChartData, setCapnoChartData] = useState([] as any);
  const [capnoChart1Data, setCapnoChart1Data] = useState([] as any);
  const [capnoChart2Data, setCapnoChart2Data] = useState([] as any);
  const [capnoChart3Data, setCapnoChart3Data] = useState([] as any);
  const [capnoChart4Data, setCapnoChart4Data] = useState([] as any);

  const [tickInterval, setTickInterval] = useState<number>(120);
  const [tickInterval1, setTickInterval1] = useState<number>(120);
  const [tickInterval2, setTickInterval2] = useState<number>(120);
  const [tickInterval3, setTickInterval3] = useState<number>(120);
  const [tickInterval4, setTickInterval4] = useState<number>(120);

  const [capnoChart1Options, setCapnoChart1Options] = useState([] as any);
  const [capnoChart2Options, setCapnoChart2Options] = useState([] as any);
  const [capnoChart3Options, setCapnoChart3Options] = useState([] as any);
  const [capnoChart4Options, setCapnoChart4Options] = useState([] as any);
  const [capnoDataOriginJson, setCapnoDataOriginJson] = useState([] as any);
  const [infoTitle, setInfoTitle] = useState(null as any);
  const intervalData = [
    { value: 120, text: "2min" },
    { value: 600, text: "10min" },
    { value: 1800, text: "30min" },
    { value: 3600, text: "1hour" },
    { value: 7200, text: "2hour" },
  ];

  //capnochart max value
  const [capnoChart1MaxValue, setCapnoChart1MaxValue] = useState<number>(50);
  const [capnoChart2MaxValue, setCapnoChart2MaxValue] = useState<number>(30);
  const [capnoChart3MaxValue, setCapnoChart3MaxValue] = useState<number>(100);
  const [capnoChart4MaxValue, setCapnoChart4MaxValue] = useState<number>(150);

  //chartpage
  const [currentPage1, setCurrentPage1] = useState<number>(1);
  const [currentPage2, setCurrentPage2] = useState<number>(1);
  const [currentPage3, setCurrentPage3] = useState<number>(1);
  const [currentPage4, setCurrentPage4] = useState<number>(1);

  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();

    //디자인 적용
    $(".dx-toolbar").css("display", "none");
    $(".tap").removeClass("on");
    $("#pageMeasurement").addClass("on");

    const doctorService = new DocotrDataProvider();

    const fetchArticles = async () => {
      //데이터 초기화
      const gridDt = await doctorService.GetPatientMeasureList();
      const gridChk = _.filter(gridDt.data.data, function (o) {
        return o.measureType != null && o.deviceCode != null && o.name != null;
      });
      let query = queryString.parse(props.location.search);
      //쿼리 스트링 존재시
      if (!_.isEmpty(query)) {
        let filter;
        if (query.insungSeq !== undefined) {
          const personInfo = _.filter(gridChk, {
            insungSeq: parseInt("" + query.insungSeq),
          })[0];
          setSubTap("");
          setName(personInfo.name);
          setInsungSeq(query.insungSeq);
          setSeq(personInfo.insungSeq);
          setAge(personInfo.age);
          setGender(personInfo.gender);
          setInfoTitle(
            personInfo.name +
              "(" +
              personInfo.gender +
              "/" +
              personInfo.age +
              ")"
          );
          filter = ["insungSeq", "=", query.insungSeq];
        } else {
          const measureType = _.filter(gridChk, {
            measureType: query.resultType,
          });
          const dt = _.uniqBy(measureType, "measureDevice");
          dt.unshift({ measureDevice: "All" });

          if (query.device !== undefined) {
            setDevice(query.device);
          } else {
            setDevice("All");
          }

          setDeviceDt(dt);
          setResultType(query.resultType);

          filter = [
            [
              "measureType",
              "contains",
              query.resultType === undefined ? "" : query.resultType,
            ],
            "and",
            [
              "deviceCode",
              "contains",
              query.device === undefined ? "" : query.device,
            ],
          ];
        }
        setGridFilterValue(filter);
      }

      //데이터 초기화
      setNameList(_.uniqBy(gridChk, "name"));
      setGridList(gridChk);
      setGridListClone(gridChk);
      setTotalCnt(gridDt.data.totalCount);

      const dt = _.intersectionBy(
        measurementType,
        _.uniqBy(gridChk, "measureType"),
        "measureType"
      );

      dt.unshift({
        measureType: "",
        name: "All",
      });
      setDeviceDt([{ measureDevice: "All" }]);
      setResultType("");
      setMeasureType(dt);
      setPanShow(false);
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const onAlertList = async (e: any) => {
    let alertDt = _.filter(gridListClone, { normalYn: "N" });
    alertDt = _.sortBy(alertDt, "measureUtcDt").reverse();

    setGridList(alertDt);
    setSortReg("aaa");
  };

  const renderTitleHeader = () => {
    return (
      <div>
        <span>Value </span>
        <i
          className="fa fa-bell"
          aria-hidden="true"
          style={{ color: "red", cursor: "pointer" }}
          onClick={onAlertList}
        ></i>
      </div>
    );
  };

  const showColumn = async () => {
    $(".dx-datagrid-column-chooser-button").click();
  };
  const onDateSelectChanged = async (e: any) => {
    if (e.value === "1W") {
      let cDate = new Date();
      cDate.setDate(cDate.getDate() - 7);
      setStartDate(cDate);
      setEndDate(new Date());
    } else if (e.value === "1M") {
      let cDate = new Date();
      var monthDate = new Date(cDate.setMonth(cDate.getMonth() - 1));
      setStartDate(monthDate);
      setEndDate(new Date());
    } else if (e.value === "Today") {
      setStartDate(new Date());
      setEndDate(new Date());
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };
  const onMeasureTypeChanged = async (e: any) => {
    const measureType = _.filter(gridList, { measureType: e.value });
    const dt = _.uniqBy(measureType, "measureDevice");

    dt.unshift({ measureDevice: "All" });
    setDevice("All");
    setResultType(e.value);

    setDeviceDt(dt);
  };
  const onMeasureDeviceChanged = async (e: any) => {
    setDevice(e.value);
  };
  const onNameChanged = async (e: any) => {
    if (e.value !== undefined) {
      const personInfo = _.filter(gridList, {
        insungSeq: parseInt(e.value),
      })[0];

      if (subTap !== "none") {
        setAge(personInfo.age);
        setGender(personInfo.gender);
      }
      setInsungSeq(e.value);
      setName(personInfo.name);
    }
  };
  const onSearch = async () => {
    $("#tableView").show();
    $("#graphView").hide();
    let filter: any;
    filter = [];

    if (startDate === undefined && endDate !== undefined) {
      alert(formatMessage("msg_select_start_end_date"), "alert");
      return false;
    }

    if (startDate !== undefined && endDate === undefined) {
      alert(formatMessage("msg_select_start_end_date"), "alert");
      return false;
    }

    if (startDate === null || endDate === null) {
      setGridList(gridListClone);
    } else if (startDate !== undefined && endDate !== undefined) {
      let sValue = moment(startDate).format("YYYY-MM-DD 00:00:00");
      let eValue = moment(endDate).format("YYYY-MM-DD 23:59:59");
      const a = _.filter(gridListClone, function (item) {
        return _.inRange(
          moment(item.measureUtcDt).valueOf(),
          moment(sValue).valueOf(),
          moment(eValue).valueOf()
        );
      });
      setGridList(a);
    } else {
      setGridList(gridListClone);
    }

    if (insungSeq !== "") {
      filter.push([
        "insungSeq",
        "contains",
        insungSeq === null ? "" : insungSeq,
      ]);
      filter.push("and");
    } else {
      filter = [];
    }
    console.log(device);
    filter.push([
      [
        "measureType",
        !common.valueChk(resultType) || resultType === "All"
          ? "<>"
          : "contains",
        !common.valueChk(resultType) || resultType === "All"
          ? "9999999"
          : resultType,
      ],
      "and",
      [
        "deviceCode",
        !common.valueChk(device) || device === "All" ? "<>" : "contains",
        !common.valueChk(device) || device === "All" ? "9999999" : device,
      ],
    ]);
    setGridFilterValue(filter);
  };
  const onSDateChanged = async (e: any) => {
    setStartDate(e.value);
  };
  const onEDateChanged = async (e: any) => {
    setEndDate(e.value);
  };

  //추가
  useEffect(() => {
    if (capnoChart1Data.length > 0) {
      changePageChart(1, 120, 50, 1);
      changePageChart(2, 120, 30, 1);
      changePageChart(3, 120, 100, 1);
      changePageChart(4, 120, 150, 1);
    }
  }, [capnoChart1Data]);
  const displayCapnoDataChart = (
    chartIndex: any,
    intervalValue: any,
    rangeValue: any,
    curPage: any
  ) => {
    let labels = [];
    let data = [];

    const pageSize = intervalValue / 4;

    let capnoMeasureDt: any = capnoMeasureDate;

    let j = 0;
    for (let i = (curPage - 1) * pageSize; i < curPage * pageSize; i++) {
      if (j === 0)
        capnoMeasureDt = dateAdd(
          capnoMeasureDt,
          "second",
          (curPage - 1) * intervalValue
        );
      else capnoMeasureDt = dateAdd(capnoMeasureDt, "second", 4);

      capnoMeasureDt = formatDate(capnoMeasureDt);
      labels.push(capnoMeasureDt.substring(10));

      if (i < capnoData.length) {
        data.push(capnoData[i][chartIndex - 1]);
      } else {
        data.push(null);
      }
      j++;
    }

    if (chartIndex == 1) {
      let chart1Data = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: "#ffc000",
            borderColor: "#ffc000",
            fill: false,
          },
        ],
      };

      let chart1Options = {
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "%",
              },
              ticks: {
                min: 0,
                max: parseInt(rangeValue),
              },
            },
          ],
        },
      };

      setCapnoChart1Data(chart1Data);
      setCapnoChart1Options(chart1Options);
    } else if (chartIndex == 2) {
      let chart2Data = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: "#0070c0",
            borderColor: "#0070c0",
            fill: false,
          },
        ],
      };

      let chart2Options = {
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "bpm",
              },
              ticks: {
                min: 0,
                max: parseInt(rangeValue),
              },
            },
          ],
        },
      };

      setCapnoChart2Data(chart2Data);
      setCapnoChart2Options(chart2Options);
    } else if (chartIndex == 3) {
      let chart3Data = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: "#660066",
            borderColor: "#660066",
            fill: false,
          },
        ],
      };

      let chart3Options = {
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "%",
              },
              ticks: {
                min: 0,
                max: parseInt(rangeValue),
              },
            },
          ],
        },
      };

      setCapnoChart3Data(chart3Data);
      setCapnoChart3Options(chart3Options);
    } else if (chartIndex == 4) {
      let chart4Data = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: "#00b050",
            borderColor: "#00b050",
            fill: false,
          },
        ],
      };

      let chart4Options = {
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "bpm",
              },
              ticks: {
                min: 0,
                max: parseInt(rangeValue),
              },
            },
          ],
        },
      };

      setCapnoChart4Data(chart4Data);
      setCapnoChart4Options(chart4Options);
    }
  };

  const changePageChart = (
    chartIndex: any,
    intervalValue: any,
    rangeValue: any,
    page: any
  ) => {
    let pageSize = intervalValue / 4;

    let pageCount = capnoData.length / pageSize;

    if (page < 1) page = 1;
    if (page > numPages(chartIndex, pageSize))
      page = numPages(chartIndex, pageSize);

    displayCapnoDataChart(chartIndex, intervalValue, rangeValue, page);

    if (page === 1) {
      $("#prevChartPage" + chartIndex).css("display", "none");
      //$(".capno-chart-arrow-left").css("display", "none");
    } else {
      $("#prevChartPage" + chartIndex).css("display", "");
      //$(".capno-chart-arrow-left").css("display", "");
    }

    if (page === numPages(chartIndex, pageSize)) {
      $("#nextChartPage" + chartIndex).css("display", "none");
      //$(".capno-chart-arrow-right").css("display", "none");
    } else {
      $("#nextChartPage" + chartIndex).css("display", "");
      //$(".capno-chart-arrow-right").css("display", "");
    }
  };

  const numPages = (chartIndex: any, pageSize: any) => {
    return Math.ceil(capnoData.length / pageSize);
  };

  const prevChartPage = async (chartIndex: any) => {
    if (chartIndex == 1) {
      if (currentPage1 > 1) {
        setCurrentPage1(currentPage1 - 1);

        changePageChart(
          chartIndex,
          tickInterval1,
          capnoChart1MaxValue,
          currentPage1 - 1
        );
      }
    } else if (chartIndex == 2) {
      if (currentPage2 > 1) {
        setCurrentPage2(currentPage2 - 1);

        changePageChart(
          chartIndex,
          tickInterval2,
          capnoChart2MaxValue,
          currentPage2 - 1
        );
      }
    } else if (chartIndex == 3) {
      if (currentPage3 > 1) {
        setCurrentPage3(currentPage3 - 1);

        changePageChart(
          chartIndex,
          tickInterval3,
          capnoChart3MaxValue,
          currentPage3 - 1
        );
      }
    } else if (chartIndex == 4) {
      if (currentPage4 > 1) {
        setCurrentPage4(currentPage4 - 1);

        changePageChart(
          chartIndex,
          tickInterval4,
          capnoChart4MaxValue,
          currentPage4 - 1
        );
      }
    }
  };

  const nextChartPage = async (chartIndex: any) => {
    if (chartIndex == 1) {
      if (currentPage1 < numPages(chartIndex, $("#interval1").val())) {
        setCurrentPage1(currentPage1 + 1);

        changePageChart(
          chartIndex,
          tickInterval1,
          capnoChart1MaxValue,
          currentPage1 + 1
        );
      }
    } else if (chartIndex == 2) {
      if (currentPage2 < numPages(chartIndex, $("#interval2").val())) {
        setCurrentPage2(currentPage2 + 1);

        changePageChart(
          chartIndex,
          tickInterval2,
          capnoChart2MaxValue,
          currentPage2 + 1
        );
      }
    } else if (chartIndex == 3) {
      if (currentPage3 < numPages(chartIndex, $("#interval3").val())) {
        setCurrentPage3(currentPage3 + 1);

        changePageChart(
          chartIndex,
          tickInterval3,
          capnoChart3MaxValue,
          currentPage3 + 1
        );
      }
    } else if (chartIndex == 4) {
      if (currentPage4 < numPages(chartIndex, $("#interval4").val())) {
        setCurrentPage4(currentPage4 + 1);

        changePageChart(
          chartIndex,
          tickInterval4,
          capnoChart4MaxValue,
          currentPage4 + 1
        );
      }
    }
  };

  const onChartIntervalSelectChanged = async (e: any) => {
    const chartIndex = e.element.id.slice(-1);
    const intervalValue = e.value;
    let rangeValue = 50;
    setTickInterval(e.value);

    if (chartIndex == 1) {
      setCurrentPage1(1);
      setTickInterval1(e.value);
      rangeValue = capnoChart1MaxValue;
    } else if (chartIndex == 2) {
      setCurrentPage2(1);
      setTickInterval2(e.value);
      rangeValue = capnoChart2MaxValue;
    } else if (chartIndex == 3) {
      setCurrentPage3(1);
      setTickInterval3(e.value);
      rangeValue = capnoChart3MaxValue;
    } else if (chartIndex == 4) {
      setCurrentPage4(1);
      setTickInterval4(e.value);
      rangeValue = capnoChart4MaxValue;
    }

    changePageChart(chartIndex, intervalValue, rangeValue, 1);
    //displayCapnoDataChart(chartIndex, 50, 1);
  };

  const onChartRangeSelectChanged = async (e: any) => {
    const chartIndex = e.element.id.slice(-1);
    let rangeValue = e.value.replace("bpm", "");
    if (chartIndex == 1) {
      setCapnoChart1MaxValue(e.value);
    } else if (chartIndex == 2) {
      setCapnoChart2MaxValue(e.value.replace("bpm", ""));
    } else if (chartIndex == 3) {
      setCapnoChart3MaxValue(e.value);
    } else if (chartIndex == 4) {
      setCapnoChart4MaxValue(e.value.replace("bpm", ""));
    }

    changePageChart(chartIndex, 120, rangeValue, 1);
  };

  const drawAsmaChart = async (measureData: any) => {
    console.log("asmaData", measureData);
    $("#spiroPef1").show();
    $("#spiroPef2").show();
    $("#spiroPefChart").show();
    $("#startDate").html(measureData.startDate);
    $("#endDate").html(measureData.endDate);
    $("#daysNumber").html(measureData.days);

    $("#sessionNumber").html(measureData.sessionCnt);
    $("#goodSession").html(measureData.goodSession);
    $("#badSession").html(measureData.badSession);

    $("#maxPefP").html(measureData.maxPefP / 10 + "%");
    $("#minPefP").html(measureData.minPefP / 10 + "%");
    $("#meanPefP").html(Math.round(measureData.meanPefP) / 10 + "%");

    $("#maxPef").html(measureData.maxPef + "(L/min)");
    $("#minPef").html(measureData.minPef + "(L/min)");
    $("#meanPef").html(measureData.meanPef + "(L/min)");

    $("#maxFev1P").html(measureData.maxFev1P / 10 + "%");
    $("#minFev1P").html(measureData.minFev1P / 10 + "%");
    $("#meanFev1P").html(Math.round(measureData.meanFev1P) / 10 + "%");

    $("#maxFev1").html(measureData.maxFev1 + "(L)");
    $("#minFev1").html(measureData.minFev1 + "(L)");
    $("#meanFev1").html(Math.round(measureData.meanFev1) + "(L)");

    //draw chart
    let pefData = [];
    let fev1Data = [];
    setFev1Max(3);
    for (let i = 0; i < measureData.graphData.length; i++) {
      pefData.push({
        date: measureData.graphData[i].measureUtcDt.substring(0, 10),
        pef: parseFloat(measureData.graphData[i].bestPef),
      });
      fev1Data.push({
        date: measureData.graphData[i].measureUtcDt.substring(0, 10),
        fev1: parseFloat(measureData.graphData[i].bestFev1),
      });

      if (measureData.graphData[i].bestFev1 > 3) setFev1Max(10);
    }

    setPersonalPef(parseFloat(measureData.graphData[0].pefPersonalBest));
    setPersonalFev1(measureData.graphData[0].fev1PersonalBest);
    setAsmaChart1Data(pefData);
    setAsmaChart2Data(fev1Data);
  };

  const drawLungMonitorChart = async (measureData: any) => {
    console.log("lungMonitorData", measureData);
    $("#spiroPef1").hide();
    $("#spiroPef2").hide();
    $("#spiroPefChart").hide();

    $("#startDate").html(measureData.startDate);
    $("#endDate").html(measureData.endDate);
    $("#daysNumber").html(measureData.days);

    $("#sessionNumber").html(measureData.sessionCnt);
    $("#goodSession").html(measureData.goodSession);
    $("#badSession").html(measureData.badSession);

    $("#maxFev1P").html(measureData.maxFev1P / 10 + "%");
    $("#minFev1P").html(measureData.minFev1P / 10 + "%");
    $("#meanFev1P").html(Math.round(measureData.meanFev1P) / 10 + "%");

    $("#maxFev1").html(measureData.maxFev1 + "(L)");
    $("#minFev1").html(measureData.minFev1 + "(L)");
    $("#meanFev1").html(Math.round(measureData.meanFev1) + "(L)");

    //draw chart
    let fev1Data = [];
    setFev1Max(3);
    for (let i = 0; i < measureData.graphData.length; i++) {
      fev1Data.push({
        date: measureData.graphData[i].measureUtcDt.substring(0, 10),
        fev1: parseFloat(measureData.graphData[i].bestFev1),
      });

      if (measureData.graphData[i].bestFev1 > 3) setFev1Max(10);
    }

    setPersonalFev1(measureData.graphData[0].fev1PersonalBest);
    setAsmaChart2Data(fev1Data);
  };

  const drawCopd6Chart = async (measureData: any) => {
    console.log("copd6Data", measureData);
    $("#copd6Table1 > tbody").empty();

    for (let i = 0; i < measureData.tableData1.length; i++) {
      let table1Row = "<tr>";
      table1Row +=
        "<td scope='row' className='fst'>" +
        measureData.tableData1[i].measureUtcDt +
        "</td>";
      table1Row += "<td>" + measureData.tableData1[i].gender + "</td>";
      table1Row += "<td>" + parseInt(measureData.tableData1[i].age) + "</td>";
      table1Row += "<td>" + measureData.tableData1[i].height + "</td>";
      table1Row += "<td>" + measureData.tableData1[i].lungAge + "</td>";
      table1Row += "<td>" + measureData.tableData1[i].fev1PPred + "</td>";
      table1Row += "</tr>";

      $("#copd6Table1 > tbody").append(table1Row);
    }

    $("#copd6Table2").find("tr:gt(1)").remove();
    for (let i = 0; i < measureData.tableData2.length; i++) {
      let table2Row = "<tr>";
      table2Row +=
        "<tr><td scope='row' className='fst' rowspan='3'>" +
        measureData.tableData2[i].measureUtcDt +
        "</td>";
      table2Row += "<td style='text-align: left'>FEV1(L)</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev1Predicted + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev1Best1 + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev1Best2 + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev1Best3 + "</td>";
      table2Row +=
        "<td>" + measureData.tableData2[i].fev1MeasuredBest + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev1PPred + "</td></tr>";
      table2Row += "<tr><td style='text-align: left'>FEV6(L)</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev6Predicted + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev6Best1 + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev6Best2 + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev6Best3 + "</td>";
      table2Row +=
        "<td>" + measureData.tableData2[i].fev6MeasuredBest + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev6PPred + "</td></tr>";
      table2Row += "<tr><td style='text-align: left'>FEV1/FEV6(Ratio)</td>";
      table2Row +=
        "<td>" + measureData.tableData2[i].fev1Fev6Predicted + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev1Fev6Best1 + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev1Fev6Best2 + "</td>";
      table2Row += "<td>" + measureData.tableData2[i].fev1Fev6Best3 + "</td>";
      table2Row +=
        "<td>" + measureData.tableData2[i].fev1Fev6MeasuredBest + "</td>";
      table2Row +=
        "<td>" + measureData.tableData2[i].fev1Fev6PPred + "</td></tr>";

      $("#copd6Table2 > tbody").append(table2Row);
    }

    $("#normalRow").css("height", measureData.greenZoneHeight + "px");
    $("#mildRow").css("height", measureData.yellowZoneHeight + "px");
    $("#moderateRow").css("height", measureData.orangeZoneHeight + "px");
    $("#servereRow").css("height", measureData.orangeZone + "px");

    const fev1PPredLast = parseInt(measureData.fev1PPredLast);
    console.log("fev1PPredLast", fev1PPredLast);
    if (fev1PPredLast >= 80) {
      $("#normalTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    } else if (fev1PPredLast < 80 && fev1PPredLast >= 50) {
      $("#mildTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    } else if (fev1PPredLast < 50 && fev1PPredLast >= 30) {
      $("#moderateTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    } else if (fev1PPredLast < 30) {
      $("#servereTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    }

    $("#normalTd2").html(">= " + parseInt(measureData.greenZone) + "%");
    $("#mildTd2").html("< " + parseInt(measureData.greenZone) + "%");
    $("#moderateTd2").html("< " + parseInt(measureData.yellowZone) + "%");
    $("#servereTd2").html("< " + parseInt(measureData.orangeZone) + "%");

    const fev1Fev6MeasuredBestLast = measureData.fev1Fev6MeasuredBestLast;
    console.log("fev1Fev6MeasuredBestLast", fev1Fev6MeasuredBestLast);
    if (fev1Fev6MeasuredBestLast >= 0.7) {
      $("#normalRightTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    } else if (
      fev1Fev6MeasuredBestLast < 0.7 &&
      fev1Fev6MeasuredBestLast >= 80
    ) {
      $("#stage1RightTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    } else if (
      fev1Fev6MeasuredBestLast < 0.7 &&
      fev1Fev6MeasuredBestLast < 80
    ) {
      $("#stage2RightTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    } else if (
      fev1Fev6MeasuredBestLast < 0.7 &&
      fev1Fev6MeasuredBestLast < 50
    ) {
      $("#stage3RightTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    } else if (
      fev1Fev6MeasuredBestLast < 0.7 &&
      fev1Fev6MeasuredBestLast < 30
    ) {
      $("#stage4RightTd1").html(
        "<i class='fa fa-arrow-left icon-title' aria-hidden='true'>"
      );
    }

    $("#stage1RightTd2").html(
      "<span>FEV1/FEV6 < 0.70 & FEV1 >= " +
        parseInt(measureData.greenZone) +
        "% Pred.</span>"
    );
    $("#stage2RightTd2").html(
      "<span>FEV1/FEV6 < 0.70 & FEV1 < " +
        parseInt(measureData.greenZone) +
        "% Pred.</span>"
    );
    $("#stage3RightTd2").html(
      "<span>FEV1/FEV6 < 0.70 & FEV1 < " +
        parseInt(measureData.yellowZone) +
        "% Pred.</span>"
    );
    $("#stage4RightTd2").html(
      "<span>FEV1/FEV6 < 0.70 & FEV1 < " +
        parseInt(measureData.orangeZone) +
        "% Pred.</span>"
    );

    let fev1Data = [];
    let fev6Data = [];
    let fev1Fev6Data = [];

    setCopd6Fev1Max(3);
    setCopd6Fev6Max(6);

    for (let i = 0; i < measureData.graphData.length; i++) {
      fev1Data.push({
        date: measureData.graphData[i].measureUtcDt.substring(0, 10),
        fev1: parseFloat(
          measureData.graphData[i].fev1MeasuredBest.replace(",", ".")
        ),
      });
      fev6Data.push({
        date: measureData.graphData[i].measureUtcDt.substring(0, 10),
        fev6: parseFloat(
          measureData.graphData[i].fev6MeasuredBest.replace(",", ".")
        ),
      });
      fev1Fev6Data.push({
        date: measureData.graphData[i].measureUtcDt.substring(0, 10),
        "fev1/fev6": parseFloat(
          measureData.graphData[i].fev1Fev6MeasuredBest.replace(",", ".")
        ),
      });

      if (measureData.graphData[i].fev1MeasuredBest.replace(",", ".") > 3)
        setCopd6Fev1Max(10);

      if (measureData.graphData[i].fev1MeasuredBest.replace(",", ".") > 6)
        setCopd6Fev6Max(10);
    }

    setCopd6Chart1Data(fev1Data);
    setCopd6Chart2Data(fev6Data);
    setCopd6Chart3Data(fev1Fev6Data);

    console.log("copd6Chart1Data", copd6Chart1Data);
  };

  const drawMeasureChart = async (chartData: any) => {
    console.log("chartData1", chartData);
    if (chartData.data.measureList.length > 0) {
      $("#measureNodata").hide();
      let measureData1 = [];
      let measureData2 = [];
      let measureData3 = [];
      let measureData4 = [];

      for (let i = 0; i < chartData.data.measureList.length; i++) {
        switch (resultType) {
          case "BP":
            $("#chartBP").show();
            $("#chartBP").css("width", "100%");
            $("#chart1").hide();
            $("#chart2").hide();
            $("#chart3").hide();
            $("#chart4").hide();
            measureData1.push({
              date: moment(chartData.data.measureList[i].measureUtcDt)
                .subtract(tzOffset, "minute")
                .format(
                  (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
                    " HH:mm:ss"
                ),
              systolic: parseFloat(chartData.data.measureList[i].measureValue1),
              diastolic: parseFloat(
                chartData.data.measureList[i].measureValue2
              ),
            });
            measureData2.push({
              date: moment(chartData.data.measureList[i].measureUtcDt)
                .subtract(tzOffset, "minute")
                .format(
                  (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
                    " HH:mm:ss"
                ),
              pulse: parseFloat(chartData.data.measureList[i].measureValue3),
            });

            break;
          case "BM":
          case "GL":
          case "TE":
          case "UC":
            $("#chartBP").hide();
            $("#chart1").show();
            $("#chart2").hide();
            $("#chart3").hide();
            $("#chart4").hide();
            measureData1.push({
              date: moment(chartData.data.measureList[i].measureUtcDt)
                .subtract(tzOffset, "minute")
                .format(
                  (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
                    " HH:mm:ss"
                ),
              measureValue1: parseFloat(
                chartData.data.measureList[i].measureValue1
              ),
            });

            setSeriesName1(getChartLabel(resultType, "1"));
            setChartUnit1(chartData.data.measureList[0].measureUnit1);

            break;
          case "BM":
          case "GH":
          case "IN":
          case "OX":
            $("#chartBP").hide();
            $("#chart1").show();
            $("#chart2").show();
            $("#chart3").hide();
            $("#chart4").hide();
            measureData1.push({
              date: moment(chartData.data.measureList[i].measureUtcDt)
                .subtract(tzOffset, "minute")
                .format(
                  (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
                    " HH:mm:ss"
                ),
              measureValue1: parseFloat(
                chartData.data.measureList[i].measureValue1
              ),
            });
            measureData2.push({
              date: moment(chartData.data.measureList[i].measureUtcDt)
                .subtract(tzOffset, "minute")
                .format(
                  (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
                    " HH:mm:ss"
                ),
              measureValue2: parseFloat(
                chartData.data.measureList[i].measureValue2
              ),
            });

            setSeriesName1(getChartLabel(resultType, "1"));
            setSeriesName2(getChartLabel(resultType, "2"));
            setChartUnit1(chartData.data.measureList[0].measureUnit1);
            setChartUnit2(chartData.data.measureList[0].measureUnit2);
            break;
          case "WE":
            $("#chartBP").hide();
            $("#chart1").show();
            $("#chart2").hide();
            $("#chart3").hide();
            $("#chart4").hide();
            measureData1.push({
              date: moment(chartData.data.measureList[i].measureUtcDt)
                .subtract(tzOffset, "minute")
                .format(
                  (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
                    " HH:mm:ss"
                ),
              measureValue1: parseFloat(
                chartData.data.measureList[i].measureValue1
              ),
            });

            setSeriesName1(getChartLabel(resultType, "1"));
            setChartUnit1(chartData.data.measureList[0].measureUnit1);
            break;
          case "CH":
            $("#chartBP").hide();

            if (
              !isNaN(parseFloat(chartData.data.measureList[i].measureValue1))
            ) {
              measureData1.push({
                date: moment(chartData.data.measureList[i].measureUtcDt)
                  .subtract(tzOffset, "minute")
                  .format(
                    (localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L") + " HH:mm:ss"
                  ),
                measureValue1: parseFloat(
                  chartData.data.measureList[i].measureValue1
                ),
              });
            }
            if (
              !isNaN(parseFloat(chartData.data.measureList[i].measureValue2))
            ) {
              measureData2.push({
                date: moment(chartData.data.measureList[i].measureUtcDt)
                  .subtract(tzOffset, "minute")
                  .format(
                    (localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L") + " HH:mm:ss"
                  ),
                measureValue2: parseFloat(
                  chartData.data.measureList[i].measureValue2
                ),
              });
            }
            if (
              !isNaN(parseFloat(chartData.data.measureList[i].measureValue3))
            ) {
              measureData3.push({
                date: moment(chartData.data.measureList[i].measureUtcDt)
                  .subtract(tzOffset, "minute")
                  .format(
                    (localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L") + " HH:mm:ss"
                  ),
                measureValue3: parseFloat(
                  chartData.data.measureList[i].measureValue3
                ),
              });
            }
            if (
              !isNaN(parseFloat(chartData.data.measureList[i].measureValue4))
            ) {
              measureData4.push({
                date: moment(chartData.data.measureList[i].measureUtcDt)
                  .subtract(tzOffset, "minute")
                  .format(
                    (localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L") + " HH:mm:ss"
                  ),
                measureValue4: parseFloat(
                  chartData.data.measureList[i].measureValue4
                ),
              });
            }

            if (measureData1.length > 0) {
              $("#chart1").show();
              setSeriesName1(getChartLabel(resultType, "1"));
            }
            if (measureData2.length > 0) {
              $("#chart2").show();
              setSeriesName2(getChartLabel(resultType, "2"));
            }
            if (measureData3.length > 0) {
              $("#chart3").show();
              setSeriesName3(getChartLabel(resultType, "3"));
            }
            if (measureData4.length > 0) {
              $("#chart4").show();
              setSeriesName4(getChartLabel(resultType, "4"));
            }
            break;
          default:
            break;
        }
      }

      setMeasureChart1Data(measureData1);
      setMeasureChart2Data(measureData2);
      setMeasureChart3Data(measureData3);
      setMeasureChart4Data(measureData4);
      setChartUnit1(chartData.data.measureList[0].measureUnit1);
      setChartUnit2(chartData.data.measureList[0].measureUnit1);
      setChartUnit3(chartData.data.measureList[0].measureUnit1);
      setChartUnit4(chartData.data.measureList[0].measureUnit1);
    } else {
      $("#measureNodata").show();
      $("#chartBP").hide();
      $("#chart1").hide();
      $("#chart2").hide();
      $("#chart3").hide();
      $("#chart4").hide();
    }
  };

  const onGraph = async () => {
    if (device === "" || device === undefined || device === "All") {
      alert(formatMessage("msg_select_device"), "alert");
      return false;
    }
    if (insungSeq === "" || insungSeq === undefined) {
      alert(formatMessage("msg_select_patient"), "alert");
      return false;
    }

    if (
      resultType == "EC" ||
      resultType == "UR" ||
      resultType == "CA" ||
      resultType == "MS" ||
      resultType == "CG" ||
      resultType == "NO" ||
      resultType == "BC"
    ) {
      alert(formatMessage("msg_not_support_graph"), "alert");
      return false;
    }

    try {
      $("#tableView").hide();
      $("#graphView").show();
      const doctorService = new DocotrDataProvider();
      let chartData = [];
      if (resultType === "PF") {
        if (device == "ASMA1") {
          $("#spirometerView1").show();
          $("#spirometerView2").hide();
          chartData = await doctorService.GetPatientSpirometerAsmaGraph(
            insungSeq,
            startDate,
            endDate
          );
          drawAsmaChart(chartData);
        } else if (device === "LUNGMONITOR") {
          $("#spirometerView1").show();
          $("#spirometerView2").hide();
          chartData = await doctorService.GetPatientSpirometerLungMonitorGraph(
            insungSeq,
            startDate,
            endDate
          );
          drawLungMonitorChart(chartData);
        } else if (device === "COPD6") {
          $("#spirometerView1").hide();
          $("#spirometerView2").show();
          chartData = await doctorService.GetPatientSpirometerCopd6Graph(
            insungSeq,
            startDate,
            endDate
          );
          drawCopd6Chart(chartData);
        } else {
          alert(formatMessage("msg_not_support_graph"), "alert");
          return false;
          //chartData = await doctorService.GetPatientMeasureGraph(
          //    insungSeq,
          //    resultType,
          //    device,
          //    startDate === undefined
          //        ? startDate
          //        : moment(startDate).format("YYYYMMDD"),
          //    endDate === undefined ? endDate : moment(endDate).format("YYYYMMDD")
          //);
          //drawMeasureChart(chartData);
        }
      } else {
        chartData = await doctorService.GetPatientMeasureGraph(
          insungSeq,
          resultType,
          device,
          startDate === undefined
            ? startDate
            : moment(startDate).format("YYYYMMDD"),
          endDate === undefined ? endDate : moment(endDate).format("YYYYMMDD")
        );
        drawMeasureChart(chartData);
      }
    } catch (error) {
      alert("검색 조건을 선택해주세요", "알림");
    }
  };

  const onCellRenderMeasureValue = (data: any) => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        title={data.data.measureUnit}
        onClick={() => measureDetailPop(data)}
        className={data.data.normalYn === "N" ? " valRed " : "link"}
        style={{ cursor: "pointer" }}
      >
        {data.data.measureType === "PH" || data.data.measureType === "MS"
          ? moment(data.data.measureUtcDt).format("YYYYMMDDhhmmss") + ".jpg"
          : data.value}
      </a>
    );
  };

  const onTableIntervalSelectChanged = async (e: any) => {
    const selectedValue = e.value;
    let capnoData = capnoDataOriginJson;
    var capnoDataTableJson = [];

    for (let i = 0; i < capnoData.length; i++) {
      if (selectedValue == "4s") {
        capnoDataTableJson = capnoDataOriginJson;
      } else if (selectedValue === "24s") {
        if (i % 6 === 0) capnoDataTableJson.push(capnoData[i]);
      } else if (selectedValue === "1min") {
        if (i % 15 === 0) capnoDataTableJson.push(capnoData[i]);
      } else if (selectedValue === "2min") {
        if (i % 30 === 0) capnoDataTableJson.push(capnoData[i]);
      } else if (selectedValue === "5min") {
        if (i % 75 === 0) capnoDataTableJson.push(capnoData[i]);
      } else if (selectedValue === "10min") {
        if (i % 150 === 0) capnoDataTableJson.push(capnoData[i]);
      } else if (selectedValue === "20min") {
        if (i % 300 === 0) capnoDataTableJson.push(capnoData[i]);
      } else if (selectedValue === "30min") {
        if (i % 450 === 0) capnoDataTableJson.push(capnoData[i]);
      }
    }

    setCapnoDataTableJson(capnoDataTableJson);
  };

  const measureDetailPop = (data: any) => {
    let capno_measure_date;
    setMeasureSeq(data.data.measureSeq);

    $("#microscopeDetail").css("display", "none");
    $("#stethoscopeDetail").css("display", "none");
    $("#ecgDetail").css("display", "none");
    $("#ecgDheartDetail").css("display", "none");
    $("#ecgCardipiaDetail").css("display", "none");
    $("#capnographyDetail").css("display", "none");
    $("li[name=measureValue]").remove();

    $("#patientInfo").html(
      data.data.name + "(" + data.data.gender + "/" + data.data.age + ")"
    );
    let directInputYn =
      data.data.directInputYn === "Y"
        ? "(" + formatMessage("txt_direct_input") + ")"
        : "";
    $("#deviceInfo").html(data.data.measureDevice + directInputYn);
    console.log(
      moment(data.data.measureUtcDt)
        .subtract(tzOffset, "minute")
        .format(
          (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
            " HH:mm:ss"
        )
    );
    $("#measureDate").html(
      moment(data.data.measureUtcDt)
        .subtract(tzOffset, "minute")
        .format(
          (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
            " HH:mm:ss"
        )
    );
    $("#transferDate").html(
      moment(data.data.inputUtcDt)
        .subtract(tzOffset, "minute")
        .format(
          (localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L") +
            " HH:mm:ss"
        )
    );

    console.log("data", data);

    const doctorService = new DocotrDataProvider();

    switch (data.data.measureType) {
      case "AC":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_step_count") +
            " : </span><strong>" +
            data.data.measureValue1 +
            "</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_activity_minutes") +
            " : </span><strong>" +
            data.data.measureValue2 +
            " min</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_calories_burned") +
            " : </span><strong>" +
            data.data.measureValue3 +
            " kcal</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_distance") +
            " : </span><strong>" +
            data.data.measureValue4 +
            " km</strong>"
        );
        break;
      case "BC":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_percent_body_fat") +
            " : </span><strong>" +
            data.data.measureValue3 +
            " %</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_bmi") +
            " : </span><strong>" +
            data.data.measureValue2 +
            " kg/㎡</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_weight") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " " +
            data.data.measureUnit1 +
            "</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_skeletal_muscle_mass") +
            " : </span><strong>" +
            data.data.measureValue4 +
            " kg</strong>"
        );
        break;
      case "BM":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_weight") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " " +
            data.data.measureUnit1 +
            "</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_height") +
            " : </span><strong>" +
            data.data.measureValue2 +
            " " +
            data.data.measureUnit2 +
            "</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_bmi") +
            " : </span><strong>" +
            data.data.measureValue3 +
            " " +
            data.data.measureUnit3 +
            "</strong>"
        );
        break;
      case "BP":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_blood_pressure") +
            " : </span><strong>" +
            data.data.measureValue1 +
            "/" +
            data.data.measureValue2 +
            " " +
            data.data.measureUnit1 +
            "</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_pulse") +
            " : </span><strong>" +
            data.data.measureValue3 +
            " bpm</strong>"
        );
        break;
      case "CA":
      case "CH":
        if (data.data.deviceCode == "LIPIDPRO") {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_tc") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_trig") +
              " : </span><strong>" +
              data.data.measureValue2 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_hdl") +
              " : </span><strong>" +
              data.data.measureValue3 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_ldl") +
              " : </span><strong>" +
              data.data.measureValue4 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
        } else if (data.data.deviceCode == "CARDIOCHEK") {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_tc") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_trig") +
              " : </span><strong>" +
              data.data.measureValue2 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_hdl") +
              " : </span><strong>" +
              data.data.measureValue3 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_ldl") +
              " : </span><strong>" +
              data.data.measureValue4 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_tc") +
              " / " +
              formatMessage("txt_hdl") +
              " : </span><strong>" +
              data.data.measureValue5 +
              " %</strong>"
          );
        } else {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_tc") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " " +
              data.data.measureUnit1 +
              "</strong>"
          );
        }
        break;
      case "GH":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_glucose") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " " +
            data.data.measureUnit1 +
            "</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_hct") +
            " : </span><strong>" +
            data.data.measureValue3 +
            " %</strong>"
        );
        break;
      case "GL":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_glucose") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " " +
            data.data.measureUnit1 +
            "</strong>"
        );
        break;
      case "HB":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_hba1c") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " " +
            data.data.measureUnit1 +
            "</strong>"
        );
        break;
      case "HR":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_heart_rate") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " bpm</strong>"
        );
        break;
      case "IN":
        if (data.data.measureValue3 !== "") {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_inr") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " INR (" +
              data.data.measureValue2 +
              " sec./ " +
              data.data.measureValue3 +
              "%Q)</strong>"
          );
        } else {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_inr") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " INR</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_pt") +
              " : </span><strong>" +
              data.data.measureValue2 +
              " sec</strong>"
          );
        }
        break;
      case "OX":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_spo2") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " %</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_pulse") +
            " : </span><strong>" +
            data.data.measureValue2 +
            " bpm</strong>"
        );
        break;
      case "PF":
      case "AS":
      case "CO":
      case "LM":
      case "OT":
        if (
          data.data.deviceCode == "ASMA1" ||
          data.data.deviceCode == "PF200"
        ) {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_pef") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " L/min</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev1") +
              " : </span><strong>" +
              data.data.measureValue2 +
              " L</strong>"
          );
        } else if (data.data.deviceCode == "COPD6") {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev1") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " L</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev6") +
              " : </span><strong>" +
              data.data.measureValue2 +
              " L</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev1_fev6_ratio") +
              " : </span><strong>" +
              data.data.measureValue3 +
              " %</strong>"
          );
        } else if (data.data.deviceCode == "LUNGMONITOR") {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev1") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " L</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev1") +
              "% : </span><strong>" +
              data.data.measureValue2 +
              " </strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev6") +
              "(ratio) : </span><strong>" +
              data.data.measureValue3 +
              " L</strong>"
          );
        } else {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fvc") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " L</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev1") +
              " : </span><strong>" +
              data.data.measureValue2 +
              " L</strong>"
          );
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_fev1") +
              "/" +
              formatMessage("txt_fvc") +
              " : </span><strong>" +
              data.data.measureValue3 +
              " %</strong>"
          );
        }
        break;
      case "TE":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_body_temperature") +
            " : </span><strong>" +
            data.data.measureValue +
            " " +
            data.data.measureUnit +
            "</strong>"
        );
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_measurement_site") +
            " : </span><strong>" +
            data.data.measureEtc +
            "</strong>"
        );
        break;
      case "UC":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_uric_acid") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " " +
            data.data.measureUnit1 +
            "</strong>"
        );
        break;
      case "WA":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_uric_acid") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " cm</strong>"
        );
        break;
      case "WE":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_weight") +
            " : </span><strong>" +
            data.data.measureValue1 +
            " " +
            data.data.measureUnit1 +
            "</strong>"
        );
        break;
      case "WR":
        $("#measureDetailInfo").append(
          "<li name='measureValue'><span>" +
            formatMessage("txt_steps") +
            " : </span><strong>" +
            data.data.measureValue1 +
            "</strong>"
        );
        break;
      case "CG":
        $("#capnographyDetail").css("display", "");
        $("#capnoData > tbody").empty();

        const getCapnographyData = async () => {
          const cgData = await doctorService.GetMeasureCapnographyInfo(
            data.data.measureSeq
          );

          setCapnoData(cgData.capnoData);
          setCapnoMeasureDate(cgData.measureUtcDt);
          setCapnoDataOriginJson(cgData.capnoDataJson);
          setCapnoDataTableJson(cgData.capnoDataJson);

          //console.log('capnomeasureutcdt', cgData.measureUtcDt);
          let capnoMeasureDt = cgData.measureUtcDt;
          let capnoChart1Data = [];
          let capnoChart1Label = [];
          let capnoChart2Data = [];
          let capnoChart3Data = [];
          let capnoChart4Data = [];

          let j = 0;
          for (let i = 0; i < 30; i++) {
            if (j === 0) capnoMeasureDt = dateAdd(capnoMeasureDt, "second", 0);
            else capnoMeasureDt = dateAdd(capnoMeasureDt, "second", 4);

            capnoMeasureDt = formatDate(capnoMeasureDt);
            capnoChart1Label.push(capnoMeasureDt.substring(10));

            if (i < cgData.capnoData.length) {
              capnoChart1Data.push(cgData.capnoData[i][0]);
              capnoChart2Data.push(cgData.capnoData[i][1]);
              capnoChart3Data.push(cgData.capnoData[i][2]);
              capnoChart4Data.push(cgData.capnoData[i][3]);
            } else {
              capnoChart1Data.push(null);
            }

            j++;
          }

          setCapnoLabel(capnoChart1Label);

          let chart1Data = {
            labels: capnoChart1Label,
            datasets: [
              {
                data: capnoChart1Data,
                backgroundColor: "#ffc000",
                borderColor: "#ffc000",
                fill: false,
              },
            ],
          };

          let chart2Data = {
            labels: capnoChart1Label,
            datasets: [
              {
                data: capnoChart2Data,
                backgroundColor: "#0070c0",
                borderColor: "#0070c0",
                fill: false,
              },
            ],
          };

          let chart3Data = {
            labels: capnoChart1Label,
            datasets: [
              {
                data: capnoChart3Data,
                backgroundColor: "#660066",
                borderColor: "#660066",
                fill: false,
              },
            ],
          };

          let chart4Data = {
            labels: capnoChart1Label,
            datasets: [
              {
                data: capnoChart4Data,
                backgroundColor: "#00b050",
                borderColor: "#00b050",
                fill: false,
              },
            ],
          };

          let chart1Options = {
            legend: {
              display: false,
            },
            responsive: true,
            scales: {
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "%",
                  },
                  ticks: {
                    min: 0,
                    max: 50,
                  },
                },
              ],
            },
          };

          let chart2Options = {
            legend: {
              display: false,
            },
            responsive: true,
            scales: {
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "bpm",
                  },
                  ticks: {
                    min: 0,
                    max: 30,
                  },
                },
              ],
            },
          };

          let chart3Options = {
            legend: {
              display: false,
            },
            responsive: true,
            scales: {
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "%",
                  },
                  ticks: {
                    min: 0,
                    max: 100,
                  },
                },
              ],
            },
          };

          let chart4Options = {
            legend: {
              display: false,
            },
            responsive: true,
            scales: {
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "bpm",
                  },
                  ticks: {
                    min: 0,
                    max: 150,
                  },
                },
              ],
            },
          };

          setCapnoChart1Data(chart1Data);
          setCapnoChart2Data(chart2Data);
          setCapnoChart3Data(chart3Data);
          setCapnoChart4Data(chart4Data);

          setCapnoChart1Options(chart1Options);
          setCapnoChart2Options(chart2Options);
          setCapnoChart3Options(chart3Options);
          setCapnoChart4Options(chart4Options);

          //chart series setting
          const chartSeries1 = [
            { value: "etco2", name: formatMessage("txt_etco2") + "(%)" },
          ];
          const chartSeries2 = [
            {
              value: "respiratoryRate",
              name: formatMessage("txt_respiratory_rate"),
            },
          ];
          const chartSeries3 = [
            { value: "spo2", name: formatMessage("txt_spo2") + "(%)" },
          ];
          const chartSeries4 = [
            { value: "pulse", name: formatMessage("txt_pulse_bpm") },
          ];
        };
        getCapnographyData();
        break;
      case "EC":
        if (data.data.deviceCode === "PC80B") {
          $("#measureDetailInfo").append(
            "<li name='measureValue'><span>" +
              formatMessage("txt_hr") +
              " : </span><strong>" +
              data.data.measureValue1 +
              " bpm</strong>"
          );
          $("#ecgDetail").css("display", "");

          setEcgPopHeight("700px");

          const getEcgData = async () => {
            const ecgData = await doctorService.GetMeasureEcgInfo(
              data.data.measureSeq
            );

            const ecgChartData = await doctorService.GetMeasureEcgGraphInfo(
              data.data.measureSeq
            );

            const xScale = d3
              .scaleLinear()
              .domain([0, 4500 - 1]) //x축 숫자 범위
              .range([0, 1590]); //모눈 이미지 범위
            const yScale = d3
              .scaleLinear()
              .domain([1900, 2600])
              .range([0, 300]);
            const scaleList = [];

            const scale: { x: number; y: number }[] = [];
            ecgChartData["graphData1"].forEach(function (d: any) {
              scale.push({ x: xScale(d.x), y: yScale(d.y) });
            });
            scaleList.push(scale);

            setEcgMeasureDetailData(ecgData[0]);
            setEcgChart([]);
            setEcgChartList(scaleList);
          };
          getEcgData();
        } else if (data.data.deviceCode === "CARDIPIA400H") {
          $("#ecgCardipiaDetail").css("display", "");
          setPanShowEcg(true);

          const getEcgCardipiaData = async () => {
            const ecgCardipiaData =
              await doctorService.GetMeasureEcgCardipiaInfo(
                data.data.measureSeq
              );

            const ecgCardipiaChartData =
              await doctorService.GetMeasureEcgCardipiaGraphInfo(
                data.data.measureSeq
              );

            const xScale = d3
              .scaleLinear()
              .domain([0, 5000]) //x축 숫자 범위
              .range([0, 1600]); //모눈 이미지 범위
            const yScale = d3.scaleLinear().domain([0, 40]).range([0, 80]);
            const scaleList = [];

            for (var i = 1; i < 13; i++) {
              const scale: { x: number; y: number }[] = [];
              ecgCardipiaChartData["graphData" + i].forEach(function (d: any) {
                scale.push({ x: xScale(d.x), y: yScale(d.y) });
              });
              scaleList.push(scale);
            }
            setEcgMeasureDetailData(ecgCardipiaData[0]);
            setEcgChart([]);
            setEcgChartList(scaleList);
          };
          getEcgCardipiaData();
        } else {
          $("#ecgDheartDetail").css("display", "");
        }
        break;
      case "MS":
      case "PH":
        const getPhotoData = async () => {
          const photoData = await doctorService.GetMeasurePhotoContentInfo(
            data.data.measureValue1
          );
          $("#imageInfo").attr("src", "data:image/gif;base64," + photoData);
          $("#imageDownload").attr(
            "href",
            "data:image/gif;base64," + photoData
          );
          $("#imageDownload").attr(
            "download",
            moment(data.data.measureUtcDt).format("YYYYMMDDHHMMSS") + ".jpg"
          );
          $("#microscopeDetail").css("display", "");
        };
        getPhotoData();
        break;
      case "ST":
        $("#stethoscopeDetail").css("display", "");
        $("#source").attr("src", "");
        const getStethoscopeData = async () => {
          const stData = await doctorService.GetMeasureStethoscopeInfo(
            data.data.measureSeq
          );

          const audioContent = stData.data.measureStethoscope_contents;
          const bufferBase64 = new Buffer(audioContent, "binary").toString(
            "base64"
          );

          const blob = new Blob([audioContent], { type: "audio/wav" });
          const blobUrl = URL.createObjectURL(blob);
          $("#source").attr("src", blobUrl);
        };
        getStethoscopeData();
        break;
    }
    common.openLayer("popMeasure7");
  };

  function paginateArray(array: any, pageSize: any, page: any) {
    --page;
    return array.slice(page * pageSize, (page + 1) * pageSize);
  }

  function dateAdd(date: any, interval: any, units: any) {
    let ret = new Date(date); //don't change original date
    var checkRollover = function () {
      if (ret.getDate() != date.getDate()) ret.setDate(0);
    };
    switch (interval.toLowerCase()) {
      case "year":
        ret.setFullYear(ret.getFullYear() + units);
        checkRollover();
        break;
      case "quarter":
        ret.setMonth(ret.getMonth() + 3 * units);
        checkRollover();
        break;
      case "month":
        ret.setMonth(ret.getMonth() + units);
        checkRollover();
        break;
      case "week":
        ret.setDate(ret.getDate() + 7 * units);
        break;
      case "day":
        ret.setDate(ret.getDate() + units);
        break;
      case "hour":
        ret.setTime(ret.getTime() + units * 3600000);
        break;
      case "minute":
        ret.setTime(ret.getTime() + units * 60000);
        break;
      case "second":
        ret.setTime(ret.getTime() + units * 1000);
        break;
      default:
        break;
    }
    return ret;
  }

  function formatDate(dateObj: any) {
    let currDate = dateObj.getDate();
    let currMonth = dateObj.getMonth();
    currMonth = currMonth + 1;
    let currYear = dateObj.getFullYear();
    let currMin = dateObj.getMinutes();
    let currHr = dateObj.getHours();
    let currSc = dateObj.getSeconds();
    if (currMonth.toString().length == 1) currMonth = "0" + currMonth;
    if (currDate.toString().length == 1) currDate = "0" + currDate;
    if (currHr.toString().length == 1) currHr = "0" + currHr;
    if (currMin.toString().length == 1) currMin = "0" + currMin;
    if (currSc.toString().length == 1) currSc = "0" + currSc;

    return (
      currYear +
      "-" +
      currMonth +
      "-" +
      currDate +
      " " +
      currHr +
      ":" +
      currMin +
      ":" +
      currSc
    );
  }

  function getChartLabel(resultType: any, chartIndex: any) {
    let resultValue: string = "";

    if (resultType == "GL") {
      resultValue = "Others";
    } else if (resultType == "GH") {
      if (chartIndex == "1") resultValue = "Others";
      else resultValue = "HCT";
    } else if (resultType == "CH") {
      if (chartIndex == "1") resultValue = "TC";
      else if (chartIndex == "2") resultValue = "TG";
      else if (chartIndex == "3") resultValue = "HDL";
      else if (chartIndex == "4") resultValue = "LDL";
    } else if (resultType == "OX") {
      if (chartIndex == "1") resultValue = "SpO₂";
      else resultValue = "Pulse";
    } else if (resultType == "IN") {
      if (chartIndex == "1") resultValue = "INR";
      else resultValue = "PT";
    } else if (resultType == "TE") {
      resultValue = "Body temperature";
    } else if (resultType == "UC") {
      resultValue = "Uric Acid";
    } else if (resultType == "WE") {
      if (chartIndex == "1") {
        resultValue = "Weight";
      } else if (chartIndex == "2") {
        resultValue = "BMI";
      } else if (chartIndex == "3") {
        resultValue = "Bodyfat";
      }
    } else if (resultType == "HR") {
      resultValue = "Heart rate";
    } else if (resultType == "BM") {
      if (chartIndex == "1") {
        resultValue = "Weight";
      } else resultValue = "BMI";
    }

    return resultValue;
  }

  return (
    <main id="content" className="content">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: ".wrap" }}
        visible={panShow}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <PopAlertEdit seq={seq} info={infoTitle} />
      <section className="sub-top">
        <header>
          <div className="tit-area">
            <i className="fa fa-heart icon-title" aria-hidden="true"></i>
            <div className="tit">
              <h2>{formatMessage("txt_measurement")}</h2>
              <span className="num">({gridList.length})</span>
            </div>
            <button
              type="button"
              onClick={() => {
                common.openLayer("popAlertLvInput");
              }}
              style={{ display: subTap === "none" ? "" : "none" }}
            >
              <i className="fa fa-bell-o icon-link" aria-hidden="true"></i>
              <span className="blind">{formatMessage("txt_alert_level")}</span>
            </button>
          </div>
          <div className="name-area" style={{ display: subTap }}>
            <p className="info" id="pInfoPatient">
              {infoTitle}
            </p>
            {/* <button
              type="button"
              className="ml10"
              onClick={() => {
                common.openLayer("popPhone");
              }}
            >
              <i className="fa fa-phone icon-link" aria-hidden="true"></i>
              <span className="blind">phone settings</span>
            </button> */}
            <button
              type="button"
              className="ml10"
              onClick={() => {
                common.openLayer("popAlertLvInput");
              }}
            >
              <i className="fa fa-bell-o icon-link" aria-hidden="true"></i>
              <span className="blind">{formatMessage("txt_alert_level")}</span>
            </button>
          </div>
        </header>

        <article className="search-form">
          <form action="" name="searchForm">
            <fieldset>
              <legend className="blind">검색폼</legend>
              <div className="form-item">
                <div className="opt short">
                  <label htmlFor="selDate" className="blind">
                    기간선택
                  </label>
                  <SelectBox
                    items={["All", "Today", "1W", "1M"]}
                    defaultValue={"All"}
                    onValueChanged={onDateSelectChanged}
                  />
                </div>
                <div className="form-wrap date-item">
                  <DateBox
                    value={startDate}
                    max={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onSDateChanged}
                  />

                  <span className="hyphen">~</span>

                  <DateBox
                    value={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onEDateChanged}
                  />
                </div>
              </div>
              <div className="form-item mt10">
                <div className="form-wrap">
                  <div className="opt insert">
                    <SelectBox
                      id="selMeasureType"
                      value={resultType}
                      items={_.filter(
                        _.sortBy(measureType, "name"),
                        function (o) {
                          return o.name !== "";
                        }
                      )}
                      placeholder=""
                      displayExpr="name"
                      valueExpr="measureType"
                      onValueChanged={onMeasureTypeChanged}
                    />
                  </div>

                  <div className="opt">
                    <label htmlFor="selDevice" className="blind">
                      디바이스선택
                    </label>
                    <SelectBox
                      id="selDevice"
                      value={device}
                      items={_.filter(
                        _.sortBy(deviceDt, "measureDevice"),
                        function (o) {
                          return (
                            o.measureDevice !== undefined &&
                            o.measureDevice !== null
                          );
                        }
                      )}
                      placeholder=""
                      displayExpr="measureDevice"
                      valueExpr="deviceCode"
                      onValueChanged={onMeasureDeviceChanged}
                    />
                  </div>
                </div>
              </div>
              <div className="form-item mt10">
                <div className="opt">
                  <label htmlFor="keywordSearch1" className="blind">
                    검색어
                  </label>
                  <SelectBox
                    id="keywordSearch1"
                    dataSource={_.orderBy(
                      nameList,
                      [(e) => e.name.toLowerCase()],
                      ["asc"]
                    )}
                    value={insungSeq}
                    placeholder="Name"
                    searchEnabled={true}
                    displayExpr="name"
                    valueExpr="insungSeq"
                    onValueChanged={onNameChanged}
                  />
                </div>
              </div>
              <div className="btn-wrap mt10">
                <button
                  type="button"
                  id="btnSearch"
                  className="btn md white docBtn"
                  onClick={onSearch}
                >
                  <i className="fa fa-search icon" aria-hidden="true"></i>
                  <span>{formatMessage("txt_table")}</span>
                </button>
                <button
                  type="button"
                  className="btn md white docBtn"
                  onClick={onGraph}
                >
                  <i className="fa fa-search icon" aria-hidden="true"></i>
                  <span>{formatMessage("btn_graphl")}</span>
                </button>
              </div>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont mt30" id="tableView">
        <header>
          <button
            type="button"
            id="btnSave"
            className="btn sm blue flex-mla"
            onClick={showColumn}
          >
            <span>Display</span>
          </button>
        </header>

        <div className="tbl-wrap">
          <DataGrid
            dataSource={gridList}
            filterValue={gridFilterValue}
            allowColumnResizing={true}
            columnMinWidth={50}
            columnAutoWidth={true}
            showBorders={true}
            noDataText="No data"
            className="type-ct"
          >
            <LoadPanel />
            <ColumnChooser enabled={true} mode="select" />
            <Column
              caption="No"
              cellRender={(e) => {
                return (
                  e.component.pageIndex() * e.component.pageSize() +
                  e.row.rowIndex +
                  1
                );
              }}
            />
            <Column dataField="insungSeq" visible={false} />
            <Column
              dataField="name"
              caption={formatMessage("txt_name")}
              cellTemplate={(container: any, options: any) => {
                $(
                  '<a href="/measurement?insungSeq=' +
                    options.data.insungSeq +
                    '"class="link">'
                )
                  .append(options.value)
                  .appendTo(container);
              }}
            />
            <Column dataField="gender" caption={formatMessage("txt_sex")} />
            <Column dataField="age" caption={formatMessage("txt_age")} />
            <Column
              dataField="measureUtcDt"
              caption={formatMessage("txt_date_measured")}
              cellRender={(e: any) => {
                return moment(e.value)
                  .subtract(tzOffset, "minutes")
                  .format(
                    (localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L") + " HH:mm:ss"
                  );
              }}
            />

            <Column
              dataField="measureType"
              caption={formatMessage("txt_result_type")}
              cellTemplate={(container: any, options: any) => {
                const rtnm = _.filter(measurementType, {
                  measureType: options.value,
                });
                if (rtnm.length > 0 && rtnm[0].name !== undefined) {
                  $(
                    '<a href="/measurement?resultType=' +
                      options.value +
                      '"class="link">'
                  )
                    .append(
                      _.filter(measurementType, {
                        measureType: options.value,
                      })[0].name
                    )
                    .appendTo(container);
                }
              }}
            />
            <Column
              dataField="deviceCode"
              caption={formatMessage("txt_device")}
              cellTemplate={(container: any, options: any) => {
                $(
                  '<a href="/measurement?resultType=' +
                    options.data.measureType +
                    "&device=" +
                    options.value +
                    '"class="link">'
                )
                  .append(options.data.measureDevice)
                  .appendTo(container);
              }}
            />
            <Column
              dataField="measureValue"
              caption={formatMessage("txt_value")}
              cellRender={onCellRenderMeasureValue}
              headerCellRender={renderTitleHeader}
            />
            <Column
              dataField="inputUtcDt"
              caption={formatMessage("txt_date_transferred")}
              cellRender={(e: any) => {
                return moment(e.value)
                  .subtract(tzOffset, "minutes")
                  .format(
                    (localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L") + " HH:mm:ss"
                  );
              }}
            />
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 25, 50, 100]}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        </div>

        <footer>
          <button
            type="button"
            id="btnSave"
            className="btn sm blue flex-mla"
            onClick={() => common.openLayer("popUnits")}
          >
            <span>{formatMessage("txt_unit_measure")}</span>
          </button>
        </footer>
      </section>

      <section
        id="graphView"
        className="sub-cont mt30"
        style={{ display: "none" }}
      >
        <div className="web-view display-none" id="measureNodata">
          <div style={{ textAlign: "center" }}>
            {formatMessage("txt_no_matches")}
          </div>
        </div>
        <div id="chartBP" className="chart-section">
          <Chart id="chart" title="" dataSource={measureChart1Data}>
            <CommonSeriesSettings argumentField="date" type="line" />
            <Series name="systolic" valueField="systolic" color="#418CF0" />
            <Series name="diastolic" valueField="diastolic" color="#FCB441" />
            <ValueAxis title="mmHg">
              <VisualRange startValue={0} />
              <Label customizeText={"e"} />
            </ValueAxis>
            <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="right"
            />
          </Chart>
          <Chart id="chart" title="" dataSource={measureChart2Data}>
            <Series
              name="pulse"
              argumentField="date"
              valueField="pulse"
              type="line"
              color="#418CF0"
            />
            <ValueAxis title="bpm">
              <VisualRange startValue={0} />
              <Label customizeText={""} />
            </ValueAxis>
            <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="right"
            />
          </Chart>
        </div>
        <div id="chart1" className="chart-section">
          <Chart id="chart" title="" dataSource={measureChart1Data}>
            <Series
              name={seriesName1}
              argumentField="date"
              valueField="measureValue1"
              type="line"
              color="#418CF0"
            />
            <ValueAxis title={chartUnit1}>
              <VisualRange startValue={0} />
              <Label customizeText={""} />
            </ValueAxis>
            <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="right"
            />
          </Chart>
        </div>
        <div id="chart2" className="chart-section">
          <Chart id="chart" title="" dataSource={measureChart2Data}>
            <Series
              name={seriesName2}
              argumentField="date"
              valueField="measureValue2"
              type="spline"
              color="#418CF0"
            />
            <ValueAxis title={chartUnit2}>
              <VisualRange startValue={0} />
              <Label customizeText={""} />
            </ValueAxis>
            <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="right"
            />
          </Chart>
        </div>
        <div id="chart3" className="chart-section">
          <Chart id="chart" title="" dataSource={measureChart3Data}>
            <Series
              name={seriesName3}
              argumentField="date"
              valueField="measureValue3"
              type="spline"
              color="#418CF0"
            />
            <ValueAxis title={chartUnit3}>
              <VisualRange startValue={0} />
              <Label customizeText={""} />
            </ValueAxis>
            <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="right"
            />
          </Chart>
        </div>
        <div id="chart4" className="chart-section">
          <Chart id="chart" title="" dataSource={measureChart4Data}>
            <Series
              name={seriesName4}
              argumentField="date"
              valueField="measureValue4"
              type="spline"
              color="#418CF0"
            />
            <ValueAxis title={chartUnit4}>
              <VisualRange startValue={0} />
              <Label customizeText={""} />
            </ValueAxis>
            <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="right"
            />
          </Chart>
        </div>
      </section>

      <div
        className="web-view graph"
        id="spirometerView1"
        style={{ display: "none" }}
      >
        <div className="data">
          <article>
            <header>
              <strong className="type-bold">
                {formatMessage("txt_summary")}
              </strong>
            </header>
            <div className="tbl-wrap">
              <table>
                <caption>{formatMessage("txt_summary")}</caption>
                <colgroup>
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="21%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col" className="fst">
                      {formatMessage("txt_start_date_camel")}
                    </th>
                    <td>
                      <span id="startDate" />
                    </td>
                    <th scope="col"> {formatMessage("txt_end_date_camel")}</th>
                    <td>
                      <span id="endDate" />
                    </td>
                    <th scope="col">{formatMessage("txt_number_of_days")}</th>
                    <td>
                      <span id="daysNumber" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="col" className="fst">
                      {formatMessage("txt_number_of_session")}
                    </th>
                    <td>
                      <span id="sessionNumber" />
                    </td>
                    <th scope="col">{formatMessage("txt_good_session")}</th>
                    <td>
                      <span id="goodSession" />
                    </td>
                    <th scope="col">{formatMessage("txt_bad_session")}</th>
                    <td>
                      <span id="badSession" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <article id="spiroPef1">
            <header>
              <strong>{formatMessage("txt_pef_diurnal_var")}</strong>
            </header>
            <div className="tbl-wrap">
              <table>
                <caption>{formatMessage("txt_pef_diurnal_var")}</caption>
                <colgroup>
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="21%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col" className="fst">
                      {formatMessage("txt_max")}
                    </th>
                    <td>
                      <span id="maxPefP" />
                    </td>
                    <th className="col">{formatMessage("txt_min")}</th>
                    <td>
                      <span id="minPefP" />
                    </td>
                    <th className="col">{formatMessage("txt_mean")}</th>
                    <td>
                      <span id="meanPefP" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <article id="spiroPef2">
            <header>
              <strong>{formatMessage("txt_pef")}</strong>
            </header>
            <div className="tbl-wrap">
              <table>
                <caption>{formatMessage("txt_pef")}</caption>
                <colgroup>
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="21%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col" className="fst">
                      {formatMessage("txt_max")}
                    </th>
                    <td>
                      <span id="maxPef" />
                    </td>
                    <th className="col">{formatMessage("txt_min")}</th>
                    <td>
                      <span id="minPef" />
                    </td>
                    <th className="col">{formatMessage("txt_mean")}</th>
                    <td>
                      <span id="meanPef" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <article>
            <header>
              <strong>{formatMessage("txt_fev1_diurnal_var")}</strong>
            </header>
            <div className="tbl-wrap">
              <table>
                <caption>{formatMessage("txt_fev1_diurnal_var")}</caption>
                <colgroup>
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="21%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col" className="fst">
                      {formatMessage("txt_max")}
                    </th>
                    <td>
                      <span id="maxFev1P" />
                    </td>
                    <th className="col">{formatMessage("txt_min")}</th>
                    <td>
                      <span id="minFev1P" />
                    </td>
                    <th className="col">{formatMessage("txt_mean")}</th>
                    <td>
                      <span id="meanFev1P" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <article>
            <header>
              <strong>{formatMessage("txt_fev1")}</strong>
            </header>
            <div className="tbl-wrap">
              <table>
                <caption>{formatMessage("txt_fev1")}</caption>
                <colgroup>
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="20%" />
                  <col width="13%" />
                  <col width="21%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col" className="fst">
                      {formatMessage("txt_max")}
                    </th>
                    <td>
                      <span id="maxFev1" />
                    </td>
                    <th className="col">{formatMessage("txt_min")}</th>
                    <td>
                      <span id="minFev1" />
                    </td>
                    <th className="col">{formatMessage("txt_mean")}</th>
                    <td>
                      <span id="meanFev1" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
        </div>
        <div className="chart">
          <article id="spiroPefChart">
            <header>
              <strong>{formatMessage("txt_pef")}</strong>
              <span>(L/min)</span>
            </header>
            <div className="view">
              {/*<Line type="line" data={asmaChart1Data} options={asmaChart1Options} />*/}
              <Chart id="chart" title="" dataSource={asmaChart1Data}>
                <Series
                  argumentField="date"
                  valueField="pef"
                  type="spline"
                  color="#000000"
                />
                <ValueAxis>
                  <VisualRange startValue={0} />
                  <Label customizeText={""} />
                  <ConstantLine
                    value={personalPef}
                    width={2}
                    color="#abfdb1"
                    dashStyle="dash"
                  >
                    <Label visible={false} />
                  </ConstantLine>
                  <Strip
                    startValue={0}
                    endValue={100}
                    color="rgba(252, 95, 95, 1)"
                  >
                    <Label text="Above average">
                      <Font color={""} />
                    </Label>
                  </Strip>
                  <Strip
                    startValue={100}
                    endValue={200}
                    color="rgba(255, 153, 0, 1)"
                  >
                    <Label text="Below average">
                      <Font color={""} />
                    </Label>
                  </Strip>
                  <Strip
                    startValue={200}
                    endValue={300}
                    color="rgba(252, 252, 71, 1)"
                  >
                    <Label text="Above average">
                      <Font color={""} />
                    </Label>
                  </Strip>
                  <Strip
                    startValue={300}
                    endValue={500}
                    color="rgba(100, 206, 110, 1)"
                  >
                    <Label text="Below average">
                      <Font color={""} />
                    </Label>
                  </Strip>
                  <StripStyle>
                    <Label>
                      <Font weight="500" size="14" />
                    </Label>
                  </StripStyle>
                </ValueAxis>
                <Legend visible={false} />
              </Chart>
            </div>
          </article>
          <article>
            <header>
              <strong>{formatMessage("txt_fev1")}</strong>
              <span>(L)</span>
            </header>
            <div className="view">
              <Chart id="chart" title="" dataSource={asmaChart2Data}>
                <Series
                  argumentField="date"
                  valueField="fev1"
                  type="spline"
                  color="#000000"
                />
                <ValueAxis>
                  <VisualRange startValue={0} />
                  <Label customizeText={""} />
                  <ConstantLine
                    value={personalFev1}
                    width={2}
                    color="#abfdb1"
                    dashStyle="dash"
                  >
                    <Label visible={false} />
                  </ConstantLine>
                  <Strip
                    startValue={0}
                    endValue={0.5}
                    color="rgba(252, 95, 95, 1)"
                  >
                    <Label text="Above average">
                      <Font color={""} />
                    </Label>
                  </Strip>
                  <Strip
                    startValue={0.5}
                    endValue={1}
                    color="rgba(255, 153, 0, 1)"
                  >
                    <Label text="Below average">
                      <Font color={""} />
                    </Label>
                  </Strip>
                  <Strip
                    startValue={1}
                    endValue={1.75}
                    color="rgba(252, 252, 71, 1)"
                  >
                    <Label text="Above average">
                      <Font color={""} />
                    </Label>
                  </Strip>
                  <Strip
                    startValue={1.75}
                    endValue={fev1Max}
                    color="rgba(100, 206, 110, 1)"
                  >
                    <Label text="Below average">
                      <Font color={""} />
                    </Label>
                  </Strip>
                  <StripStyle>
                    <Label>
                      <Font weight="500" size="14" />
                    </Label>
                  </StripStyle>
                </ValueAxis>
                <Legend visible={false} />
              </Chart>
            </div>
          </article>
        </div>
      </div>

      <section
        className="sub-cont mt30"
        id="spirometerView2"
        style={{ display: "none" }}
      >
        <div className="chart-wrap">
          <section className="data">
            <article>
              <header>
                <strong className="type-bold">
                  {formatMessage("txt_summary")}
                </strong>
              </header>
              <div className="tbl-wrap">
                <table className="type-ct" id="copd6Table1">
                  <caption>{formatMessage("txt_summary")}</caption>
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">{formatMessage("txt_date")}</th>
                      <th scope="col">{formatMessage("txt_gender")}</th>
                      <th scope="col">{formatMessage("txt_age")}</th>
                      <th scope="col">{formatMessage("txt_height")}</th>
                      <th scope="col">{formatMessage("txt_lung_age")}</th>
                      <th scope="col">
                        {formatMessage("txt_obstructive_index")}
                      </th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </article>
            <article>
              <header>
                <strong>{formatMessage("txt_results")}</strong>
              </header>
              <div className="tbl-wrap">
                <table className="type-ct" id="copd6Table2">
                  <caption>{formatMessage("txt_results")}</caption>
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">{formatMessage("txt_date")}</th>
                      <th scope="col"></th>
                      <th scope="col">{formatMessage("txt_predicted")}</th>
                      <th scope="col">{formatMessage("txt_test")}1</th>
                      <th scope="col">{formatMessage("txt_test")}2</th>
                      <th scope="col">{formatMessage("txt_test")}3</th>
                      <th scope="col">{formatMessage("txt_best")}</th>
                      <th scope="col">% {formatMessage("txt_pred")}</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </article>
          </section>
          <section className="chart">
            <div className="level">
              <article>
                <header>
                  <strong>{formatMessage("txt_obstructive_index")}</strong>
                </header>
                <div className="tbl-wrap">
                  <table className="type-sm type-color">
                    <caption>{formatMessage("txt_obstructive_index")}</caption>
                    <colgroup>
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr id="normalRow">
                        <th scope="row" className="fst lv1">
                          <span>{formatMessage("txt_normal")}</span>
                        </th>
                        <td
                          id="normalTd1"
                          className="icon border-bottom-hidden"
                        ></td>
                        <td id="normalTd2"></td>
                      </tr>
                      <tr id="mildRow">
                        <th scope="row" className="lv2">
                          <span>{formatMessage("txt_mild")}</span>
                        </th>
                        <td
                          id="mildTd1"
                          className="icon border-bottom-hidden"
                        ></td>
                        <td id="mildTd2"></td>
                      </tr>
                      <tr id="moderateRow">
                        <th scope="row" className="lv3">
                          <span>{formatMessage("txt_moderate")}</span>
                        </th>
                        <td
                          id="moderateTd1"
                          className="icon border-bottom-hidden"
                        ></td>
                        <td id="moderateTd2"></td>
                      </tr>
                      <tr id="servereRow">
                        <th scope="row" className="lv4">
                          <span>{formatMessage("txt_severe")}</span>
                        </th>
                        <td id="servereTd1" className="icon"></td>
                        <td id="servereTd2"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="desc">
                  {formatMessage("txt_fev1_measured_fev1_predicted")}
                </p>
              </article>
              <article>
                <header>
                  <strong>COPD(Gold) classNameification</strong>
                </header>
                <div className="tbl-wrap">
                  <table className="type-sm type-color">
                    <caption>{formatMessage("txt_obstructive_index")}</caption>
                    <colgroup>
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row" className="fst slv0">
                          <span>{formatMessage("txt_normal")}</span>
                        </th>
                        <td
                          id="normalRightTd1"
                          className="icon border-bottom-hidden"
                        ></td>
                        <td>
                          <span>
                            {formatMessage("txt_fev1")}&#47;
                            {formatMessage("txt_fev")}6 &#62;&#61; 0.70
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="slv1">
                          <span>StageⅠ</span>
                        </th>
                        <td
                          id="stage1RightTd1"
                          className="icon border-bottom-hidden"
                        ></td>
                        <td id="stage1RightTd2"></td>
                      </tr>
                      <tr>
                        <th scope="row" className="slv2">
                          <span>StageⅡ</span>
                        </th>
                        <td
                          id="stage2RightTd1"
                          className="icon border-bottom-hidden"
                        ></td>
                        <td id="stage2RightTd2"></td>
                      </tr>
                      <tr>
                        <th scope="row" className="slv3">
                          <span>StageⅢ</span>
                        </th>
                        <td
                          id="stage3RightTd1"
                          className="icon border-bottom-hidden"
                        ></td>
                        <td id="stage3RightTd2"></td>
                      </tr>
                      <tr>
                        <th scope="row" className="slv4">
                          <span>StageⅣ</span>
                        </th>
                        <td id="stage4RightTd1" className="icon"></td>
                        <td id="stage4RightTd2"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
            <article>
              <header>
                <strong>{formatMessage("txt_fev1")}</strong>
                <span>(L)</span>
              </header>
              <div className="view">
                <Chart id="chart" title="" dataSource={copd6Chart1Data}>
                  <Series
                    argumentField="date"
                    valueField="fev1"
                    type="spline"
                    color="#418CF0"
                  />
                  <ValueAxis>
                    <VisualRange startValue={0} endValue={copd6Fev1Max} />
                    <Label customizeText={""} />
                  </ValueAxis>
                  <Legend visible={false} />
                </Chart>
                {/*<Line data={copd6Chart1Data} options={copd6Chart1Options} />*/}
              </div>
            </article>
            <article>
              <header>
                <strong>{formatMessage("txt_fev")}6</strong>
                <span>(L)</span>
              </header>
              <div className="view">
                <Chart id="chart" title="" dataSource={copd6Chart2Data}>
                  <Series
                    argumentField="date"
                    valueField="fev6"
                    type="spline"
                    color="#418CF0"
                  />
                  <ValueAxis>
                    <VisualRange startValue={0} endValue={copd6Fev6Max} />
                    <Label customizeText={""} />
                  </ValueAxis>
                  <Legend visible={false} />
                </Chart>
                {/*<Line data={copd6Chart2Data} options={copd6Chart2Options} />*/}
              </div>
            </article>
            <article>
              <header>
                <strong>
                  {formatMessage("txt_fev1")}/{formatMessage("txt_fev")}6
                </strong>
                <span>(L)</span>
              </header>
              <div className="view">
                <Chart id="chart" title="" dataSource={copd6Chart3Data}>
                  <Series
                    argumentField="date"
                    valueField="fev1/fev6"
                    type="spline"
                    color="#418CF0"
                  />
                  <ValueAxis>
                    <VisualRange startValue={0} />
                    <Label customizeText={""} />
                  </ValueAxis>
                  <Legend visible={false} />
                </Chart>
                {/*<Line data={copd6Chart3Data} options={copd6Chart3Options} />*/}
              </div>
            </article>
          </section>
        </div>
      </section>

      <div
        className="pop-layer pop-layer--sm"
        id="popMeasure7"
        style={{ maxHeight: "800px" }}
      >
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: ".wrap" }}
          visible={panShowEcg}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />

        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>{formatMessage("txt_measurement")}</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <article>
                <h4>
                  <strong>{formatMessage("txt_patient")}</strong>
                </h4>
                <div className="data-list type-dot">
                  <ul>
                    <li>
                      <strong id="patientInfo"></strong>
                    </li>
                  </ul>
                </div>
              </article>

              <article>
                <h4>
                  <strong>{formatMessage("txt_summary")}</strong>
                </h4>
                <div className="data-list type-dot type-col2">
                  <ul id="measureDetailInfo">
                    <li className="full">
                      <span>{formatMessage("txt_device")} : </span>
                      <strong id="deviceInfo">BP170B</strong>
                    </li>
                    <li>
                      <span>{formatMessage("txt_date_measured")} : </span>
                      <strong id="measureDate">08/05/2021, 4:05:00 PM</strong>
                    </li>
                    <li>
                      <span>{formatMessage("txt_date_transferred")} : </span>
                      <strong id="transferDate">08/05/2021, 4:05:00 PM</strong>
                    </li>
                  </ul>
                </div>
                <div id="microscopeDetail">
                  <div id="imgDiv" className="svg-wrap mt10">
                    <TransformWrapper>
                      {({ zoomIn, zoomOut, resetTransform }: any) => (
                        <React.Fragment>
                          <ul className="icon-list mt20">
                            <li>
                              <a onClick={() => zoomIn()}>
                                <span className="blind">확대</span>
                                <i
                                  className="fa fa-search-plus"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a onClick={() => zoomOut()}>
                                <span className="blind">축소</span>
                                <i
                                  className="fa fa-search-minus"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a id="imageDownload">
                                <span className="blind">다운로드</span>
                                <i
                                  className="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                          </ul>
                          <TransformComponent>
                            <img id="imageInfo" alt="" width="600" />
                          </TransformComponent>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                  </div>
                </div>
                <div id="stethoscopeDetail">
                  <audio id="audioInfo" controls preload="auto">
                    <source id="source" type="audio/wav" />
                  </audio>
                </div>
              </article>
              <article id="ecgDetail">
                <div className="svg-wrap">
                  <div className="svg-wrap">
                    {ecgChartList.map((d: any, i: any) => {
                      return (
                        <div className="ecg-chart" style={{ width: "1600px" }}>
                          <Chart
                            id={"chart" + i}
                            dataSource={d}
                            style={{ position: "relative", top: "0px" }}
                          >
                            <Size height={300} width={1600} />
                            <CommonSeriesSettings
                              argumentField="x"
                              valueField="y"
                              type="spline"
                            >
                              <Point visible={false} />
                            </CommonSeriesSettings>
                            <Series color="#000000" />
                            <ArgumentAxis
                              allowDecimals={false}
                              axisDivisionFactor={1}
                              visible={false}
                            >
                              <Grid visible={false} />
                              <VisualRange startValue={0} endValue={1590} />
                              <Label visible={false} />
                            </ArgumentAxis>
                            <ValueAxis
                              allowDecimals={false}
                              inverted={false}
                              visible={false}
                              axisDivisionFactor={1}
                            >
                              <Grid visible={false} />
                              <Label visible={false} />
                              <VisualRange startValue={0} endValue={300} />
                            </ValueAxis>
                            <Legend visible={false} />
                          </Chart>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </article>
              <article id="ecgDheartDetail">
                <div className="flex-ct mt20 mb10">
                  <div id="origin" className="btn md blue"></div>
                </div>
                <div className="svg-wrap">
                  <img
                    src="../images/img-measurement2.png"
                    alt=""
                    width="600"
                  />
                </div>
              </article>
              <article id="ecgCardipiaDetail">
                <h4>
                  <strong>
                    {formatMessage("txt_measured_cardiac_rythm_events")}
                  </strong>
                </h4>
                <div className="data-list type-dot type-col3">
                  <ul>
                    <li>
                      <span>HR : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_hr}bpm
                      </strong>
                    </li>
                    <li>
                      <span>PR : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_pr}ms
                      </strong>
                    </li>
                    <li>
                      <span>QRS : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_qrs}ms
                      </strong>
                    </li>
                    <li>
                      <span>QT : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_qt}ms
                      </strong>
                    </li>
                    <li>
                      <span>QTc : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_qtc === null
                          ? ""
                          : ecgMeasureDetailData.measureCardipia_qtc}
                      </strong>
                    </li>
                    <li>
                      <span>QTr : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_qtr}ms
                      </strong>
                    </li>
                    <li>
                      <span>RV5 : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_rv5}mV
                      </strong>
                    </li>
                    <li>
                      <span>SV1 : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_sv1}mV
                      </strong>
                    </li>
                    <li>
                      <span>P/QRS/T/QRST axis : </span>
                      <strong>
                        {ecgMeasureDetailData.measureCardipia_p_qrs_t_qrst}
                      </strong>
                    </li>
                  </ul>
                </div>
                <p className="info-txt">
                  Unconfirmed Report To Be Reviewed ** [Adult] 3- Rate greater
                  than 100 bpm. Tachycardia.
                </p>
                <div
                  className="svg-wrap"
                  style={{
                    height: "300px",
                    overflowY: "scroll",
                    overflowX: "scroll",
                  }}
                >
                  {ecgChartList.map((d: any, i: any) => {
                    return (
                      <div className="ecg-chart" style={{ width: "1000px" }}>
                        <Chart
                          id={"chart" + i}
                          dataSource={d}
                          onDone={() => {
                            if (i === 11) {
                              setPanShowEcg(false);
                            }
                          }}
                        >
                          <Size height={100} width={1000} />
                          <CommonSeriesSettings
                            argumentField="x"
                            valueField="y"
                            type="line"
                          >
                            <Point visible={false} />
                          </CommonSeriesSettings>
                          <Series color="#000000" />
                          <ArgumentAxis allowDecimals={false} visible={false}>
                            <Grid visible={false} />
                            <VisualRange startValue={0} endValue={1600} />
                            <Label visible={false} />
                          </ArgumentAxis>
                          <ValueAxis
                            allowDecimals={false}
                            inverted={false}
                            visible={false}
                          >
                            <Grid visible={false} />
                            <Label visible={false} />
                            <VisualRange startValue={-50} endValue={100} />
                          </ValueAxis>
                          <Legend visible={false} />
                        </Chart>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="tbl-wrap"
                  style={{
                    height: "300px",
                    overflowY: "scroll",
                    overflowX: "scroll",
                  }}
                >
                  <table className="type-pop type-ct" id="cardipiaTable">
                    <caption>Measurement</caption>
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col" className="fst">
                          <strong></strong>
                        </th>
                        <th scope="col">
                          <strong>I</strong>
                        </th>
                        <th scope="col">
                          <strong>II</strong>
                        </th>
                        <th scope="col">
                          <strong>III</strong>
                        </th>
                        <th scope="col">
                          <strong>aVR</strong>
                        </th>
                        <th scope="col">
                          <strong>aVL</strong>
                        </th>
                        <th scope="col">
                          <strong>aVF</strong>
                        </th>
                        <th scope="col">
                          <strong>V1</strong>
                        </th>
                        <th scope="col">
                          <strong>V2</strong>
                        </th>
                        <th scope="col">
                          <strong>V3</strong>
                        </th>
                        <th scope="col">
                          <strong>V4</strong>
                        </th>
                        <th scope="col">
                          <strong>V5</strong>
                        </th>
                        <th scope="col">
                          <strong>V6</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.keys(ecgMeasureDetailData).map((e: any, z: any) => {
                        const d = ecgMeasureDetailData;
                        if (
                          !_.isNumber(d[e]) &&
                          d[e] !== null &&
                          d[e].indexOf(",") !== -1
                        ) {
                          const string = e
                            .replace("measureCardipia_", "")
                            .replace("_", "`");
                          return (
                            <tr>
                              <td scope="row" className="fst">
                                {string.charAt(0).toUpperCase() +
                                  string.slice(1)}
                                (mV)
                              </td>
                              {d[e].split(",").map((x: any, y: any) => {
                                return (
                                  <td scope="row" className="fst">
                                    {x}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </article>
              <article id="capnographyDetail" className="tab-wrap mt30">
                <ul className="tab-nav tab-nav--type2">
                  <li className="on" {...rel1}>
                    <a href="#none">
                      <span>{formatMessage("btn_trend_chart")}</span>
                    </a>
                  </li>
                  <li {...rel2}>
                    <a href="#none">
                      <span>{formatMessage("btn_trend_table")}</span>
                    </a>
                  </li>
                </ul>

                <div className="tab-cont on" id="tab1">
                  <p className="info-txt">
                    * {formatMessage("txt_start_time")}: 17:20:40 *{" "}
                    {formatMessage("txt_end_time")} : 17:21:24
                  </p>
                  <div className="chart-area">
                    <div className="chart">
                      <div>
                        <a onClick={() => prevChartPage(1)}>
                          <i
                            id="prevChartPage1"
                            className="fa fa-chevron-circle-left capno-chart-arrow-left"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <a onClick={() => nextChartPage(1)}>
                          <i
                            id="nextChartPage1"
                            className="fa fa-chevron-circle-right capno-chart-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                      <Line
                        data={capnoChart1Data}
                        options={capnoChart1Options}
                      />
                    </div>
                    <div className="sort sm">
                      <span>{formatMessage("txt_interval")}</span>
                      <SelectBox
                        id="interval1"
                        dataSource={intervalData}
                        displayExpr="text"
                        valueExpr="value"
                        defaultValue={intervalData[0].value}
                        onValueChanged={onChartIntervalSelectChanged}
                      />
                      <span>{formatMessage("txt_range")}</span>
                      <SelectBox
                        id="range1"
                        items={["8", "10", "20", "30", "40", "50"]}
                        defaultValue={"50"}
                        onValueChanged={onChartRangeSelectChanged}
                      />
                    </div>
                  </div>
                  <div className="chart-area">
                    <div className="chart">
                      <div>
                        <a onClick={() => prevChartPage(2)}>
                          <i
                            id="prevChartPage2"
                            className="fa fa-chevron-circle-left capno-chart-arrow-left"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <a onClick={() => nextChartPage(2)}>
                          <i
                            id="nextChartPage2"
                            className="fa fa-chevron-circle-right capno-chart-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                      <Line
                        type="line"
                        data={capnoChart2Data}
                        options={capnoChart2Options}
                      />
                    </div>
                    <div className="sort sm">
                      <span>{formatMessage("txt_interval")}</span>
                      <SelectBox
                        id="interval2"
                        dataSource={intervalData}
                        displayExpr="text"
                        valueExpr="value"
                        defaultValue={intervalData[0].value}
                        onValueChanged={onChartIntervalSelectChanged}
                      />
                      <span>{formatMessage("txt_range")}</span>
                      <SelectBox
                        id="range2"
                        items={["30bpm", "50bpm", "100bpm", "200bpm"]}
                        defaultValue={"30bpm"}
                        onValueChanged={onChartRangeSelectChanged}
                      />
                    </div>
                  </div>
                  <div className="chart-area">
                    <div className="chart">
                      <div>
                        <a onClick={() => prevChartPage(3)}>
                          <i
                            id="prevChartPage3"
                            className="fa fa-chevron-circle-left capno-chart-arrow-left"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <a onClick={() => nextChartPage(3)}>
                          <i
                            id="nextChartPage3"
                            className="fa fa-chevron-circle-right capno-chart-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                      <Line
                        type="line"
                        data={capnoChart3Data}
                        options={capnoChart3Options}
                      />
                    </div>
                    <div className="sort sm">
                      <span>{formatMessage("txt_interval")}</span>
                      <SelectBox
                        id="interval3"
                        dataSource={intervalData}
                        displayExpr="text"
                        valueExpr="value"
                        defaultValue={intervalData[0].value}
                        onValueChanged={onChartIntervalSelectChanged}
                      />
                      <span>{formatMessage("txt_range")}</span>
                      <SelectBox
                        id="range3"
                        items={["100%"]}
                        defaultValue={"100%"}
                        onValueChanged={onChartRangeSelectChanged}
                      />
                    </div>
                  </div>
                  <div className="chart-area">
                    <div className="chart">
                      <div>
                        <a onClick={() => prevChartPage(4)}>
                          <i
                            id="prevChartPage4"
                            className="fa fa-chevron-circle-left capno-chart-arrow-left"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <a onClick={() => nextChartPage(4)}>
                          <i
                            id="nextChartPage4"
                            className="fa fa-chevron-circle-right capno-chart-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                      <Line
                        type="line"
                        data={capnoChart4Data}
                        options={capnoChart4Options}
                      />
                    </div>
                    <div className="sort sm">
                      <span>{formatMessage("txt_interval")}</span>
                      <SelectBox
                        id="interval4"
                        dataSource={intervalData}
                        displayExpr="text"
                        valueExpr="value"
                        defaultValue={intervalData[0].value}
                        onValueChanged={onChartIntervalSelectChanged}
                      />
                      <span>{formatMessage("txt_range")}</span>
                      <SelectBox
                        id="range4"
                        items={["150bpm", "300bpm", "400bpm"]}
                        defaultValue={"150bpm"}
                        onValueChanged={onChartRangeSelectChanged}
                      />
                    </div>
                  </div>
                </div>

                <div className="tab-cont" id="tab2">
                  <div className="chart-area">
                    <div className="sort sm">
                      <span>Time interval</span>
                      <SelectBox
                        items={[
                          "4s",
                          "24s",
                          "1min",
                          "2min",
                          "5min",
                          "10min",
                          "20min",
                          "30min",
                        ]}
                        defaultValue={"4s"}
                        onValueChanged={onTableIntervalSelectChanged}
                      />
                    </div>
                  </div>
                  <div className="tbl-wrap mt20">
                    <DataGrid
                      dataSource={capnoDataTableJson}
                      columnHidingEnabled={true}
                      noDataText="No data"
                      selection={{ mode: "single" }}
                      hoverStateEnabled={true}
                      className="type-ct"
                    >
                      <ColumnChooser enabled={true} mode="select" />
                      <Column
                        dataField="time"
                        caption={formatMessage("txt_time")}
                        dataType="date"
                        format="yyyy-MM-dd hh:mm:ss"
                      />
                      <Column dataField="etco2" caption="EtCO₂(%)" />
                      <Column
                        dataField="respiratoryRate"
                        caption={formatMessage("txt_respiratory_rate")}
                      />
                      <Column dataField="spo2" caption="SpO₂(%)" />
                      <Column
                        dataField="pulse"
                        caption={
                          formatMessage("txt_pulse") +
                          "(" +
                          formatMessage("txt_bpm") +
                          ")"
                        }
                      />
                      <Paging defaultPageSize={10} />
                      <Pager
                        visible={true}
                        allowedPageSizes={[10, 20, "all"]}
                        displayMode={"full"}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                      />
                    </DataGrid>
                  </div>
                </div>
              </article>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <Button
                className="btn mlg grey"
                text="Close"
                type="default"
                stylingMode="contained"
                onClick={() => $(".popClose").trigger("click")}
              />
            </div>
          </footer>
        </section>
      </div>
    </main>
  );
};

export default Measurement;
