/* eslint-disable jsx-a11y/anchor-is-valid */
/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { IFilesProps } from "./IFiles";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import { DateBox, SelectBox } from "devextreme-react";
import * as _ from "lodash";
import moment from "moment";
import { alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);

export const Files: FunctionComponent<IFilesProps> = (props) => {
  locale("" + localStorage.getItem("lang"));
  const history = useHistory();
  const common = new Common();

  const [totalCnt, setTotalCnt] = useState(Number);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [name, setName] = useState(String);
  const [nameList, setNameList] = useState([] as any);
  const [fileList, setFileList] = useState([] as any);
  const [fileListClone, setFileListClone] = useState([] as any);
  const [pageNoList, setPageNoList] = useState([] as any);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const doctorService = new DocotrDataProvider();

    const fetchArticles = async () => {
      const gridDt = await doctorService.GetVideoList();
      $(".tap").removeClass("on");
      $("#pageFiles").addClass("on");
      //데이터 초기화
      const sort = _.sortBy(
        gridDt.data.data,
        "video_consultation_archives_input_utc_dt"
      ).reverse();
      console.log(sort);
      setNameList(_.uniqBy(gridDt.data.data, "callee_telnum"));
      setTotalCnt(gridDt.data.data.length);
      setFileListClone(sort);
      setFileList(getPaginatedItems(sort, 1, 10));

      const dtPage = [];
      for (var i = 0; i < gridDt.data.data.length / 10; i++) {
        dtPage.push(i);
      }
      setPageNoList(dtPage);
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const getPaginatedItems = (items: any, page: any, pageSize: any) => {
    let sValue = moment(startDate).format("YYYY-MM-DD 00:00:00");
    let eValue = moment(endDate).format("YYYY-MM-DD 23:59:59");
    let dt: any;
    let dateChk = common.valueChk(startDate) && common.valueChk(endDate);
    if (dateChk && common.valueChk(name)) {
      const date = _.filter(items, function (item) {
        const convertDate = moment(
          item["video_consultation_archives_input_utc_dt"]
        ).format("YYYY-MM-DD hh:mm:ss");
        return _.inRange(
          moment(convertDate).valueOf(),
          moment(sValue).valueOf(),
          moment(eValue).valueOf()
        );
      });

      dt = _.filter(date, { callee_telnum: name });
      setFileList(getPaginatedItems(dt, 1, pageSize));
    } else if (dateChk || common.valueChk(name)) {
      dt = dateChk
        ? _.filter(items, function (item) {
            const convertDate = moment(
              item["video_consultation_archives_input_utc_dt"]
            ).format("YYYY-MM-DD hh:mm:ss");

            return _.inRange(
              moment(convertDate).valueOf(),
              moment(sValue).valueOf(),
              moment(eValue).valueOf()
            );
          })
        : _.filter(items, { callee_telnum: name });
    } else {
      dt = items;
    }

    const pg = page || 1,
      pgSize = pageSize || 100,
      offset = (pg - 1) * pgSize,
      pagedItems = _.drop(dt, offset).slice(0, pgSize);

    return pagedItems;
  };
  const onValueChanged = async (e: any) => {
    const dtPage = [];
    for (var i = 0; i < totalCnt / e.value; i++) {
      dtPage.push(i + 1);
    }
    $(".num").removeClass("current");
    $("#page1").addClass("current");
    setPageSize(e.value);
    setPageNoList(dtPage);
    setFileList(getPaginatedItems(fileListClone, 1, e.value));
  };

  const onSelectChanged = async (e: any) => {
    let cDate = new Date();
    setEndDate(new Date());
    if (e.value === "1W") {
      cDate.setDate(cDate.getDate() - 7);
      setStartDate(cDate);
    } else if (e.value === "1M") {
      var monthDate = new Date(cDate.setMonth(cDate.getMonth() - 1));
      setStartDate(monthDate);
    } else {
      setStartDate(cDate);
    }
  };
  const onNameChanged = async (e: any) => {
    setName(e.value);
  };

  const onSDateChanged = async (e: any) => {
    setStartDate(e.value);

    if (endDate === undefined) {
      setEndDate(e.value);
    }
  };
  const onEDateChanged = async (e: any) => {
    if (startDate === undefined) {
      setStartDate(e.value);
    }
    setEndDate(e.value);
  };
  const onSearch = async () => {
    let sValue = moment(startDate).format("YYYY-MM-DD 00:00:00");
    let eValue = moment(endDate).format("YYYY-MM-DD 23:59:59");
    let dt: any;
    let dateChk = common.valueChk(startDate) && common.valueChk(endDate);

    if (dateChk || common.valueChk(name)) {
      if (dateChk && common.valueChk(name)) {
        const date = _.filter(fileListClone, function (item) {
          const convertDate = moment(
            item["video_consultation_archives_input_utc_dt"]
          ).format("YYYY-MM-DD hh:mm:ss");
          return _.inRange(
            moment(convertDate).valueOf(),
            moment(sValue).valueOf(),
            moment(eValue).valueOf()
          );
        });

        dt = _.filter(date, { callee_telnum: name });
        setFileList(getPaginatedItems(dt, 1, pageSize));
      } else {
        dt = dateChk
          ? _.filter(fileListClone, function (item) {
              const convertDate = moment(
                item["video_consultation_archives_input_utc_dt"]
              ).format("YYYY-MM-DD hh:mm:ss");

              return _.inRange(
                moment(convertDate).valueOf(),
                moment(sValue).valueOf(),
                moment(eValue).valueOf()
              );
            })
          : _.filter(fileListClone, { callee_telnum: name });
      }

      setFileList(getPaginatedItems(dt, 1, pageSize));
      const dtPage = [];
      for (var i = 0; i < dt.length / 10; i++) {
        dtPage.push(i);
      }
      setPageNoList(dtPage);
      setTotalCnt(dt.length);
      $(".num").removeClass("current");
      $("#page1").addClass("current");
    } else {
      if (!common.valueChk(startDate) && common.valueChk(endDate)) {
        alert(formatMessage("msg_select_start_end_date"), "alert");
        return false;
      }

      if (common.valueChk(startDate) && !common.valueChk(endDate)) {
        alert(formatMessage("msg_select_start_end_date"), "alert");
        return false;
      }
    }
  };

  const server =
    window.location.hostname === "doc.hicare.net"
      ? "https://api.hicare.net"
      : window.location.hostname === "dev.doc.hicare.net"
      ? "https://dev.api.hicare.net"
      : "http://localhost:3000";
  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="tit-area">
            <i className="fa fa-picture-o icon-title" aria-hidden="true"></i>
            <div className="tit">
              <h2>{formatMessage("mnu_files")}</h2>
              <span className="num">({totalCnt})</span>
            </div>
          </div>
        </header>
        <article className="search-form">
          <form action="" name="searchForm">
            <fieldset>
              <legend className="blind">검색폼</legend>
              <div className="form-item">
                <div className="opt short">
                  <label htmlFor="selDate" className="blind">
                    기간선택
                  </label>
                  <SelectBox
                    items={["All", "Today", "1W", "1M"]}
                    defaultValue={"All"}
                    onValueChanged={onSelectChanged}
                  />
                </div>
                <div className="form-wrap date-item">
                  <DateBox
                    value={startDate}
                    max={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onSDateChanged}
                  />
                  <span className="hyphen"> ~ </span>
                  <DateBox
                    value={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onEDateChanged}
                  />
                </div>
              </div>
              <div className="form-item mt10">
                <div className="opt">
                  <label htmlFor="selGraph" className="blind">
                    검색어
                  </label>
                  <SelectBox
                    dataSource={_.orderBy(
                      nameList,
                      [(e) => e.callee_telnum.toLowerCase()],
                      ["asc"]
                    )}
                    placeholder="Name"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"callee_telnum"}
                    displayExpr="callee_telnum"
                    valueExpr="callee_telnum"
                    onValueChanged={onNameChanged}
                  />
                </div>
                <button
                  type="button"
                  className="btn md white"
                  onClick={onSearch}
                >
                  <i className="fa fa-search icon" aria-hidden="true"></i>
                  <span className="blind">검색</span>
                </button>
              </div>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont">
        <article className="enviro" style={{ display: "none" }}>
          <header>
            <h3>
              <i className="fa fa-envira" aria-hidden="true"></i>
              <span>Healthy Environment</span>
            </h3>
          </header>
          <div className="enviro__wrap mt30">
            <header>Current</header>
            <div className="cont">
              <div className="enviro__item">
                <i
                  className="fa fa-thermometer-three-quarters"
                  aria-hidden="true"
                ></i>
                <strong>25</strong>
                <span>℃</span>
              </div>
              <div className="enviro__item">
                <i className="fa fa-tint" aria-hidden="true"></i>
                <strong>53</strong>
                <span>%</span>
              </div>
            </div>
          </div>
          <div className="enviro__wrap">
            <header>Month</header>
            <div className="cont">
              <button type="button" className="btn md blue">
                <span>View</span>
              </button>
            </div>
          </div>
        </article>
        <br />
        <header>
          <div className="sort sm">
            <span>{formatMessage("txt_show")}</span>
            <span>
              <SelectBox
                id="numSort"
                dataSource={[10, 20, 100]}
                defaultValue={10}
                onValueChanged={onValueChanged}
              />
            </span>
            <span>{formatMessage("txt_entries")}</span>
          </div>
        </header>

        <div className="gallery">
          {totalCnt !== 0 ? (
            fileList.map((d: any, i: any) => {
              return (
                <article className="gallery__item">
                  <div className="video">
                    <video
                      src={server + d["video_consultation_archives_path"]}
                      controls
                    ></video>
                  </div>
                  <ul className="info">
                    <li>
                      <span>{d["video_consultation_archives_id"]}.mp4</span>
                      <div className="icon-wrap">
                        <a href="#none" className="tip-icon">
                          <span className="blind">더보기</span>
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul className="tip">
                          <li>
                            <strong>
                              {moment(
                                d["video_consultation_archives_input_utc_dt"]
                              ).format("L")}
                            </strong>
                          </li>
                          <li>
                            <i
                              className="fa fa-user-circle-o"
                              aria-hidden="true"
                            ></i>
                            <span>
                              {moment(
                                d["video_consultation_archives_input_utc_dt"]
                              ).format("hh:mm:ss A")}
                              ~{" "}
                              {moment(
                                d["video_consultation_archives_input_utc_dt"]
                              )
                                .add(
                                  d["video_consultation_archives_duration"],
                                  "seconds"
                                )
                                .format("hh:mm:ss A")}
                            </span>
                          </li>
                          <li className="name">
                            <span>With {d["callee_telnum"]}</span>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <span>{d["video_consultation_archives_size"]}kb</span>
                      <span>{d["video_consultation_archives_resolution"]}</span>
                    </li>
                    <li>
                      <span>{d["callee_telnum"]}</span>
                      <span>
                        {moment(
                          d["video_consultation_archives_input_utc_dt"]
                        ).format("L")}
                      </span>
                    </li>
                  </ul>
                </article>
              );
            })
          ) : (
            <div className="gallery no-data" style={{ width: "100%" }}>
              <p>{formatMessage("txt_no_matches")}</p>
            </div>
          )}
        </div>

        <div className="pagination">
          <a
            className="first"
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              $(".num").removeClass("current");
              $("#page1").addClass("current");
              setFileList(getPaginatedItems(fileListClone, 1, pageSize));
            }}
          >
            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
            <span className="blind">first</span>
          </a>
          <a
            className="prev"
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              if (pageNo - 1 >= 1) {
                $(".num").removeClass("current");
                $("#page" + (pageNo - 1)).addClass("current");
                setPageNo(pageNo - 1);
                setFileList(
                  getPaginatedItems(fileListClone, pageNo - 1, pageSize)
                );
              }
            }}
          >
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span className="blind">prev</span>
          </a>
          {pageNoList.map((d: any, i: any) => {
            return (
              <a
                id={"page" + (i + 1)}
                className={"num " + (i === 0 ? "current" : "")}
                style={{ cursor: "pointer" }}
                onClick={(e: any) => {
                  $(".num").removeClass("current");
                  $("#page" + (i + 1)).addClass("current");
                  setPageNo(i + 1);
                  setFileList(
                    getPaginatedItems(fileListClone, i + 1, pageSize)
                  );
                }}
              >
                <span>{i + 1}</span>
              </a>
            );
          })}
          <a
            className="next"
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              if (pageNoList.length >= pageNo + 1) {
                $(".num").removeClass("current");
                $("#page" + (pageNo + 1)).addClass("current");
                setPageNo(pageNo + 1);
                setFileList(
                  getPaginatedItems(fileListClone, pageNo + 1, pageSize)
                );
              }
            }}
          >
            <i className="fa fa-angle-right" aria-hidden="true"></i>
            <span className="blind">next</span>
          </a>
          <a
            className="last"
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              $(".num").removeClass("current");
              $("#page" + pageNoList.length).addClass("current");

              setFileList(
                getPaginatedItems(fileListClone, pageNoList.length, pageSize)
              );
            }}
          >
            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
            <span className="blind">last</span>
          </a>
        </div>
      </section>
    </main>
  );
};

export default Files;
