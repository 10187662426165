export interface IAssessmentProps {}
export const q01 = [
  "Never",
  "Hardly ever",
  "A few minute",
  "Several times",
  "Many times",
  "A great many times",
  "Unable to sleep because of asthma",
];

export const q02 = [
  "No symptoms",
  "Very mild symptoms",
  "Mild symptoms",
  "Moderate symptoms",
  "Quite severe symptoms",
  "Severe symptoms",
  "Very severe symptoms",
];

export const q03 = [
  "Not limited at all",
  "Very slightly limited",
  "Slightly limited",
  "Moderately limited",
  "Very limited",
  "Extremely limited",
  "Totally limited",
];

export const q04 = [
  "None",
  "A very little",
  "A little",
  "A moderate amount",
  "Quite a lot",
  "A great deal",
  "A very great deal",
];
export const q05 = [
  "Not at all",
  "Hardly any of the time",
  "A little of the time",
  "A moderate amount of the time",
  "A lot of the time",
  "Most of the time",
  "All the time",
];

export const q06 = [
  "None",
  "1-2 puffs most days",
  "3-4 puffs most days",
  "5-8 puffs most days",
  "9-12 puffs most days",
  "13-16 puffs most days",
  "More than 16 puffs most days",
];
