/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { IPopupProps } from "./IPopup";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../../lib/utils/common";

import * as _ from "lodash";
import {
  DataGrid,
  Button,
  DropDownBox,
  TextBox,
  SelectBox,
  NumberBox,
  Validator,
} from "devextreme-react";
import { DocotrDataProvider } from "../../../lib/service/DocotrDataProvider";
import { RequiredRule } from "devextreme-react/data-grid";
import { useHistory } from "react-router";
import moment from "moment";
export const PopPhone: FunctionComponent<IPopupProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [phoneList, setPhoneList] = useState([]);
  const [phoneName, setPhoneName] = useState(String as any);
  const [phoneTitle, setPhoneTitle] = useState(String as any);
  const [phoneNum1, setPhoneNum1] = useState(String as any);
  const [phoneNum2, setPhoneNum2] = useState(String as any);
  const [phoneNum3, setPhoneNum3] = useState(String as any);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const doctorService2 = new DocotrDataProvider();

    const fetchArticles = async () => {
      const dt = await doctorService2.GetPatientList();
      setPhoneList(dt.data.data);
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);
  const onPhoneNameChanged = async (e: any) => {
    setPhoneName(e.value);
  };
  const onPhoneTitleChanged = async (e: any) => {
    setPhoneTitle(e.value);
  };
  const onPhoneNum1Changed = async (e: any) => {
    setPhoneNum1(e.value);
  };
  const onPhoneNum2Changed = async (e: any) => {
    setPhoneNum2(e.value);
  };
  const onPhoneNum3Changed = async (e: any) => {
    setPhoneNum3(e.value);
  };
  const onPhoneSetting = async (e: any) => {
    if (e.validationGroup.validate().isValid) {
      const json = {
        insungSeq: phoneName,
        contactTelnum: phoneNum1 + "-" + phoneNum2 + "-" + phoneNum3,
        contactTitle: phoneTitle,
      };
      const doctorService = new DocotrDataProvider();
      doctorService.addPatientContact(json).then(async (d: any) => {
        $(".popClose").trigger("click");
      });
    }
  };

  return (
    <div className="pop-layer pop-layer--sm" id="popPhone">
      <section className="pop-layer__wrap">
        <header>
          <h3>
            <strong>Phone Settings</strong>
          </h3>
          <span className="icon icon--close lg popClose"></span>
        </header>

        <div className="cont">
          <div className="cont__wrap">
            <p className="alert-notice mb20">
              <i className="fa fa-info-circle"></i>
              <span>You can set up information for the App. phone</span>
            </p>
            <div className="phone-wrap">
              <article>
                <h4>
                  <strong>Patient</strong>
                </h4>
                <SelectBox
                  valueExpr="insungSeq"
                  displayExpr="name"
                  value={phoneName}
                  dataSource={phoneList}
                  deferRendering={false}
                  placeholder="Name"
                  showClearButton={true}
                  onValueChanged={onPhoneNameChanged}
                >
                  {/* <Validator>
                    <RequiredRule message="Name is required" />
                  </Validator> */}
                </SelectBox>
              </article>
              <article>
                <h4>
                  <strong>Phone Title</strong>
                </h4>
                <div className="tf mb10">
                  <TextBox
                    defaultValue="1"
                    value={phoneTitle}
                    onValueChanged={onPhoneTitleChanged}
                  >
                    {/* <Validator>
                      <RequiredRule message="Title is required" />
                    </Validator> */}
                  </TextBox>
                </div>
                <h4>
                  <strong>Phone Number</strong>
                </h4>
                <div className="tf type type--03">
                  <TextBox
                    className="insert"
                    value={phoneNum1}
                    defaultValue="010"
                    onValueChanged={onPhoneNum1Changed}
                  >
                    {/* <Validator>
                      <RequiredRule message="Phone number is required" />
                    </Validator> */}
                  </TextBox>
                  <span className="hyphen"> - </span>
                  <NumberBox
                    className="insert"
                    value={phoneNum2}
                    onValueChanged={onPhoneNum2Changed}
                  >
                    {/* <Validator>
                      <RequiredRule message="Phone number is required" />
                    </Validator> */}
                  </NumberBox>
                  <span className="hyphen"> - </span>
                  <NumberBox
                    className="insert"
                    value={phoneNum3}
                    onValueChanged={onPhoneNum3Changed}
                  >
                    {/* <Validator>
                      <RequiredRule message="Phone number is required" />
                    </Validator> */}
                  </NumberBox>
                </div>
                <p className="desc-txt">
                  * Phone number cannot include the characters
                </p>
              </article>
            </div>
          </div>
        </div>

        <footer>
          <div className="btn-wrap">
            <button
              id="sekk"
              onClick={() => {
                setPhoneName("");
                setPhoneTitle("");
                setPhoneNum1(" ");
                setPhoneNum2(" ");
                setPhoneNum3(" ");
              }}
              style={{
                display: "none",
              }}
            >
              Activate Lasers
            </button>
            <Button
              className="btn mlg blue"
              text="Save"
              type="success"
              height={49}
              stylingMode="contained"
              useSubmitBehavior={true}
              onClick={(e: any) => {
                onPhoneSetting(e);
              }}
            />
            <Button
              className="btn mlg grey"
              text="Cancel"
              type="default"
              stylingMode="contained"
              onClick={() => $(".popClose").trigger("click")}
            />
          </div>
        </footer>
      </section>
    </div>
  );
};

export default PopPhone;
