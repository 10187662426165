/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.generic.custom-hicareDoctor.css";
import "jquery-ui/themes/base/jquery-ui.css";
import "../../css/font-awesome.min.css";
import "../../css/layout.css";

import {
  IMeasurementMobileProps,
  measurementType,
  measureItemText,
} from "./IMeasurementMobile";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import * as _ from "lodash";

import { Line } from "react-chartjs-2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Chart,
  Series,
  Strip,
  StripStyle,
  Legend,
  ValueAxis,
  Label,
  Font,
  VisualRange,
  ConstantLine,
  CommonSeriesSettings,
  Size,
  AdaptiveLayout,
  ArgumentAxis,
  Point,
  Grid,
} from "devextreme-react/chart";
import * as d3 from "d3";
import moment from "moment";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);

export const MeasurementMobile: FunctionComponent<IMeasurementMobileProps> = (
  props
) => {
  const common = new Common();

  const search = window.location.search;
  console.log(search);
  const params = new URLSearchParams(search);
  const insungSeq = params.get("insungSeq");
  const measureType = params.get("measureType");
  const deviceCode = params.get("deviceCode");
  const startDate = params.get("startDate");
  const endDate = params.get("endDate");
  const layout = params.get("layout");
  const timezoneOffset = params.get("timezoneOffset");
  const language = params.get("locale");
  let paramLang = "en";
  if (language == "it-IT") paramLang = "it";
  else if (language == "de-DE") paramLang = "de";
  else if (language == "th-TH") paramLang = "te";
  else if (language == "ko-KR") paramLang = "ko";
  else if (language == "en-US") paramLang = "en";

  locale("" + paramLang);
  //추가
  const [measureTableData, setMeasureTableData] = useState([] as any);
  const [measureDetailData, setMeasureDetailData] = useState([] as any);

  const [measureMediaTableData, setMeasureMediaTableData] = useState([] as any);
  const [measureMediaDetailData, setMeasureMediaDetailData] = useState("");
  const [selectIndex, setSelectIndex] = useState(0);

  const [measureChart1Data, setMeasureChart1Data] = useState([] as any);
  const [measureChart2Data, setMeasureChart2Data] = useState([] as any);
  const [measureChart3Data, setMeasureChart3Data] = useState([] as any);
  const [measureChart4Data, setMeasureChart4Data] = useState([] as any);
  const [seriesName1, setSeriesName1] = useState("");
  const [seriesName2, setSeriesName2] = useState("");
  const [seriesName3, setSeriesName3] = useState("");
  const [seriesName4, setSeriesName4] = useState("");

  const [ecgChartList, setEcgChartList] = useState([] as any);
  const [ecgChart, setEcgChart] = useState([] as any);
  const [ecgMeasureDetailData, setEcgMeasureDetailData] = useState({} as any);
  const [ecgPopHeight, setEcgPopHeight] = useState("");

  const [chartRawData, setChartRawData] = useState([] as any);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  function getWindowDimensions() {
    let winWidth = window.innerWidth;
    let winHeight = window.innerHeight;
    if (layout === "H") {
      winWidth = winWidth / 2;
    }
    return {
      winWidth,
      winHeight,
    };
  }

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "gray",
          right: "50px",
          verticalAlign: "middle",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "gray",
          left: "50px",
          verticalAlign: "middle",
        }}
        onClick={onClick}
      />
    );
  }

  function getChartLabel(resultType: any, chartIndex: any) {
    let resultValue: string = "";

    if (resultType == "GL") {
      resultValue = "Others";
    } else if (resultType == "GH") {
      if (chartIndex == "1") resultValue = formatMessage("txt_others");
      else resultValue = formatMessage("txt_hct");
    } else if (resultType == "CH") {
      if (chartIndex == "1") resultValue = formatMessage("txt_tc");
      else if (chartIndex == "2") resultValue = formatMessage("txt_tg");
      else if (chartIndex == "3") resultValue = formatMessage("txt_hdl");
      else if (chartIndex == "4") resultValue = formatMessage("txt_ldl");
    } else if (resultType == "OX") {
      if (chartIndex == "1") resultValue = formatMessage("txt_spo2");
      else resultValue = formatMessage("txt_pulse");
    } else if (resultType == "IN") {
      if (chartIndex == "1") resultValue = formatMessage("txt_inr");
      else resultValue = formatMessage("txt_pt");
    } else if (resultType == "TE") {
      resultValue = formatMessage("txt_body_temperature");
    } else if (resultType == "UC") {
      resultValue = formatMessage("txt_uric_acid");
    } else if (resultType == "WE") {
      if (chartIndex == "1") {
        resultValue = formatMessage("txt_weight");
      } else if (chartIndex == "2") {
        resultValue = formatMessage("txt_bmi");
      } else if (chartIndex == "3") {
        resultValue = formatMessage("txt_bodyfat");
      }
    } else if (resultType == "HR") {
      resultValue = formatMessage("txt_heart_rate");
    } else if (resultType == "BM") {
      if (chartIndex == "1") {
        resultValue = formatMessage("txt_weight");
      } else resultValue = formatMessage("txt_bmi");
    } else if (resultType == "BC") {
      if (chartIndex == "1") resultValue = formatMessage("txt_weight");
      else if (chartIndex == "2") resultValue = formatMessage("txt_bmi");
      else if (chartIndex == "3") resultValue = formatMessage("txt_bodyfat");
      else if (chartIndex == "4") resultValue = formatMessage("txt_body_water");
    }

    return resultValue;
  }

  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions);
    }
    window.addEventListener("resize", handleResize);

    common.ResizePage();
    const doctorService = new DocotrDataProvider();

    const fetchArticles = async () => {
      const gridDt = await doctorService.GetPatientMeasureTable(
        insungSeq,
        measureType,
        deviceCode,
        startDate,
        endDate
      );
      console.log("tableData", gridDt);
      console.log("tableData", gridDt.data.measureList);
      const element: HTMLElement = document.getElementById(
        "dataList"
      ) as HTMLElement;

      if (measureType == "PH" || measureType == "MS") {
        //thumbnail파일 읽어오기
        gridDt.data.measureList.map(async (d: any, i: any) => {
          const thumbnail = await doctorService.GetMeasurePhotoContentInfo(
            d["measureTotal_measure_value2"]
          );
          gridDt.data.measureList[i].measureTotal_thumbnail = thumbnail;
        });

        setMeasureMediaTableData(gridDt.data.measureList);
      } else {
        setMeasureTableData(gridDt.data.measureList);
      }

      if (gridDt.data.measureList.length === 0) {
        $("#measureNodata").removeClass("display-none");
      } else {
        if (measureType == "EC") {
          if (layout == "V") {
            $("#measureView3").removeClass("display-none");
            $("#measureDetailV0").toggle();
          } else {
            $("#measureView2").removeClass("display-none");
            $("#meaureDetailH").show();
          }

          if (deviceCode == "CARDIPIA400H") {
            const cardipiaData = await doctorService.GetMeasureEcgCardipiaInfo(
              gridDt.data.measureList[0].measureTotal_measure_seq
            );
            console.log("cardipiaData", cardipiaData);
            setMeasureDetailData(cardipiaData);

            //chart
            const cardipiaChartData =
              await doctorService.GetMeasureEcgCardipiaGraphInfo(
                gridDt.data.measureList[0].measureTotal_measure_seq
              );
            console.log("cardipiaChartData", cardipiaChartData);
            showDetail(gridDt.data.measureList[0].measureTotal_measure_seq, 0);
          } else if (deviceCode == "PC80B") {
            const ecgData = await doctorService.GetMeasureEcgInfo(
              gridDt.data.measureList[0].measureTotal_measure_seq
            );

            setMeasureDetailData(ecgData);
            console.log("fetch-measureDetailData", measureDetailData);
            showDetail(gridDt.data.measureList[0].measureTotal_measure_seq, 0);
          }
        } else if (measureType == "MS" || measureType == "PH") {
          if (layout == "V") {
            $("#measureView5").removeClass("display-none");
          } else {
            $("#measureView4").removeClass("display-none");
          }

          const photoContents = await doctorService.GetMeasurePhotoContentInfo(
            gridDt.data.measureList[0].measureTotal_measure_value1
          );
          console.log("photoContents-load", photoContents);

          setMeasureMediaDetailData(photoContents);
        } else if (measureType == "ST") {
          if (layout == "V") {
            $("#measureView7").removeClass("display-none");
          } else {
            $("#measureView6").removeClass("display-none");
          }

          const stData = await doctorService.GetMeasureStethoscopeInfo(
            gridDt.data.measureList[0].measureTotal_measure_seq
          );
          console.log("stData", stData);
          //const audioContent = "data:audio/wav;base64," + stData.data.measureStethoscope_contents;
          //console.log('audioContent', audioContent);
          //const binary = convertDataURIToBinary(audioContent);

          const audioContent = stData.data.measureStethoscope_contents;
          const bufferBase64 = new Buffer(audioContent, "binary").toString(
            "base64"
          );
          console.log("bufferBase64", bufferBase64);
          const binary = convertDataURIToBinary(
            "data:audio/wav;base64," + bufferBase64
          );
          const blob = new Blob([binary], { type: "audio/wav" });
          const blobUrl = URL.createObjectURL(blob);
          console.log("blobUrl", blobUrl);
          setMeasureMediaDetailData(bufferBase64);
        } else {
          $("#measureView1").removeClass("display-none");
          if (layout == "V") {
            $("#measureView1").addClass("mode-v-reverse");
          }

          //chart
          //let chartData = [];
          //const chartData = await doctorService.GetPatientMeasureGraph(2633, resultType, device, "2019-01-01", "2021-09-30");
          const chartData = await doctorService.GetPatientMeasureGraph2(
            insungSeq,
            measureType,
            deviceCode,
            startDate,
            endDate
          );
          drawMeasureChart(chartData);
        }
      }
    };
    fetchArticles();
  }, [props]);

  const convertDataURIToBinary = (dataURI: any) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  };

  const showDetail = async (measureRef: any, index: any) => {
    console.log("showDetail", measureRef + "," + index);
    setSelectIndex(index);
    const doctorService = new DocotrDataProvider();
    if (measureType === "EC") {
      setEcgChartList([]);
      if (deviceCode === "PC80B") {
        const ecgData = await doctorService.GetMeasureEcgInfo(measureRef);
        const ecgChartData = await doctorService.GetMeasureEcgGraphInfo(
          measureRef
        );
        console.log("ecgChartData", ecgChartData);
        const xScale = d3
          .scaleLinear()
          .domain([0, 4500 - 1]) //x축 숫자 범위
          .range([0, 1590]); //모눈 이미지 범위
        const yScale = d3.scaleLinear().domain([1900, 2600]).range([0, 300]);
        const scaleList = [];

        const scale: { x: number; y: number }[] = [];
        ecgChartData["graphData1"].forEach(function (d: any) {
          scale.push({ x: xScale(d.x), y: yScale(d.y) });
        });
        scaleList.push(scale);

        //setEcgMeasureDetailData(ecgData[0]);
        setEcgChart([]);
        setEcgChartList(scaleList);
      } else if (deviceCode === "CARDIPIA400H") {
        const ecgCardipiaData = await doctorService.GetMeasureEcgCardipiaInfo(
          measureRef
        );
        console.log("ecgCardipiaData", ecgCardipiaData);

        const ecgCardipiaChartData =
          await doctorService.GetMeasureEcgCardipiaGraphInfo(measureRef);
        console.log("ecgCardipiaChartData", ecgCardipiaChartData);
        const xScale = d3
          .scaleLinear()
          .domain([0, 5000]) //x축 숫자 범위
          .range([0, 1600]); //모눈 이미지 범위
        const yScale = d3.scaleLinear().domain([0, 40]).range([0, 80]);
        const scaleList = [];

        for (var i = 1; i < 13; i++) {
          const scale: { x: number; y: number }[] = [];
          ecgCardipiaChartData["graphData" + i].forEach(function (d: any) {
            scale.push({ x: xScale(d.x), y: yScale(d.y) });
          });
          scaleList.push(scale);
        }
        //setEcgMeasureDetailData(ecgCardipiaData[0]);
        setEcgChart([]);
        setEcgChartList(scaleList);
      }

      if (layout == "V") {
        //펼쳐진 div 모두 접기
        $("div[title='measureDetail']").hide();
        $("#measureDetailV" + index).toggle();
      }
    } else if (measureType === "MS" || measureType === "PH") {
      //if (measureType === "PH") {
      const contents = await doctorService.GetMeasurePhotoContentInfo(
        measureRef
      );
      setMeasureMediaDetailData(contents);
      //} else {
      //  const msData = await doctorService.GetMeasureMicroscopeInfo(measureRef);
      //  console.log("msData", msData);
      //  const bufferBase64 = new Buffer(
      //    msData.data.measureMicroscope_contents,
      //    "binary"
      //  ).toString("base64");
      //  setMeasureMediaDetailData(bufferBase64);
      //}

      if (layout == "V") {
        //펼쳐진 div 모두 접기
        $("div[title='mediaDetail']").hide();
        $("#mediaDetailV" + index).toggle();
      }
    } else if (measureType === "ST") {
      console.log("measureSeq", measureRef);
      const stData = await doctorService.GetMeasureStethoscopeInfo(measureRef);
      console.log("stData", stData);
      //const audioContent = "data:audio/wav;base64," + stData.data.measureStethoscope_contents;
      //console.log('audioContent', audioContent);
      //const binary = convertDataURIToBinary(audioContent);

      const audioContent = stData.data.measureStethoscope_contents;
      const bufferBase64 = new Buffer(audioContent, "binary").toString(
        "base64"
      );
      console.log("bufferBase64", bufferBase64);
      const binary = convertDataURIToBinary(
        "data:audio/wav;base64," + bufferBase64
      );
      const blob = new Blob([binary], { type: "audio/wav" });
      const blobUrl = URL.createObjectURL(blob);
      console.log("blobUrl", blobUrl);
      setMeasureMediaDetailData(bufferBase64);

      if (layout == "V") {
        //펼쳐진 div 모두 접기
        $("div[title='mediaDetail']").hide();
        $("#mediaDetailV" + index).toggle();
      }
    }
  };

  const drawMeasureChart = async (chartData: any) => {
    console.log("chartData", chartData);
    setChartRawData(chartData.data);
    if (chartData.data.measureList.length > 0) {
      let labels = [];
      let measureData1 = [];
      let measureData2 = [];
      let measureData3 = [];
      let measureData4 = [];
      let chart1Data = {},
        chart2Data = {},
        chart3Data = {},
        chart4Data = {};
      let chart1Options = {},
        chart2Options = {},
        chart3Options = {},
        chart4Options = {};

      for (let i = 0; i < chartData.data.measureList.length; i++) {
        labels.push(
          chartData.data.measureList[i].measureTotal_measure_utc_dt.substring(
            0,
            10
          )
        );

        switch (measureType) {
          case "BP":
            $("#chartBP").removeClass("display-none");
            $("#chartBP").show();

            measureData1.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              systolic: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value1
              ),
              diastolic: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value2
              ),
            });
            measureData2.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              pulse: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value3
              ),
            });

            break;
          case "BM":
          case "GL":
          case "TE":
          case "UC":
            $("#chartOther").removeClass("display-none");
            $("#chartOther").show();

            measureData1.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue1: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value1
              ),
            });

            setSeriesName1(getChartLabel(measureType, "1"));
            break;
          case "BM":
          case "GH":
          case "IN":
          case "OX":
            $("#chartOther").removeClass("display-none");
            $("#chartOther").show();

            measureData1.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue1: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value1
              ),
            });
            measureData2.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .add(timezoneOffset, "minutes")
                .format("YYYY-MM-DD hh:mm:ss"),
              measureValue2: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value2
              ),
            });

            setSeriesName1(getChartLabel(measureType, "1"));
            setSeriesName2(getChartLabel(measureType, "2"));
            break;
          case "WE":
            $("#chartOther").removeClass("display-none");
            $("#chartOther").show();

            measureData1.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue1: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value1
              ),
            });
            measureData2.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue2: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value3
              ),
            });
            measureData1.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue3: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value4
              ),
            });

            setSeriesName1(getChartLabel(measureType, "1"));
            setSeriesName3(getChartLabel(measureType, "2"));
            setSeriesName2(getChartLabel(measureType, "3"));
            break;
          case "CH":
            $("#chartOther").removeClass("display-none");
            $("#chartOther").show();

            if (
              !isNaN(
                parseFloat(
                  chartData.data.measureList[i].measureTotal_measure_value1
                )
              )
            ) {
              measureData1.push({
                date: moment(
                  chartData.data.measureList[i].measureTotal_measure_utc_dt
                )
                  .subtract(timezoneOffset, "minutes")
                  .format(
                    (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                  ),
                measureValue1: parseFloat(
                  chartData.data.measureList[i].measureTotal_measure_value1
                ),
              });
              setSeriesName1(getChartLabel(measureType, "1"));
            }
            if (
              !isNaN(
                parseFloat(
                  chartData.data.measureList[i].measureTotal_measure_value2
                )
              )
            ) {
              measureData2.push({
                date: moment(
                  chartData.data.measureList[i].measureTotal_measure_utc_dt
                )
                  .subtract(timezoneOffset, "minutes")
                  .format(
                    (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                  ),
                measureValue2: parseFloat(
                  chartData.data.measureList[i].measureTotal_measure_value2
                ),
              });
              setSeriesName2(getChartLabel(measureType, "2"));
            }
            if (
              !isNaN(
                parseFloat(
                  chartData.data.measureList[i].measureTotal_measure_value3
                )
              )
            ) {
              measureData3.push({
                date: moment(
                  chartData.data.measureList[i].measureTotal_measure_utc_dt
                )
                  .subtract(timezoneOffset, "minutes")
                  .format(
                    (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                  ),
                measureValue3: parseFloat(
                  chartData.data.measureList[i].measureTotal_measure_value3
                ),
              });
              setSeriesName3(getChartLabel(measureType, "3"));
            }
            if (
              !isNaN(
                parseFloat(
                  chartData.data.measureList[i].measureTotal_measure_value4
                )
              )
            ) {
              measureData4.push({
                date: moment(
                  chartData.data.measureList[i].measureTotal_measure_utc_dt
                )
                  .subtract(timezoneOffset, "minutes")
                  .format(
                    (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                  ),
                measureValue4: parseFloat(
                  chartData.data.measureList[i].measureTotal_measure_value4
                ),
              });
              setSeriesName4(getChartLabel(measureType, "4"));
            }
            if (measureData1.length > 0) {
              setSeriesName1(getChartLabel(measureType, "1"));
            }
            if (measureData2.length > 0) {
              setSeriesName2(getChartLabel(measureType, "2"));
            }
            if (measureData3.length > 0) {
              setSeriesName3(getChartLabel(measureType, "3"));
            }
            if (measureData4.length > 0) {
              setSeriesName4(getChartLabel(measureType, "4"));
            }
            break;
          case "BC":
            $("#chartOther").removeClass("display-none");
            $("#chartOther").show();
            console.log("chartData", chartData);
            measureData1.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue1: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value1
              ),
            });
            setSeriesName1(getChartLabel(measureType, "1"));

            measureData2.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue2: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value3
              ),
            });
            setSeriesName2(getChartLabel(measureType, "2"));

            measureData3.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue3: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value4
              ),
            });
            setSeriesName3(getChartLabel(measureType, "3"));

            measureData4.push({
              date: moment(
                chartData.data.measureList[i].measureTotal_measure_utc_dt
              )
                .subtract(timezoneOffset, "minutes")
                .format(
                  (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " HH:mm:ss"
                ),
              measureValue4: parseFloat(
                chartData.data.measureList[i].measureTotal_measure_value5
              ),
            });
            setSeriesName4(getChartLabel(measureType, "4"));

            if (measureData1.length > 0) {
              setSeriesName1(getChartLabel(measureType, "1"));
            }
            if (measureData2.length > 0) {
              setSeriesName2(getChartLabel(measureType, "2"));
            }
            if (measureData3.length > 0) {
              setSeriesName3(getChartLabel(measureType, "3"));
            }
            if (measureData4.length > 0) {
              setSeriesName4(getChartLabel(measureType, "4"));
            }
            break;
          default:
            break;
        }
      }

      setMeasureChart1Data(measureData1);
      setMeasureChart2Data(measureData2);
      setMeasureChart3Data(measureData3);
      setMeasureChart4Data(measureData4);

      //let ecgChartObj = $("#ecgChart").clone();
      //console.log('ecgChartHtml', ecgChartObj.html());
      //let detailHtml = "<div id='cardipiaDetail1'>" + ecgChartObj.html() + "</div>";
      //console.log('ecgChartDetail', detailHtml);
      //$("#ecgDetail").html(detailHtml);
      //console.log('ecgDetail', $("#ecgDetail").html());
      //console.log('avl-value', $("#cardipiaDetail1").find("#avl").text());
    } else {
      //nodata
    }
  };
  return (
    //measureView1 : 일반적인 차트유형(혈압,혈당,체온등..데이터여러개를 차트하나에 표시하는 측정항목)
    //세로모드는 최상위 div class에 mode-v class추가
    //measureView2 : ecg, microscope, image등 하나의 측정값마다 상세정보를 보여줘야하는 측정항목-가로모드
    //measureView3 : meaureView2의 세로모드
    //measureView4 : image, microscope 항목의 가로모드
    //measureView5 : measureView4의 세로모드
    //measureView6 : stethoscope항목의 가로모드
    //measureView7 : meaureView6의 세로모드
    <article>
      <div className="web-view display-none" id="measureNodata">
        <div className="nodata">{formatMessage("txt_no_matches")}</div>
      </div>
      <div className="web-view display-none" id="measureView1">
        <div className="data">
          <p className="unit">
            {formatMessage("txt_date_measured")} /{" "}
            {formatMessage("txt_date_transferred")}
          </p>
          <div className="data__list">
            <div className="data__tit">
              {measureItemText.map((d: any, i: any) => {
                if (measureType === d["measureType"]) {
                  if (measureType === "EC") {
                    if (deviceCode === "PC80B") {
                      return (
                        <ul>
                          {d["measureItem"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    } else {
                      return (
                        <ul>
                          {d["measureItem2"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }
                  } else if (measureType === "IN") {
                    if (deviceCode === "COAGUCHEKXS") {
                      return (
                        <ul>
                          {d["measureItem"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    } else {
                      return (
                        <ul>
                          {d["measureItem2"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }
                  } else {
                    return (
                      <ul>
                        {d["measureItem"].map((e: any, j: any) => {
                          if (e["text"] != "")
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                        })}
                      </ul>
                    );
                  }
                }
              })}
            </div>
            {measureTableData.map((d: any, i: any) => {
              return (
                <div className="data__item">
                  <p className="date">
                    {moment(d["measureTotal_measure_utc_dt"])
                      .subtract(timezoneOffset, "minute")
                      .format(
                        (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " hh:mm:ss"
                      ) +
                      " / " +
                      moment(d["measureTotal_input_utc_dt"])
                        .subtract(timezoneOffset, "minute")
                        .format(
                          (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                            " hh:mm:ss"
                        )}
                  </p>
                  {measureItemText.map((e: any, j: any) => {
                    if (measureType === e["measureType"]) {
                      if (measureType === "IN") {
                        if (deviceCode === "COAGUCHEKXS") {
                          return (
                            <ul>
                              {e["measureItem"].map((f: any, k: any) => {
                                const idx = k + 1;
                                return (
                                  <li>
                                    <strong>
                                      {d["measureTotal_measure_value" + idx]}
                                    </strong>
                                    <span>
                                      {d["measureTotal_measure_unit" + idx]}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          );
                        } else {
                          return (
                            <ul>
                              {e["measureItem2"].map((f: any, k: any) => {
                                const idx = k + 1;
                                return (
                                  <li>
                                    <strong>
                                      {d["measureTotal_measure_value" + idx]}
                                    </strong>
                                    <span>
                                      {d["measureTotal_measure_unit" + idx]}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          );
                        }
                      } else if (measureType === "GL") {
                        return (
                          <ul>
                            <li>
                              <strong>
                                {d["measureTotal_measure_value1"]}
                              </strong>
                              <span>{d["measureTotal_measure_unit1"]}</span>
                            </li>
                            <li>
                              <strong>
                                {d["measureTotal_measure_value3"]}
                              </strong>
                            </li>
                          </ul>
                        );
                      } else if (measureType === "BC") {
                        return (
                          <ul>
                            {e["measureItem"].map((f: any, k: any) => {
                              if (k != 1) {
                                console.log(
                                  d["measureTotal_measure_value" + k]
                                );
                                return (
                                  <li>
                                    <strong>
                                      {
                                        d[
                                          "measureTotal_measure_value" + (k + 1)
                                        ]
                                      }
                                    </strong>
                                    <span>
                                      {d["measureTotal_measure_unit" + (k + 1)]}
                                    </span>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        );
                      } else {
                        return (
                          <ul>
                            {e["measureItem"].map((f: any, k: any) => {
                              return (
                                <li>
                                  <strong>
                                    {d["measureTotal_measure_value" + (k + 1)]}
                                  </strong>
                                  <span>
                                    {d["measureTotal_measure_unit" + (k + 1)]}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        );
                      }
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="chart">
          <article id="measureChart" className="chart__wrap">
            <div
              id="chartBP"
              className="display-none"
              style={{ overflow: "hidden" }}
            >
              <Slider {...settings}>
                <Chart id="chart" title="" dataSource={measureChart1Data}>
                  <Size width={windowDimensions.winWidth - 80} />
                  <CommonSeriesSettings argumentField="date" type="line" />
                  <Series
                    name={formatMessage("txt_systolic")}
                    valueField="systolic"
                    color="#418CF0"
                  />
                  <Series
                    name={formatMessage("txt_diastolic")}
                    valueField="diastolic"
                    color="#FCB441"
                  />
                  <ValueAxis>
                    <VisualRange startValue={0} />
                    <Label customizeText={"e"} />
                  </ValueAxis>
                  <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="right"
                  />
                </Chart>

                <Chart id="chart" title="" dataSource={measureChart2Data}>
                  <Size width={windowDimensions.winWidth - 80} />
                  <Series
                    name={formatMessage("txt_pulse")}
                    argumentField="date"
                    valueField="pulse"
                    type="line"
                    color="#418CF0"
                  />
                  <ValueAxis>
                    <VisualRange startValue={0} />
                    <Label customizeText={""} />
                  </ValueAxis>
                  <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="right"
                  />
                </Chart>
              </Slider>
            </div>
            <div
              id="chartOther"
              className="display-none"
              style={{ overflow: "hidden" }}
            >
              <Slider {...settings}>
                {measureChart1Data.length === 0 ? (
                  ""
                ) : (
                  <Chart
                    id="chart-mobile"
                    title=""
                    dataSource={measureChart1Data}
                  >
                    <Size width={windowDimensions.winWidth - 80} />
                    <Series
                      name={seriesName1}
                      argumentField="date"
                      valueField="measureValue1"
                      type="line"
                      color="#418CF0"
                    />
                    <ValueAxis>
                      <VisualRange startValue={0} />
                      <Label customizeText={""} />
                    </ValueAxis>
                    <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      itemTextPosition="right"
                    />
                  </Chart>
                )}
                {measureChart2Data.length === 0 ? (
                  ""
                ) : (
                  <Chart
                    id="chart-mobile"
                    title=""
                    dataSource={measureChart2Data}
                  >
                    <Size width={windowDimensions.winWidth - 80} />
                    <Series
                      name={seriesName2}
                      argumentField="date"
                      valueField="measureValue2"
                      type="spline"
                      color="#418CF0"
                    />
                    <ValueAxis>
                      <VisualRange startValue={0} />
                      <Label customizeText={""} />
                    </ValueAxis>
                    <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      itemTextPosition="right"
                    />
                  </Chart>
                )}

                {measureChart3Data.length === 0 ? (
                  ""
                ) : (
                  <Chart
                    id="chart-mobile"
                    title=""
                    dataSource={measureChart3Data}
                  >
                    <Size width={windowDimensions.winWidth - 80} />
                    <Series
                      name={seriesName3}
                      argumentField="date"
                      valueField="measureValue3"
                      type="spline"
                      color="#418CF0"
                    />
                    <ValueAxis>
                      <VisualRange startValue={0} />
                      <Label customizeText={""} />
                    </ValueAxis>
                    <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      itemTextPosition="right"
                    />
                  </Chart>
                )}

                {measureChart4Data.length === 0 ? (
                  ""
                ) : (
                  <Chart
                    id="chart-mobile"
                    title=""
                    dataSource={measureChart4Data}
                  >
                    <Size width={windowDimensions.winWidth - 80} />
                    <Series
                      name={seriesName4}
                      argumentField="date"
                      valueField="measureValue4"
                      type="spline"
                      color="#418CF0"
                    />
                    <ValueAxis>
                      <VisualRange startValue={0} />
                      <Label customizeText={""} />
                    </ValueAxis>
                    <ArgumentAxis valueMarginsEnabled={false}></ArgumentAxis>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      itemTextPosition="right"
                    />
                  </Chart>
                )}
              </Slider>
            </div>
          </article>
        </div>
      </div>

      <div className="web-view display-none" id="measureView2">
        <div className="data">
          <p className="unit">
            {formatMessage("txt_date_measured")} /{" "}
            {formatMessage("txt_date_transferred")}
          </p>
          <div className="data__list">
            <div className="data__tit">
              {measureItemText.map((d: any, i: any) => {
                if (measureType === d["measureType"]) {
                  if (measureType === "EC") {
                    if (deviceCode === "PC80B") {
                      return (
                        <ul>
                          {d["measureItem"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    } else {
                      return (
                        <ul>
                          {d["measureItem2"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }
                  } else if (measureType === "IN") {
                    if (deviceCode === "COAGUCHEKXS") {
                      return (
                        <ul>
                          {d["measureItem"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    } else {
                      return (
                        <ul>
                          {d["measureItem2"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }
                  } else {
                    return (
                      <ul>
                        {d["measureItem"].map((e: any, j: any) => {
                          return (
                            <li>
                              {" "}
                              <strong>{e["text"]}</strong>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }
                }
              })}
            </div>
            {measureTableData.map((d: any, i: any) => {
              if (deviceCode === "PC80B") {
                return (
                  <div className="data__item">
                    <p className="date">
                      {moment(d["measureTotal_measure_utc_dt"])
                        .subtract(timezoneOffset, "minute")
                        .format(
                          (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                            " hh:mm:ss"
                        ) +
                        " / " +
                        moment(d["measureTotal_input_utc_dt"])
                          .subtract(timezoneOffset, "minute")
                          .format(
                            (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                              " hh:mm:ss"
                          )}
                    </p>
                    <ul
                      onClick={() =>
                        showDetail(d["measureTotal_measure_seq"], i)
                      }
                    >
                      <li>
                        <strong>{d["measureTotal_measure_value1"]}</strong>
                        <span>{d["measureTotal_measure_unit1"]}</span>
                      </li>
                    </ul>
                  </div>
                );
              } else {
                return (
                  <div className="data__item">
                    <p className="date">
                      {moment(d["measureTotal_measure_utc_dt"])
                        .subtract(timezoneOffset, "minute")
                        .format(
                          (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                            " hh:mm:ss"
                        ) +
                        " / " +
                        moment(d["measureTotal_input_utc_dt"])
                          .subtract(timezoneOffset, "minute")
                          .format(
                            (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                              " hh:mm:ss"
                          )}
                    </p>
                    <ul
                      onClick={() =>
                        showDetail(d["measureTotal_measure_seq"], i)
                      }
                    >
                      <li>
                        <strong>{d["measureTotal_measure_value1"]}</strong>
                        <span>{d["measureTotal_measure_unit1"]}</span>
                      </li>
                      <li>
                        <strong>{d["measureTotal_measure_value2"]}</strong>
                        <span>{d["measureTotal_measure_unit2"]}</span>
                      </li>
                      <li>
                        <strong>{d["measureTotal_measure_value3"]}</strong>
                        <span>{d["measureTotal_measure_unit3"]}</span>
                      </li>
                    </ul>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="chart">
          {measureDetailData.map((d: any, j: any) => {
            console.log("charth-measureDetailData", measureDetailData);
            console.log("ecgChartListh", ecgChartList);
            if (measureType === "EC") {
              if (deviceCode === "PC80B") {
                return (
                  <article className="chart__wrap" id="measureEcgDetail">
                    <div className="svg-wrap">
                      {ecgChartList.map((d: any, i: any) => {
                        return (
                          <div
                            className="ecg-chart"
                            style={{ width: "1600px" }}
                          >
                            <Chart
                              id={"chart" + i}
                              dataSource={d}
                              style={{ position: "relative", top: "0px" }}
                            >
                              <Size height={300} width={1600} />
                              <CommonSeriesSettings
                                argumentField="x"
                                valueField="y"
                                type="spline"
                              >
                                <Point visible={false} />
                              </CommonSeriesSettings>
                              <Series color="#000000" />
                              <ArgumentAxis
                                allowDecimals={true}
                                axisDivisionFactor={1}
                                visible={false}
                              >
                                <Grid visible={false} />
                                <VisualRange startValue={0} endValue={1590} />
                                <Label visible={false} />
                              </ArgumentAxis>
                              <ValueAxis
                                allowDecimals={true}
                                inverted={false}
                                visible={false}
                                axisDivisionFactor={1}
                              >
                                <Grid visible={false} />
                                <Label visible={false} />
                                <VisualRange startValue={0} endValue={300} />
                              </ValueAxis>
                              <Legend visible={false} />
                            </Chart>
                          </div>
                        );
                      })}
                    </div>
                  </article>
                );
              } else if (deviceCode === "CARDIPIA400H") {
                return (
                  <article className="chart__wrap" id="measureEcgDetail">
                    <h4>
                      <strong>Measured Cardiac Rythm Events</strong>
                    </h4>
                    <div className="data-list type-dot type-col3">
                      <ul>
                        <li>
                          <span>HR : </span>
                          <strong>{d["measureCardipia_hr"]}</strong>
                        </li>
                        <li>
                          <span>PR : </span>
                          <strong>{d["measureCardipia_pr"]}</strong>
                        </li>
                        <li>
                          <span>QRS : </span>
                          <strong>{d["measureCardipia_qrs"]}</strong>
                        </li>
                        <li>
                          <span>QT : </span>
                          <strong>{d["measureCardipia_qt"]}</strong>
                        </li>
                        <li>
                          <span>QTc : </span>
                          <strong>{d["measureCardipia_qtc"]}</strong>
                        </li>
                        <li>
                          <span>QTr : </span>
                          <strong>{d["measureCardipia_qtr"]}</strong>
                        </li>
                        <li>
                          <span>RV5 : </span>
                          <strong>{d["measureCardipia_rv5"]}</strong>
                        </li>
                        <li>
                          <span>SV1 : </span>
                          <strong>{d["measureCardipia_sv1"]}</strong>
                        </li>
                        <li>
                          <span>P/QRS/T/QRST axis : </span>
                          <strong>{d["measureCardipia_p_qrs_t_qrst"]}</strong>
                        </li>
                      </ul>
                    </div>
                    <p className="info-txt">
                      Unconfirmed Report To Be Reviewed ** [Adult] 3- Rate
                      greater than 100 bpm. Tachycardia.
                    </p>
                    <div className="svg-wrap">
                      {ecgChartList.map((d: any, i: any) => {
                        return (
                          <div
                            className="ecg-chart"
                            style={{ width: "1000px" }}
                          >
                            <Chart id={"chart" + i} dataSource={d}>
                              <Size height={100} width={1000} />
                              <CommonSeriesSettings
                                argumentField="x"
                                valueField="y"
                                type="spline"
                              >
                                <Point visible={false} />
                              </CommonSeriesSettings>
                              <Series color="#000000" />
                              <ArgumentAxis
                                allowDecimals={true}
                                axisDivisionFactor={1}
                                visible={false}
                              >
                                <Grid visible={false} />
                                <VisualRange startValue={0} endValue={1600} />
                                <Label visible={false} />
                              </ArgumentAxis>
                              <ValueAxis
                                allowDecimals={true}
                                inverted={false}
                                visible={false}
                                axisDivisionFactor={1}
                              >
                                <Grid visible={false} />
                                <Label visible={false} />
                                <VisualRange startValue={-50} endValue={100} />
                              </ValueAxis>
                              <Legend visible={false} />
                            </Chart>
                          </div>
                        );
                      })}
                    </div>
                    <div className="tbl-wrap">
                      <table className="type-pop type-ct" id="cardipiaTable">
                        <caption>Measurement</caption>
                        <colgroup>
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th scope="col" className="fst">
                              <strong></strong>
                            </th>
                            <th scope="col">
                              <strong>I</strong>
                            </th>
                            <th scope="col">
                              <strong>II</strong>
                            </th>
                            <th scope="col">
                              <strong>III</strong>
                            </th>
                            <th scope="col">
                              <strong>aVR</strong>
                            </th>
                            <th scope="col">
                              <strong>aVL</strong>
                            </th>
                            <th scope="col">
                              <strong>aVF</strong>
                            </th>
                            <th scope="col">
                              <strong>V1</strong>
                            </th>
                            <th scope="col">
                              <strong>V2</strong>
                            </th>
                            <th scope="col">
                              <strong>V3</strong>
                            </th>
                            <th scope="col">
                              <strong>V4</strong>
                            </th>
                            <th scope="col">
                              <strong>V5</strong>
                            </th>
                            <th scope="col">
                              <strong>V6</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.keys(measureDetailData[0]).map(
                            (e: any, z: any) => {
                              const d = measureDetailData[0];
                              if (
                                !_.isNumber(d[e]) &&
                                d[e] !== null &&
                                d[e].indexOf(",") !== -1
                              ) {
                                const string = e
                                  .replace("measureCardipia_", "")
                                  .replace("_", "`");
                                return (
                                  <tr>
                                    <td scope="row" className="fst">
                                      {string.charAt(0).toUpperCase() +
                                        string.slice(1)}
                                      (mV)
                                    </td>
                                    {d[e].split(",").map((x: any, y: any) => {
                                      return (
                                        <td scope="row" className="fst">
                                          {x}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </article>
                );
              }
            }
          })}
        </div>
      </div>

      <div className="web-view mode-v display-none" id="measureView3">
        <div className="data">
          <p className="unit">
            {formatMessage("txt_date_measured")} /{" "}
            {formatMessage("txt_date_transferred")}
          </p>
          <div className="data__list">
            <div className="data__tit">
              {measureItemText.map((d: any, i: any) => {
                if (measureType === d["measureType"]) {
                  if (measureType === "EC") {
                    if (deviceCode === "PC80B") {
                      return (
                        <ul>
                          {d["measureItem"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    } else {
                      return (
                        <ul>
                          {d["measureItem2"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }
                  } else if (measureType === "IN") {
                    if (deviceCode === "COAGUCHEKXS") {
                      return (
                        <ul>
                          {d["measureItem"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    } else {
                      return (
                        <ul>
                          {d["measureItem2"].map((e: any, j: any) => {
                            return (
                              <li>
                                {" "}
                                <strong>{e["text"]}</strong>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }
                  } else {
                    return (
                      <ul>
                        {d["measureItem"].map((e: any, j: any) => {
                          return (
                            <li>
                              {" "}
                              <strong>{e["text"]}</strong>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }
                }
              })}
            </div>
            {measureTableData.map((d: any, i: any) => {
              let displayNone = "display-none";
              if (i == 0) displayNone = "";
              if (deviceCode === "PC80B") {
                return (
                  <div>
                    <div className="data__item">
                      <p className="date">
                        {moment(d["measureTotal_measure_utc_dt"])
                          .subtract(timezoneOffset, "minute")
                          .format(
                            (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                              " hh:mm:ss"
                          ) +
                          " / " +
                          moment(d["measureTotal_input_utc_dt"])
                            .subtract(timezoneOffset, "minute")
                            .format(
                              (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                                " hh:mm:ss"
                            )}
                      </p>
                      <ul
                        onClick={() =>
                          showDetail(d["measureTotal_measure_seq"], i)
                        }
                      >
                        <li>
                          <strong>{d["measureTotal_measure_value1"]}</strong>
                          <span>{d["measureTotal_measure_unit1"]}</span>
                        </li>
                      </ul>
                    </div>
                    <div
                      title="measureDetail"
                      className={"chart__wrap " + displayNone}
                      id={"measureDetailV" + i}
                    >
                      <div className="svg-wrap">
                        {i !== selectIndex
                          ? ""
                          : ecgChartList.map((d: any, i: any) => {
                              return (
                                <div
                                  className="ecg-chart"
                                  style={{ width: "1600px" }}
                                >
                                  <Chart
                                    id={"chart" + i}
                                    dataSource={d}
                                    style={{
                                      position: "relative",
                                      top: "0px",
                                    }}
                                  >
                                    <Size height={300} width={1600} />
                                    <CommonSeriesSettings
                                      argumentField="x"
                                      valueField="y"
                                      type="spline"
                                    >
                                      <Point visible={false} />
                                    </CommonSeriesSettings>
                                    <Series color="#000000" />
                                    <ArgumentAxis
                                      allowDecimals={true}
                                      axisDivisionFactor={1}
                                      visible={false}
                                    >
                                      <Grid visible={false} />
                                      <VisualRange
                                        startValue={0}
                                        endValue={1590}
                                      />
                                      <Label visible={false} />
                                    </ArgumentAxis>
                                    <ValueAxis
                                      allowDecimals={true}
                                      inverted={false}
                                      visible={false}
                                      axisDivisionFactor={1}
                                    >
                                      <Grid visible={false} />
                                      <Label visible={false} />
                                      <VisualRange
                                        startValue={0}
                                        endValue={300}
                                      />
                                    </ValueAxis>
                                    <Legend visible={false} />
                                  </Chart>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div>
                    <div className="data__item">
                      <p className="date">
                        {moment(d["measureTotal_measure_utc_dt"])
                          .subtract(timezoneOffset, "minute")
                          .format(
                            (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                              " hh:mm:ss"
                          ) +
                          " / " +
                          moment(d["measureTotal_input_utc_dt"])
                            .subtract(timezoneOffset, "minute")
                            .format(
                              (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                                " hh:mm:ss"
                            )}
                      </p>
                      <ul
                        onClick={() =>
                          showDetail(d["measureTotal_measure_seq"], i)
                        }
                      >
                        <li>
                          <strong>{d["measureTotal_measure_value1"]}</strong>
                          <span>{d["measureTotal_measure_unit1"]}</span>
                        </li>
                        <li>
                          <strong>{d["measureTotal_measure_value2"]}</strong>
                          <span>{d["measureTotal_measure_unit2"]}</span>
                        </li>
                        <li>
                          <strong>{d["measureTotal_measure_value3"]}</strong>
                          <span>{d["measureTotal_measure_unit3"]}</span>
                        </li>
                      </ul>
                    </div>
                    {i !== selectIndex
                      ? ""
                      : measureDetailData.map((d: any, j: any) => {
                          return (
                            <div
                              title="measureDetail"
                              className="chart display-none"
                              id={"measureDetailV" + i}
                            >
                              <article className="chart__wrap">
                                <h4>
                                  <strong>Measured Cardiac Rythm Events</strong>
                                </h4>
                                <div className="data-list type-dot type-col3">
                                  <ul>
                                    <li>
                                      <span>HR : </span>
                                      <strong>{d["measureCardipia_hr"]}</strong>
                                    </li>
                                    <li>
                                      <span>PR : </span>
                                      <strong>{d["measureCardipia_pr"]}</strong>
                                    </li>
                                    <li>
                                      <span>QRS : </span>
                                      <strong>
                                        {d["measureCardipia_qrs"]}
                                      </strong>
                                    </li>
                                    <li>
                                      <span>QT : </span>
                                      <strong>{d["measureCardipia_qt"]}</strong>
                                    </li>
                                    <li>
                                      <span>QTc : </span>
                                      <strong>
                                        {d["measureCardipia_qtc"]}
                                      </strong>
                                    </li>
                                    <li>
                                      <span>QTr : </span>
                                      <strong>
                                        {d["measureCardipia_qtr"]}
                                      </strong>
                                    </li>
                                    <li>
                                      <span>RV5 : </span>
                                      <strong>
                                        {d["measureCardipia_rv5"]}
                                      </strong>
                                    </li>
                                    <li>
                                      <span>SV1 : </span>
                                      <strong>
                                        {d["measureCardipia_sv1"]}
                                      </strong>
                                    </li>
                                    <li>
                                      <span>P/QRS/T/QRST axis : </span>
                                      <strong>
                                        {d["measureCardipia_p_qrs_t_qrst"]}
                                      </strong>
                                    </li>
                                  </ul>
                                </div>
                                <p className="info-txt">
                                  Unconfirmed Report To Be Reviewed ** [Adult]
                                  3- Rate greater than 100 bpm. Tachycardia.
                                </p>
                                <div className="svg-wrap">
                                  {i !== selectIndex
                                    ? ""
                                    : ecgChartList.map((d: any, i: any) => {
                                        return (
                                          <div
                                            className="ecg-chart"
                                            style={{ width: "1000px" }}
                                          >
                                            <Chart
                                              id={"chart" + i}
                                              dataSource={d}
                                            >
                                              <Size height={100} width={1000} />
                                              <CommonSeriesSettings
                                                argumentField="x"
                                                valueField="y"
                                                type="spline"
                                              >
                                                <Point visible={false} />
                                              </CommonSeriesSettings>
                                              <Series color="#000000" />
                                              <ArgumentAxis
                                                allowDecimals={true}
                                                axisDivisionFactor={1}
                                                visible={false}
                                              >
                                                <Grid visible={false} />
                                                <VisualRange
                                                  startValue={0}
                                                  endValue={1600}
                                                />
                                                <Label visible={false} />
                                              </ArgumentAxis>
                                              <ValueAxis
                                                allowDecimals={true}
                                                inverted={false}
                                                visible={false}
                                                axisDivisionFactor={1}
                                              >
                                                <Grid visible={false} />
                                                <Label visible={false} />
                                                <VisualRange
                                                  startValue={-50}
                                                  endValue={100}
                                                />
                                              </ValueAxis>
                                              <Legend visible={false} />
                                            </Chart>
                                          </div>
                                        );
                                      })}
                                </div>
                                <div className="tbl-wrap">
                                  <table className="type-pop type-ct">
                                    <caption>Measurement</caption>
                                    <colgroup>
                                      <col />
                                      <col />
                                      <col />
                                      <col />
                                      <col />
                                    </colgroup>
                                    <thead>
                                      <tr>
                                        <th scope="col" className="fst">
                                          <strong></strong>
                                        </th>
                                        <th scope="col">
                                          <strong>I</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>II</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>III</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>aVR</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>aVL</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>aVF</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>V1</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>V2</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>V3</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>V4</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>V5</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>V6</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {i !== selectIndex
                                        ? ""
                                        : _.keys(measureDetailData[0]).map(
                                            (e: any, z: any) => {
                                              const d = measureDetailData[0];
                                              if (
                                                !_.isNumber(d[e]) &&
                                                d[e] !== null &&
                                                d[e].indexOf(",") !== -1
                                              ) {
                                                const string = e
                                                  .replace(
                                                    "measureCardipia_",
                                                    ""
                                                  )
                                                  .replace("_", "`");
                                                return (
                                                  <tr>
                                                    <td
                                                      scope="row"
                                                      className="fst"
                                                    >
                                                      {string
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        string.slice(1)}
                                                      (mV)
                                                    </td>
                                                    {d[e]
                                                      .split(",")
                                                      .map((x: any, y: any) => {
                                                        return (
                                                          <td
                                                            scope="row"
                                                            className="fst"
                                                          >
                                                            {x}
                                                          </td>
                                                        );
                                                      })}
                                                  </tr>
                                                );
                                              }
                                            }
                                          )}
                                    </tbody>
                                  </table>
                                </div>
                              </article>
                            </div>
                          );
                        })}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>

      <div className="web-view display-none" id="measureView4">
        <div className="img">
          <p className="unit">
            {formatMessage("txt_date_measured")} /{" "}
            {formatMessage("txt_date_transferred")}
          </p>
          <div className="img__list">
            {measureMediaTableData.map((d: any, i: any) => {
              const measureRef = d["measureTotal_measure_value1"];
              return (
                <div className="img__item thumb">
                  <p className="date">
                    {moment(d["measureTotal_measure_utc_dt"])
                      .subtract(timezoneOffset, "minute")
                      .format(
                        (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " hh:mm:ss"
                      ) +
                      " / " +
                      moment(d["measureTotal_input_utc_dt"])
                        .subtract(timezoneOffset, "minute")
                        .format(
                          (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                            " hh:mm:ss"
                        )}
                  </p>
                  <div
                    className="wrap"
                    onClick={() => showDetail(measureRef, i)}
                  >
                    <strong>
                      {moment(d["measureTotal_measure_utc_dt"])
                        .add(timezoneOffset, "minutes")
                        .format("YYYYMMDDhhmmss") + ".jpg"}
                    </strong>
                    <div className="img-wrap">
                      <span>
                        <img
                          src={
                            "data:image/jpg;base64," +
                            d["measureTotal_thumbnail"]
                          }
                          width={"50px"}
                          height={"50px"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="img">
          <div className="img__list">
            <div className="img__item original on">
              <div className="wrap">
                <div className="img-wrap">
                  <span>
                    <img
                      src={"data:image/jpg;base64," + measureMediaDetailData}
                      width={"99%"}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="web-view display-none" id="measureView5">
        <div className="img">
          <p className="unit">
            {formatMessage("txt_date_measured")} /{" "}
            {formatMessage("txt_date_transferred")}
          </p>
          <div className="img__list">
            {measureMediaTableData.map((d: any, i: any) => {
              const measureRef = d["measureTotal_measure_value1"];
              console.log("measureRef", measureRef);
              return (
                <article>
                  <div className="img__item thumb">
                    <p className="date">
                      {moment(d["measureTotal_measure_utc_dt"])
                        .subtract(timezoneOffset, "minute")
                        .format(
                          (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                            " hh:mm:ss"
                        ) +
                        " / " +
                        moment(d["measureTotal_input_utc_dt"])
                          .subtract(timezoneOffset, "minute")
                          .format(
                            (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                              " hh:mm:ss"
                          )}
                    </p>
                    <div
                      className="wrap"
                      onClick={() => showDetail(measureRef, i)}
                    >
                      <strong>
                        {moment(d["measureTotal_measure_utc_dt"])
                          .add(timezoneOffset, "minutes")
                          .format("YYYYMMDDhhmmss") + ".jpg"}
                      </strong>
                      <div className="img-wrap">
                        <span>
                          <img
                            src={
                              "data:image/jpg;base64," +
                              d["measureTotal_thumbnail"]
                            }
                            width={"50px"}
                            height={"50px"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    title="mediaDetail"
                    className="img__item display-none"
                    id={"mediaDetailV" + i}
                  >
                    <div className="wrap">
                      <div className="img-wrap">
                        <span>
                          <img
                            src={
                              "data:image/jpg;base64," + measureMediaDetailData
                            }
                            width={"99%"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </div>

      <div className="web-view display-none" id="measureView6">
        <div className="img">
          <p className="unit">
            {formatMessage("txt_date_measured")} /{" "}
            {formatMessage("txt_date_transferred")}
          </p>
          <div className="img__list">
            {measureTableData.map((d: any, i: any) => {
              return (
                <div className="img__item thumb">
                  <p className="date">
                    {moment(d["measureTotal_measure_utc_dt"])
                      .subtract(timezoneOffset, "minute")
                      .format(
                        (paramLang === "ko" ? "YYYY-MM-DD" : "L") + " hh:mm:ss"
                      ) +
                      " / " +
                      moment(d["measureTotal_input_utc_dt"])
                        .subtract(timezoneOffset, "minute")
                        .format(
                          (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                            " hh:mm:ss"
                        )}
                  </p>
                  <div
                    className="wrap"
                    onClick={() => showDetail(d["measureTotal_measure_seq"], i)}
                  >
                    <strong>{d["measureTotal_measure_value1"]}</strong>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="img">
          <div className="img__list">
            <div className="img__item original on">
              <div className="wrap">
                <div className="img-wrap">
                  <span>
                    <audio id="audioInfo" controls preload="auto">
                      <source
                        id="source"
                        type="audio/wav"
                        src={"data:audio/mpeg;base64," + measureMediaDetailData}
                      />
                    </audio>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="web-view display-none" id="measureView7">
        <div className="img">
          <p className="unit">
            {formatMessage("txt_date_measured")} /{" "}
            {formatMessage("txt_date_transferred")}
          </p>
          <div className="img__list">
            {measureTableData.map((d: any, i: any) => {
              return (
                <article>
                  <div className="img__item thumb">
                    <p className="date">
                      {moment(d["measureTotal_measure_utc_dt"])
                        .subtract(timezoneOffset, "minute")
                        .format(
                          (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                            " hh:mm:ss"
                        ) +
                        " / " +
                        moment(d["measureTotal_input_utc_dt"])
                          .subtract(timezoneOffset, "minute")
                          .format(
                            (paramLang === "ko" ? "YYYY-MM-DD" : "L") +
                              " hh:mm:ss"
                          )}
                    </p>
                    <div
                      className="wrap"
                      onClick={() =>
                        showDetail(d["measureTotal_measure_seq"], i)
                      }
                    >
                      <strong>{d["measureTotal_measure_value1"]}</strong>
                    </div>
                  </div>

                  <div
                    title="mediaDetail"
                    className="img__item display-none"
                    id={"mediaDetailV" + i}
                  >
                    <div className="wrap">
                      <div className="img-wrap">
                        <span>
                          <audio id="audioInfo" controls preload="auto">
                            <source
                              id="source"
                              type="audio/wav"
                              src={
                                "data:audio/mpeg;base64," +
                                measureMediaDetailData
                              }
                            />
                          </audio>
                        </span>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </article>
  );
};

export default MeasurementMobile;
