/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { IAssessmentProps, q01, q02, q03, q04, q05, q06 } from "./IAssessment";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import { Button, DateBox, RadioGroup, SelectBox } from "devextreme-react";
import * as _ from "lodash";
import moment from "moment";
import { alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);

export const Assessment: FunctionComponent<IAssessmentProps> = (props) => {
  locale("" + localStorage.getItem("lang"));
  const common = new Common();
  const [gridList, setGridList] = useState([] as any);
  const [gridListClone, setGridListClone] = useState([] as any);
  const [gridFilterValue, setGridFilterValue] = useState<any>([]);
  const [totalCnt, setTotalCnt] = useState(String);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [nameList, setNameList] = useState([] as any);
  const [name, setName] = useState(String);

  const [q1, setQ1] = useState(Number as any);
  const [q2, setQ2] = useState(Number as any);
  const [q3, setQ3] = useState(Number as any);
  const [q4, setQ4] = useState(Number as any);
  const [q5, setQ5] = useState(Number as any);
  const [q6, setQ6] = useState(Number as any);
  const [inputUtcDt, setInputUtcDt] = useState(Number as any);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const doctorService = new DocotrDataProvider();

    const fetchArticles = async () => {
      $(".dx-toolbar").css("display", "none");
      $(".tap").removeClass("on");
      $("#pageAssessment").addClass("on");
      const gridDt = await doctorService.GetAssessmentList();

      //console.log(gridDt);
      //데이터 초기화
      setNameList(_.uniqBy(gridDt.data.data, "name"));
      setGridList(_.sortBy(gridDt.data.data, "seq").reverse());
      setGridListClone(_.sortBy(gridDt.data.data, "seq").reverse());
      setTotalCnt(gridDt.data.totalCount);
    };
    fetchArticles();
  }, [props]);

  const showColumn = async () => {
    $(".dx-datagrid-column-chooser-button").click();
  };

  const onSelectChanged = async (e: any) => {
    if (e.value === "1W") {
      let cDate = new Date();
      cDate.setDate(cDate.getDate() - 7);
      setStartDate(cDate);
      setEndDate(new Date());
    } else if (e.value === "1M") {
      let cDate = new Date();
      var monthDate = new Date(cDate.setMonth(cDate.getMonth() - 1));
      setStartDate(monthDate);
      setEndDate(new Date());
    } else if (e.value === "Today") {
      setStartDate(new Date());
      setEndDate(new Date());
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };
  const onNameChanged = async (e: any) => {
    setName(e.value);
  };
  const onSearch = async () => {
    let filter: any;
    filter = [];
    filter.push("and");

    if (!common.valueChk(startDate) && common.valueChk(endDate)) {
      alert(formatMessage("msg_select_start_end_date"), "alert");
      return false;
    }

    if (common.valueChk(startDate) && !common.valueChk(endDate)) {
      alert(formatMessage("msg_select_start_end_date"), "alert");
      return false;
    }

    if (!common.valueChk(startDate) || !common.valueChk(endDate)) {
      setGridList(gridListClone);
    } else if (common.valueChk(startDate) && common.valueChk(endDate)) {
      let sValue = moment(startDate).format("YYYY-MM-DD 00:00:00");
      let eValue = moment(endDate).format("YYYY-MM-DD 23:59:59");
      const a = _.filter(gridListClone, function (dt) {
        console.log(dt.inputUtcDt);
        return _.inRange(
          moment(dt.inputUtcDt).valueOf(),
          moment(sValue).valueOf(),
          moment(eValue).valueOf()
        );
      });
      setGridList(a);
    } else {
      setGridList(gridListClone);
    }

    if (common.valueChk(name)) {
      filter.push(
        common.valueChk(name) ? ["name", "contains", name] : ["name", "<>", ""]
      );
    } else {
      filter = [];
    }
    setGridFilterValue(filter);
  };
  const onSDateChanged = async (e: any) => {
    setStartDate(e.value);
  };
  const onEDateChanged = async (e: any) => {
    setEndDate(e.value);
  };

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="tit-area">
            <i className="fa fa-list-alt icon-title" aria-hidden="true"></i>
            <div className="tit">
              <h2>{formatMessage("txt_assessment")}</h2>
              <span className="num">({totalCnt})</span>
            </div>
          </div>
        </header>

        <article className="search-form">
          <form action="" name="searchForm">
            <fieldset>
              <legend className="blind">검색폼</legend>
              <div className="form-item">
                <div className="opt short">
                  <label htmlFor="selDate" className="blind">
                    기간선택
                  </label>
                  <SelectBox
                    items={["All", "Today", "1W", "1M"]}
                    defaultValue={"All"}
                    onValueChanged={onSelectChanged}
                  />
                </div>
                <div className="form-wrap date-item">
                  <div className="tf">
                    <DateBox
                      value={startDate}
                      max={endDate}
                      displayFormat="yyyy-MM-dd"
                      type="date"
                      onValueChanged={onSDateChanged}
                    />
                  </div>
                  <span className="hyphen"> ~ </span>
                  <div className="tf">
                    <DateBox
                      value={endDate}
                      displayFormat="yyyy-MM-dd"
                      type="date"
                      onValueChanged={onEDateChanged}
                    />
                  </div>
                </div>
              </div>
              <div className="form-item mt10">
                <div className="opt">
                  <SelectBox
                    dataSource={_.orderBy(
                      nameList,
                      [(e) => e.name.toLowerCase()],
                      ["asc"]
                    )}
                    placeholder="Name"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"name"}
                    displayExpr="name"
                    valueExpr="name"
                    onValueChanged={onNameChanged}
                  />
                </div>
                <button
                  type="button"
                  className="btn md white"
                  onClick={onSearch}
                >
                  <i className="fa fa-search icon" aria-hidden="true"></i>
                  <span className="blind">검색</span>
                </button>
              </div>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont mt30">
        <header>
          <button
            type="button"
            id="btnDisplay"
            className="btn sm blue flex-mla"
            onClick={showColumn}
          >
            <span>{formatMessage("btn_displayl")}</span>
          </button>
        </header>

        <div className="tbl-wrap">
          <div>
            <DataGrid
              dataSource={gridList}
              columnHidingEnabled={true}
              filterValue={gridFilterValue}
              noDataText="No data"
              allowColumnResizing={true}
              columnMinWidth={50}
              columnAutoWidth={true}
              showBorders={true}
              className="type-ct"
            >
              <ColumnChooser enabled={true} mode="select" />
              <Column
                caption="No"
                cellRender={(e) => {
                  return (
                    e.component.pageIndex() * e.component.pageSize() +
                    e.row.rowIndex +
                    1
                  );
                }}
              />
              <Column
                dataField="name"
                caption={formatMessage("txt_name")}
                cellTemplate={(container: any, options: any) => {
                  $(
                    '<a href="/measurement?insungSeq=' +
                      options.data.insungSeq +
                      '"class="link">'
                  )
                    .append(options.value)
                    .appendTo(container);
                }}
              />
              <Column dataField="gender" caption={formatMessage("txt_sex")} />
              <Column dataField="age" caption={formatMessage("txt_age")} />
              <Column
                dataField="inputUtcDt"
                caption={formatMessage("txt_date")}
                cellRender={(e: any) => {
                  return moment(e.value)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format(
                      localStorage.getItem("lang") === "ko" ? "YYYY-MM-DD" : "L"
                    );
                }}
              />
              <Column
                dataField="assessType"
                caption={formatMessage("txt_type")}
              />
              <Column
                dataField="record"
                caption="Record"
                cellTemplate={(container: any, options: any) => {
                  $("<a class='link' style='cursor: pointer'>")
                    .on("click", () => {
                      const q = options.data.answer.split(",");

                      setInputUtcDt(options.data.inputUtcDt);
                      setQ1(q[0]);
                      setQ2(q[1]);
                      setQ3(q[2]);
                      setQ4(q[3]);
                      setQ5(q[4]);
                      setQ6(q[5]);

                      common.openLayer("popAssess");
                    })
                    .append($("<i>", { class: "fa fa-pencil" }))
                    .appendTo(container);
                }}
              />
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={[10, 20, "all"]}
                displayMode={"full"}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
            </DataGrid>
          </div>
        </div>
      </section>
      <div className="pop-layer pop-layer--sm" id="popAssess">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>{formatMessage("txt_assessment")}</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <p className="txt-ar mb15">
                {moment(inputUtcDt).format("MM/DD/YYYY")}
              </p>
              <article className="chk-list">
                <ul>
                  <li>
                    <p className="ques">
                      1. On average, during the past week, how often were you
                      woken by your asthma during the night.
                    </p>
                    <RadioGroup
                      id="checkQ1"
                      className="ans"
                      items={q01}
                      defaultValue={q01[q1]}
                      disabled={true}
                    />
                  </li>
                  <li>
                    <p className="ques">
                      2. On average, during the past week, how bad were your
                      asthma symptoms when you woke up in the moring.
                    </p>
                    <RadioGroup
                      id="checkQ2"
                      className="ans"
                      items={q02}
                      defaultValue={q02[q2]}
                      disabled={true}
                    />
                  </li>
                  <li>
                    <p className="ques">
                      3. In general, during the past week, how limited were you
                      in your activities because of your asthma?
                    </p>
                    <RadioGroup
                      id="checkQ3"
                      className="ans"
                      items={q03}
                      defaultValue={q03[q3]}
                      disabled={true}
                    />
                  </li>
                  <li>
                    <p className="ques">
                      4. In general, during the past week, how much shortness of
                      breath did you experience because of you asthma?
                    </p>
                    <RadioGroup
                      id="checkQ4"
                      className="ans"
                      items={q04}
                      defaultValue={q04[q4]}
                      disabled={true}
                    />
                  </li>
                  <li>
                    <p className="ques">
                      5. In general, during the past week, how much of the time
                      did you wheeze?
                    </p>
                    <RadioGroup
                      id="checkQ5"
                      className="ans"
                      items={q05}
                      defaultValue={q05[q5]}
                      disabled={true}
                    />
                  </li>
                  <li>
                    <p className="ques">
                      6. On average, during the past week, how many puffs of
                      short-acting bronchodilator(eg. Ventolin) have you used
                      each day?
                    </p>
                    <RadioGroup
                      id="checkQ6"
                      className="ans"
                      items={q06}
                      defaultValue={q06[q6]}
                      disabled={true}
                    />
                  </li>
                </ul>
              </article>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <Button
                className="btn mlg grey"
                text={formatMessage("btn_closel")}
                type="default"
                stylingMode="contained"
                onClick={() => $(".popClose").trigger("click")}
              />
            </div>
          </footer>
        </section>
      </div>
    </main>
  );
};

export default Assessment;
