/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, { Column, Pager, Paging } from "devextreme-react/data-grid";

import { IPopupAlertProps } from "./IPopupAlert";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DocotrDataProvider } from "../../../lib/service/DocotrDataProvider";
import { Button, CheckBox } from "devextreme-react";
import * as _ from "lodash";
import moment from "moment";
import { alert } from "devextreme/ui/dialog";
import { lang } from "../../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import { useInterval } from "react-use";
loadMessages(lang);
locale("en");

export const PopAlert: FunctionComponent<IPopupAlertProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [measure, setMeasure] = useState([] as any);
  const [video, setVideo] = useState([] as any);
  const [checkAlert, setCheckAlert] = useState(true);
  const [checkVideo, setCheckVideo] = useState(true);
  const [checkAll, setCheckAll] = useState(true);

  const [visibleAlert, setVisibleAlert] = useState(true);
  const [visibleVideo, setVisibleVideo] = useState(true);
  const doctorService = new DocotrDataProvider();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzOffset = -1 * moment().tz(tz).utcOffset();
  const [realTime, setRealTime] = useState(Date.now());
  /* componentDidMount && componentDidUpdate */

  useInterval(() => {
    setRealTime(Date.now());
  }, 1000);

  useEffect(() => {
    common.ResizePage();

    const fetchArticles = async () => {
      const measure = await doctorService.GetPatientMeasureList();
      const videoList = await doctorService.GetAppointment(
        new Date(),
        new Date()
      );
      const doctorDt = await doctorService.GetDoctors();
      const alt = doctorDt.data.data;

      setVisibleAlert(alt.measureAlertYn === "1" ? true : false);
      setVisibleVideo(alt.videocallAlertYn === "1" ? true : false);

      setCheckAlert(alt.measureAlertYn === "1" ? true : false);
      setCheckVideo(alt.videocallAlertYn === "1" ? true : false);

      setCheckAll(
        !(alt.measureAlertYn === "0" && alt.videocallAlertYn === "0")
      );

      setVideo(
        _.filter(videoList.data.data, function (o) {
          return (
            o.confirm_yn === "Y" &&
            o.call_yn === "N" &&
            o.appoint_dt + " " + o.start_time >=
              moment().format("YYYY-MM-DD HH:mm")
          );
        })
      );

      setMeasure(
        _.filter(measure.data.data, function (o) {
          return o.normalYn === "N";
        })
      );
    };

    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const onCellRenderMeasureValue = (data: any) => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <span className={"valRed"}>{data.value}</span>
    );
  };
  return (
    <div className="pop-layer pop-layer--sm" id="popAlerts">
      <section className="pop-layer__wrap">
        <header>
          <h3>
            <strong>Alerts</strong>
          </h3>
          <span className="icon icon--close lg popClose"></span>
        </header>

        <div className="cont">
          <div className="cont__wrap">
            <div className="top-icon" style={{ cursor: "pointer" }}>
              <a
                onClick={() => {
                  const common = new Common();
                  common.openLayer("popAlertSet");
                }}
              >
                <i className="fa fa-cog" aria-hidden="true"></i>
              </a>
            </div>
            <article className="mb20">
              <h4>
                <i className="fa fa-file-text-o" aria-hidden="true"></i>
                <strong>Patient Monitoring {" > "} biometrics</strong>
              </h4>
              <div
                className="tbl-wrap"
                style={{ display: visibleAlert ? "" : "none" }}
              >
                <DataGrid
                  dataSource={measure}
                  noDataText="No data"
                  className="type-ct"
                >
                  <Column
                    dataField="measureUtcDt"
                    caption="Date"
                    cellRender={(e: any) => {
                      return moment(e.value)
                        .subtract(tzOffset, "minutes")
                        .format(
                          (localStorage.getItem("lang") === "ko"
                            ? "YYYY-MM-DD"
                            : "L") + " HH:mm:ss"
                        );
                    }}
                    width={150}
                  />
                  <Column dataField="name" caption="Name" />
                  <Column dataField="measureType" caption="Result Type" />
                  <Column
                    dataField="measureValue"
                    caption="Value"
                    cellRender={onCellRenderMeasureValue}
                  />
                  <Column
                    dataField="Confirm"
                    caption="Confirm"
                    width={90}
                    cellTemplate={(container: any, options: any) => {
                      $(
                        '<div class="btn xsm blue" style="cursor: pointer;" >',
                        { id: options.value }
                      )
                        .on("click", () => {
                          doctorService.updatePatientMonitor(options.data);
                          const removeDt = _.filter(measure, function (n: any) {
                            return n.seq !== options.data.seq;
                          });
                          const cnt = $("#cntAlert").text();
                          $("#cntAlert").text(parseInt(cnt) - 1);
                          setMeasure(removeDt);
                        })
                        .append("Confirm")
                        .appendTo(container);
                    }}
                  />
                  <Paging defaultPageSize={5} />
                  <Pager
                    visible={true}
                    displayMode={"full"}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                </DataGrid>
              </div>
              <p
                className="no-data"
                style={{ display: !visibleAlert ? "" : "none" }}
              >
                There are no alerts.
              </p>
            </article>
            <article>
              <h4>
                <i
                  className="fa fa-phone"
                  aria-hidden="true"
                  style={{ display: !visibleAlert ? "" : "none" }}
                ></i>
                <strong>VideoChat {" > "} upcoming call</strong>
              </h4>
              <div
                className="tbl-wrap"
                style={{ display: visibleVideo ? "" : "none" }}
              >
                <DataGrid
                  dataSource={video}
                  noDataText="No data"
                  className="type-ct"
                >
                  <Column
                    dataField="appoint_dt"
                    caption="Date"
                    dataType="date"
                    format="yyyy-MM-dd "
                  />
                  <Column dataField="name" caption="Name" />
                  <Column dataField="time" caption="Time" />
                  <Column
                    dataField="Video"
                    caption="Video"
                    width={120}
                    cellTemplate={(container: any, options: any) => {
                      $(
                        '<div class="btn xsm blue" style="cursor: pointer;" >',
                        { id: options.value }
                      )
                        .on("click", () => {
                          const startTime =
                            options.data.appoint_dt +
                            " " +
                            options.data.start_time;
                          const endTime =
                            options.data.appoint_dt +
                            " " +
                            options.data.end_time;

                          if (
                            moment(startTime)
                              .subtract(10, "minutes")
                              .valueOf() <= moment(Date.now()).valueOf() &&
                            moment(endTime).valueOf() >=
                              moment(realTime).valueOf()
                          ) {
                            window.open(
                              "/videoCall/" + options.data.appoint_seq,
                              "_blank"
                            );

                            setVideo(
                              _.remove(video, (n: any) => {
                                return (
                                  n.appoint_seq !== options.data.appoint_seq
                                );
                              })
                            );
                          } else {
                            alert(
                              formatMessage("txt_reservation_notice"),
                              "alert"
                            );
                          }
                        })
                        .append("Join Call Now")
                        .appendTo(container);
                    }}
                  />
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    displayMode={"full"}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                </DataGrid>
              </div>
              <p
                className="no-data"
                style={{ display: !visibleVideo ? "" : "none" }}
              >
                There are no alerts.
              </p>
            </article>
          </div>
        </div>

        <footer>
          <div className="btn-wrap">
            <Button
              className="btn mlg grey"
              text="Cancel"
              type="default"
              stylingMode="contained"
              onClick={() => $(".popClose").trigger("click")}
            />
          </div>
        </footer>
      </section>
      <div className="pop-layer pop-layer--sm" id="popAlertSet">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>Alert Settings</strong>
            </h3>
            <span
              className="icon icon--close lg"
              onClick={() => $("#popAlertSet").hide()}
            ></span>
          </header>

          <div className="cont">
            <div className="cont__wrap" style={{ height: "113px" }}>
              <ul className="setting-list">
                <li>
                  <strong>
                    <i className="fa fa-bell-o" aria-hidden="true"></i>
                    <span>
                      You can enable or disable notifications for alerts.
                    </span>
                  </strong>
                  <div className="inp">
                    <CheckBox
                      value={checkAll}
                      name="noti"
                      id="noti"
                      onValueChanged={(e: any) => {
                        setCheckAll(e.value);
                        setCheckVideo(e.value);
                        setCheckAlert(e.value);
                      }}
                    />
                    <label htmlFor="noti"> Notification</label>
                  </div>
                </li>
                <li>
                  <strong>
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>
                    <span>Patient Monitoring {" > "} biometrics</span>
                  </strong>
                  <div className="inp">
                    <CheckBox
                      value={checkAlert}
                      name="bio"
                      id="bio"
                      onValueChanged={(e: any) => {
                        setCheckAlert(e.value);
                      }}
                    />
                    <label htmlFor="bio">
                      <span className="blind">
                        Patient Monitoring {" > "} biometrics
                      </span>
                    </label>
                  </div>
                </li>
                <li>
                  <strong>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <span>VideoChat {" > "}upcoming call</span>
                  </strong>
                  <div className="inp">
                    <CheckBox
                      value={checkVideo}
                      name="call"
                      id="call"
                      onValueChanged={(e: any) => {
                        setCheckVideo(e.value);
                      }}
                    />
                    <label htmlFor="call">
                      <span className="blind">
                        VideoChat {" > "} upcoming call
                      </span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <Button
                className="btn mlg blue"
                text="Save"
                type="success"
                stylingMode="contained"
                onClick={() => {
                  setVisibleAlert(checkAlert);
                  setVisibleVideo(checkVideo);

                  const json = {
                    measureAlertYn: checkAlert ? "1" : "0",
                    videocallAlertYn: checkVideo ? "1" : "0",
                  };
                  doctorService.alertVisibleSetting(json);
                  $("#popAlertSet").hide();
                }}
              />
              <Button
                className="btn mlg grey"
                text="Cancel"
                type="default"
                stylingMode="contained"
                onClick={() => $("#popAlertSet").hide()}
              />
            </div>
          </footer>
        </section>
      </div>
    </div>
  );
};

export default PopAlert;
