/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IPopupProps } from "./IPopup";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../../lib/utils/common";
import { Button } from "devextreme-react";
import * as _ from "lodash";
import { DocotrDataProvider } from "../../../lib/service/DocotrDataProvider";
import { useHistory } from "react-router";
import { lang } from "../../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
export const PopUnits: FunctionComponent<IPopupProps> = (props) => {
  locale("" + localStorage.getItem("lang"));
  const history = useHistory();
  const common = new Common();
  const [units, setUnits] = useState([]);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const doctorService = new DocotrDataProvider();
    const fetchArticles = async () => {
      const dt = await doctorService.GetDoctors();

      const d = dt.data.data;

      $('input[name="uric"]').val([d.uaUnit]);
      $('input[name="ketone"]').val([d.ketoneUnit]);
      $('input[name="height"]').val([d.heightUnit]);
      $('input[name="weight"]').val([d.weightUnit]);
      $('input[name="glucose"]').val([d.bldsgrUnit]);
      $('input[name="cholesterol"]').val([d.cholesterolUnit]);
      $('input[name="hbA1c"]').val([d.hba1cUnit]);
      $('input[name="body"]').val([d.bodytempUnit]);
      setUnits(dt.data.data);
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  return (
    <div className="pop-layer pop-layer--sm" id="popUnits">
      <section className="pop-layer__wrap">
        <header>
          <h3>
            <strong>{formatMessage("txt_unit_measure")}</strong>
          </h3>
          <span className="icon icon--close lg popClose"></span>
        </header>

        <div className="cont">
          <div className="cont__wrap">
            <div className="tbl-wrap">
              <table className="type-th type-pop">
                <caption>{formatMessage("txt_unit_measure")}</caption>
                <colgroup>
                  <col width="30%" />
                  <col width="35%" />
                  <col width="35%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="colgroup" className="fst" colSpan={2}>
                      <strong>{formatMessage("txt_result_type")}</strong>
                    </th>
                    <th scope="col">
                      <strong>{formatMessage("txt_unit_measure")}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>{formatMessage("txt_blood_pressure")}</strong>
                    </th>
                    <td>
                      <div>{formatMessage("txt_systolic")}</div>
                      <div>{formatMessage("txt_diastolic")}</div>
                    </td>
                    <td>mmHg</td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>Pulse</strong>
                    </th>
                    <td></td>
                    <td>/ min</td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>{formatMessage("txt_glucose")}</strong>
                    </th>
                    <td></td>
                    <td>
                      <div className="form-item">
                        <div className="inp">
                          <input
                            type="radio"
                            name="glucose"
                            id="glucose1"
                            defaultChecked={true}
                            value="mg/dL"
                          />
                          <label htmlFor="glucose1">mg/dL</label>
                        </div>
                        <div className="inp">
                          <input
                            type="radio"
                            name="glucose"
                            id="glucose2"
                            value="mmol/L"
                          />
                          <label htmlFor="glucose2">mmol/L</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>Cholesterol</strong>
                    </th>
                    <td>
                      <div>Total Cholesterol(TC)</div>
                      <div>Trig.</div>
                      <div>HDL</div>
                      <div>LDL</div>
                      <div>Total Cholesterol(TC) / HDL</div>
                    </td>
                    <td>
                      <div className="form-item">
                        <div className="inp">
                          <input
                            type="radio"
                            name="cholesterol"
                            id="cholesterol1"
                            defaultChecked={true}
                            value="mg/dL"
                          />
                          <label htmlFor="cholesterol1">mg/dL</label>
                        </div>
                        <div className="inp">
                          <input
                            type="radio"
                            name="cholesterol"
                            id="cholesterol2"
                            value="mmol/L"
                          />
                          <label htmlFor="cholesterol2">mmol/L</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>HbA1c</strong>
                    </th>
                    <td></td>
                    <td>
                      <div className="form-item">
                        <div className="inp">
                          <input
                            type="radio"
                            name="hbA1c"
                            id="hbA1c1"
                            defaultChecked={true}
                            value="%"
                          />
                          <label htmlFor="hbA1c1">%</label>
                        </div>
                        <div className="inp">
                          <input
                            type="radio"
                            name="hbA1c"
                            id="hbA1c2"
                            value="mmol/moL"
                          />
                          <label htmlFor="hbA1c2">mmol/moL</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>{formatMessage("txt_body_temperature")}</strong>
                    </th>
                    <td></td>
                    <td>
                      <div className="form-item">
                        <div className="inp">
                          <input
                            type="radio"
                            name="body"
                            id="body1"
                            defaultChecked={true}
                            value="℃"
                          />
                          <label htmlFor="body1">℃</label>
                        </div>
                        <div className="inp">
                          <input
                            type="radio"
                            name="body"
                            id="body2"
                            value="℉"
                          />
                          <label htmlFor="body2">℉</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>SpO₂</strong>
                    </th>
                    <td></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <th scope="rowgroup" className="fst" rowSpan={3}>
                      <strong>{formatMessage("txt_weight")}</strong>
                    </th>
                    <td scope="row">{formatMessage("txt_weight")}</td>
                    <td>
                      <div className="form-item">
                        <div className="inp">
                          <input
                            type="radio"
                            name="weight"
                            id="weight1"
                            defaultChecked={true}
                            value="kg"
                          />
                          <label htmlFor="weight1">kg</label>
                        </div>
                        <div className="inp">
                          <input
                            type="radio"
                            name="weight"
                            id="weight2"
                            value="lb"
                          />
                          <label htmlFor="weight2">lb</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">{formatMessage("txt_bmi")}</td>
                    <td>kg/㎡</td>
                  </tr>
                  <tr>
                    <td scope="row">{formatMessage("txt_body_fat")}</td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>{formatMessage("txt_height")}</strong>
                    </th>
                    <td></td>
                    <td>
                      <div className="form-item">
                        <div className="inp">
                          <input
                            type="radio"
                            name="height"
                            id="height1"
                            defaultChecked={true}
                            value="cm"
                          />
                          <label htmlFor="height1">cm</label>
                        </div>
                        <div className="inp">
                          <input
                            type="radio"
                            name="height"
                            id="height2"
                            value="inch"
                          />
                          <label htmlFor="height2">inch</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>{formatMessage("txt_ketone_blood")}</strong>
                    </th>
                    <td></td>
                    <td>
                      <div className="form-item">
                        <div className="inp">
                          <input
                            type="radio"
                            name="ketone"
                            id="ketone1"
                            defaultChecked={true}
                            value="mg/dL"
                          />
                          <label htmlFor="ketone1">mg/dL</label>
                        </div>
                        <div className="inp">
                          <input
                            type="radio"
                            name="ketone"
                            id="ketone2"
                            value="mmol/L"
                          />
                          <label htmlFor="ketone2">mmol/L</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="fst">
                      <strong>{formatMessage("txt_uric_acid")}</strong>
                    </th>
                    <td></td>
                    <td>
                      <div className="form-item">
                        <div className="inp">
                          <input
                            type="radio"
                            name="uric"
                            id="uric1"
                            defaultChecked={true}
                            value="mg/dL"
                          />
                          <label htmlFor="uric1">mg/dL</label>
                        </div>
                        <div className="inp">
                          <input
                            type="radio"
                            name="uric"
                            id="uric2"
                            value="mmol/L"
                          />
                          <label htmlFor="uric2">mmol/L</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <footer>
          <div className="btn-wrap">
            <Button
              className="btn mlg blue"
              text={formatMessage("btn_save_changes")}
              type="success"
              stylingMode="contained"
              onClick={() => {
                const json = {
                  uaUnit: $('input[name="uric"]:checked').val(),
                  ketoneUnit: $('input[name="ketone"]:checked').val(),
                  heightUnit: $('input[name="height"]:checked').val(),
                  weightUnit: $('input[name="weight"]:checked').val(),
                  bldsgrUnit: $('input[name="glucose"]:checked').val(),
                  cholesterolUnit: $('input[name="cholesterol"]:checked').val(),
                  hba1cUnit: $('input[name="hbA1c"]:checked').val(),
                  bodytempUnit: $('input[name="body"]:checked').val(),
                  bmi: "kg/㎡",
                  bodyFat: "%",
                  spo2: "%",
                  bp: "mmHg",
                  pulse: "/ min",
                };

                const doctorService = new DocotrDataProvider();
                doctorService.updateDocUnits(json).then(async (d: any) => {
                  $(".popClose").trigger("click");
                  window.location.reload();
                });
              }}
            />
            <Button
              className="btn mlg grey"
              text={formatMessage("btn_closel")}
              type="default"
              stylingMode="contained"
              onClick={() => $(".popClose").trigger("click")}
            />
          </div>
        </footer>
      </section>
    </div>
  );
};

export default PopUnits;
