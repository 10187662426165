import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Login from "./components/Home/Login";
import Patient from "./components/Patient/Patient";
import Measurement from "./components/Measurement/Measurement";
import Files from "./components/Files/Files";
import Appointment from "./components/Appointment/Appointment";
import Assessment from "./components/Assessment/Assessment";

import Main from "./components/Main/Main";
import Message from "./components/Message/Message";
import VideoCall from "./components/VideoCall/VideoCall";
import PopUnits from "./components/popup/popUnits/PopUnits";
import PopPhone from "./components/popup/popPhone/PopPhone";
import { Common } from "./lib/utils/common";
import { Button, TextBox } from "devextreme-react";
import $ from "jquery";
import "jquery-ui";
import tBox from "devextreme/ui/text_box";
import { DocotrDataProvider } from "./lib/service/DocotrDataProvider";
import MeasurementMobile from "./components/Measurement/MeasurementMobile";
import "dotenv/config";
import { alert, confirm } from "devextreme/ui/dialog";
import { lang } from "./lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import { PopAlert } from "./components/popup/popAlert/PopAlert";
import * as _ from "lodash";
loadMessages(lang);

const start = async () => {
  const doctorService = new DocotrDataProvider();
  const common = new Common();
  locale("" + localStorage.getItem("lang"));
  if (common.accountChk()) {
    try {
      const main = await doctorService.GetMainCount();
      const videoList = await doctorService.GetAppointment(
        new Date(),
        new Date()
      );

      const leng: number =
        _.filter(main.data.measure, function (o) {
          return o.normalYn === "N";
        }).length +
        _.filter(videoList.data.data, function (o) {
          return o.confirm_yn === "Y" && o.call_yn === "N";
        }).length;

      $("#cntAlert").text(leng);
    } catch (err: any) {}
  }
};

start();
class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Redirect from="/" exact to="/auth/login" />
          <Route path="/VideoCall/:id" component={VideoCall} />
          <Route path="/measurementmobile" component={MeasurementMobile} />
          <div className="comHeight">
            <div className="skip-navi">
              <a href="#content">본문 바로가기</a>
            </div>

            <div className="wrap">
              <header className="header">
                <div className="header__wrap">
                  <h1>
                    <a href="/main">
                      <span className="blind">Hicare</span>
                    </a>
                  </h1>

                  <button type="button" className="menu-all">
                    <span className="blind">전체메뉴</span>
                    <div className="icon icon--menu">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </button>

                  <div className="util">
                    <div
                      className="icon-wrap"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const common = new Common();
                        common.openLayer("popAlerts");
                      }}
                    >
                      <span id="cntAlert" className="alert">
                        {/* {
                          _.filter(measure.data.data, function (o) {
                            return o.normalYn === "N";
                          }).length
                        } */}
                      </span>
                      <i
                        className="fa fa-bell-o icon-bell"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <strong className="user-name">
                      {localStorage.getItem("id")}
                    </strong>
                    <button
                      type="button"
                      id="btnUtil"
                      className="util-all"
                      onClick={() => {
                        if ($("#btnUtil").hasClass("on")) {
                          $("#divUtil").removeClass("on");
                          $("#btnUtil").removeClass("on");
                        } else {
                          $("#divUtil").addClass("on");
                          $("#btnUtil").addClass("on");
                        }
                      }}
                    >
                      <i
                        className="fa fa-angle-down icon-arrow"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div id="divUtil" className="util-list">
                      <span className="blind">확장메뉴</span>
                      <ul>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const common = new Common();
                              common.openLayer("popPassword");
                            }}
                          >
                            <i className="fa fa-lock" aria-hidden="true"></i>
                            <span>{formatMessage("txt_pwd_change")}</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="/auth/login"
                            onClick={() => {
                              const doctorService = new DocotrDataProvider();
                              doctorService
                                .logoutDoctor()
                                .then(async (d: any) => {
                                  localStorage.removeItem("jwt");
                                });
                            }}
                          >
                            <i
                              className="fa fa-sign-out"
                              aria-hidden="true"
                            ></i>
                            <span>{formatMessage("txt_logout")}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <nav className="gnb">
                  <ul>
                    <li>
                      <a href="/patient" id="pagePatient" className="tap">
                        {formatMessage("mnu_patient")}
                      </a>
                    </li>
                    <li>
                      <a
                        href="/measurement"
                        id="pageMeasurement"
                        className="tap"
                      >
                        {formatMessage("mnu_measurement")}
                      </a>
                    </li>
                    <li>
                      <a
                        href="/appointment"
                        id="pageAppointment"
                        className="tap"
                      >
                        {formatMessage("mnu_appointment")}
                      </a>
                    </li>
                    <li>
                      <a href="/assessment" id="pageAssessment" className="tap">
                        {formatMessage("mnu_assessment")}
                      </a>
                    </li>
                    <li>
                      <a href="/files" id="pageFiles" className="tap">
                        {formatMessage("mnu_files")}
                      </a>
                    </li>
                    <li>
                      <a href="/message" id="pageMessage" className="tap">
                        {formatMessage("mnu_message")}
                      </a>
                    </li>
                  </ul>
                </nav>
              </header>
              <Route path="/patient" component={Patient} />
              <Route path="/measurement" component={Measurement} />
              <Route path="/files" component={Files} />
              <Route path="/appointment" component={Appointment} />
              <Route path="/assessment" component={Assessment} />
              <Route path="/message" component={Message} />
              <Route path="/main" component={Main} />

              <footer className="footer">
                <div className="footer__wrap">
                  <p className="copy">
                    Copyright ©HicareNet Inc. All rights Reserved
                  </p>
                </div>
              </footer>
              <PopAlert />
              <PopUnits />
              <PopPhone />
              <div className="pop-layer pop-layer--sm" id="popPassword">
                <section className="pop-layer__wrap">
                  <header>
                    <h3>
                      <strong>{formatMessage("txt_pwd_change")}</strong>
                    </h3>
                    <span className="icon icon--close lg popClose"></span>
                  </header>

                  <div className="cont">
                    <div className="cont__wrap">
                      <div className="tf mb10">
                        <label htmlFor="passwd" className="mb5">
                          {formatMessage("txt_pwd")}
                        </label>
                        <TextBox id="passwd" mode="password" />
                      </div>
                      <div className="tf">
                        <label htmlFor="passwdConf" className="mb5">
                          {formatMessage("txt_pwd_confirmed")}
                        </label>
                        <TextBox id="passwdConf" mode="password" />
                      </div>
                    </div>
                  </div>

                  <footer>
                    <div className="btn-wrap">
                      <Button
                        className="btn mlg blue"
                        text={formatMessage("btn_save_changes")}
                        type="success"
                        stylingMode="contained"
                        onClick={async () => {
                          const passwd = document.getElementById(
                            "passwd"
                          ) as HTMLElement;
                          const passwdConf = document.getElementById(
                            "passwdConf"
                          ) as HTMLElement;
                          const doctorService = new DocotrDataProvider();

                          if (
                            tBox.getInstance(passwd).option("value") ===
                            tBox.getInstance(passwdConf).option("value")
                          ) {
                            let result = confirm(
                              "<i>" +
                                formatMessage("msg_want_change_pwd") +
                                "</i>",
                              "Confirm changes"
                            );
                            result.then((dialogResult) => {
                              if (dialogResult) {
                                doctorService
                                  .chagePassword(
                                    tBox.getInstance(passwd).option("value")
                                  )
                                  .then(async (d: any) => {
                                    alert(formatMessage("btn_save"), "Success");
                                    $(".popClose").trigger("click");
                                  });
                              } else {
                                alert("Canceled", "Canceled");
                              }
                            });
                          } else {
                            alert(formatMessage("msg_pwd_not_match"), "Fail");
                          }
                        }}
                      />
                      <Button
                        className="btn mlg grey"
                        text={formatMessage("btn_closel")}
                        type="default"
                        stylingMode="contained"
                        onClick={() => $(".popClose").trigger("click")}
                      />
                    </div>
                  </footer>
                </section>
              </div>
            </div>
          </div>
        </Switch>
      </Router>
    );
  }
}
export default App;
