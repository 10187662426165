export const te = {
  btn_add_to_call: "เพิ่มการโทร",
  btn_cancel: "ยกเลิก",
  btn_change_layout: "เปลี่ยนเค้าโครงหน้าต่างสนทนา",
  btn_close: "ปิด",
  btn_copy_url: "คัดลอก URL การประชุม",
  btn_display: "แสดงหน้าจอ",
  btn_end_call: "สิ้นสุดการโทร",
  btn_graph: "กราฟ",
  btn_join_call_now: "เข้าร่วมการสนทนาตอนนี้",
  btn_reservation: "สร้างการนัดหมาย",
  btn_rewrite: "เขียนใหม่",
  btn_save: "บันทึก",
  btn_save_changes: "บันทึกการเปลี่ยนแปลง",
  btn_send: "ส่ง",
  btn_start_recording: "เริ่มการบันทึก",
  btn_stop_recording: "หยุดการบันทึก",
  btn_table: "ตาราง",
  btn_trend_chart: "แผนภูมิแนวโน้ม",
  btn_trend_table: "ตารางแนวโน้ม",
  btn_video_call: "สนทนาทางวีดีโอคอล",
  datatables_language: "Thai.json",
  mnu_appointment: "การนัดหมาย",
  mnu_assessment: "การประเมินผล",
  mnu_files: "ไฟล์",
  mnu_image: "ภาพ",
  mnu_measurement: "การวัดค่า",
  mnu_message: "ข้อความ",
  mnu_patient: "ผู้ป่วย",
  msg_appointment_cancelled: "การนัดหมายถูกยกเลิกแล้ว",
  msg_appointment_changed: "การนัดหมายถูกเปลี่ยนแปลงแล้ว",
  msg_appointment_created: "สร้างการนัดหมายของคุณแล้ว",
  msg_call_ended: "จบการสนทนา",
  msg_call_setting_saved: "บันทึกตั้งค่าการโทรแล้ว",
  msg_cancel_appointment: "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการนัดหมายนี้",
  msg_canceled: "ถูกยกเลิกแล้ว",
  msg_choose_photos_videos: "โปรดเลือกรูปภาพ หรือวิดีโอ อย่างน้อย 1 อย่าง",
  msg_delete_appoint: "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการนัดหมายนี้",
  msg_end_date_after_start_date:
    '"วันที่สิ้นสุด" ต้องอยู่หลัง "วันที่เริ่มต้น"',
  msg_enter_agenda: "กรุณากรอกรายละเอียดการนัดหมาย",
  msg_enter_id: "กรุณากรอกหมายเลขผู้ใช้งาน",
  msg_enter_message: "กรุณากรอกข้อความที่จะส่ง",
  msg_enter_phone_number: "กรุณากรอกหมายเลขโทรศัพท์",
  msg_enter_phone_title: "กรุณากรอกรหัสขึ้นต้นโทรศัพท์",
  msg_enter_pwd: "กรุณากรอกรหัสผ่าน",
  msg_enter_pwd_confirmed: "กรุณากรอกรหัสผ่านอีกครั้ง",
  msg_enter_title: "กรุณากรอกหัวข้อเรื่อง",
  msg_line_busy: "สายไม่ว่าง. โปรดลองโทรอีกครั้งในภายหลัง",
  msg_login_fail:
    "ไม่พบหมายเลขผู้ใช้งานที่คุณป้อนหรือรหัสผ่านของคุณไม่ถูกต้อง \n โปรดลองอีกครั้ง",
  msg_max_length:
    "ไบต์สูงสุดคือ $ {length} ไบต์ ตัวอักษรที่เกินจะถูกลบโดยอัตโนมัติ",
  msg_message_sent: "ข้อความได้ถูกส่งแล้ว",
  msg_missed_call: "ไม่มีการตอบรับ \n ได้โปรดตรวจสอบและโทรอีกครั้งในภายหลัง",
  msg_modified: "ได้ถูกแก้ไขแล้ว",
  msg_offline:
    "ไม่มีการตอบรับ \n (บุคคลอื่นอาจออฟไลน์) \nโปรดตรวจสอบและโทรอีกครั้งในภายหลัง",
  msg_pwd_changed: "รหัสผ่านได้ถูกเปลี่ยนแปลงแล้ว",
  msg_pwd_not_match: "รหัสผ่านไม่ถูกต้อง. \n โปรดตรวจสอบรหัสผ่านอีกครั้ง",
  msg_select_appointment_date: "กรุณาเลือกวันนัดหมาย",
  msg_select_appointment_time: "กรุณาเลือกเวลานัดหมาย",
  msg_select_participant: "กรุณาเลือกผู้ป่วยที่ต้องการนัดหมาย",
  msg_select_patient: "กรุณาเลือกผู้ป่วย",
  msg_select_start_end_date: "กรุณาเลือกวันที่ เริ่มต้น/สิ้นสุด",
  msg_want_change_pwd: "คุณต้องการเปลี่ยนรหัสผ่านจริงหรือไม่?",
  msg_want_end_call: "คุณแน่ใจหรือไม่ว่าต้องการสิ้นสุดการสนทนา",
  txt_2hours_after_meal: "2 ชั่วโมงหลังอาหาร",
  txt_add_to_call: "เพิ่มเพื่อโทร",
  txt_age: "อายุ",
  txt_agenda: "ข้อมูลการนัดหมาย",
  txt_albumin: "Albumin",
  txt_all: "ทั้งหมด",
  txt_assessment: "แบบประเมินผล",
  txt_before_5mins: "ก่อน 5 นาที",
  txt_before15mins: "ก่อน 15 นาที",
  txt_before1hour: "ก่อน 1 ชั่วโมง",
  txt_before30mins: "ก่อน 30 นาที",
  txt_bil: "Bil",
  txt_bilirubin: "บิลิรูบิน",
  txt_blood_pressure: "ความดันโลหิต",
  txt_blood_rbc: "เม็ดเลือดแดง (RBC / ㎕)",
  txt_blood_sugar: "น้ำตาลในเลือด",
  txt_bmi: "ค่าดัชนีมวลกาย",
  txt_body_fat: "ไขมันในร่างกาย",
  txt_body_temperature: "อุณหภูมิร่างกาย",
  txt_bodyfat: "สัดส่วนไขมันในร่างกาย",
  txt_bpm: "ครั้ง/นาที",
  txt_bytes: "Bytes",
  txt_calling: "กำลังโทร",
  txt_camera_connected: "เชื่อมต่อกล้องแล้ว -  $ {name} กล้อง",
  txt_camera_disconnected: "กล้องถูกตัดการเชื่อมต่อ ไม่สามารถเชื่อมต่อได้",
  txt_capnography: "วัดความเข้มข้นของ CO2 ในลมหายใจ",
  txt_check: "ตรวจสอบ",
  txt_cholesterol: "คอเลสเตอรอล",
  txt_cholesterol_cardiochek: "คอเลสเตอรอล (ตรวจวัดระดับไขมัน)",
  txt_comparative_results: "การเปรียบเทียบข้อมูล",
  txt_copd: "โรคหลอดลมอุดกั้นเรื้อรัง (COPD)",
  txt_creatinie: "Creatinine",
  txt_date: "วันที่",
  txt_date_measured: "วันที่ (วัดค่า)",
  txt_date_transferred: "วันที่ (ส่งค่าการวัด)",
  txt_detail: "รายละเอียด",
  txt_detection_result: "ผลการตรวจจับ",
  txt_device: "เครื่องมือ",
  txt_diagnosis_result: "ผลการวินิจฉัย",
  txt_diastolic: "ความดันตัวล่าง",
  txt_digital_microscope: "กล้องจุลทรรศน์ดิจิทัล",
  txt_digital_microscope_contents: "รูปถ่ายจากการส่องกล้อง",
  txt_dob: "วันเกิด",
  txt_ecg: "คลื่นไฟฟ้าหัวใจ (ECG)",
  txt_edit: "แก้ไข",
  txt_ela: "ELA",
  txt_end_date: "วันที่สิ้นสุด",
  txt_end_time: "เวลาสิ้นสุด",
  txt_entered_the_room: "เข้าห้องแล้ว",
  txt_etco2: "EtCO₂",
  txt_ev: "EV",
  txt_fasting: "การอดอาหาร",
  txt_fef: "FEF",
  txt_fet: "FET",
  txt_fev: "FEV",
  txt_filter: "ตัวกรอง",
  txt_fit: "FIT",
  txt_fiv: "FIV",
  txt_fivc: "FIVC",
  txt_fvc: "FVC",
  txt_gain: "สะสม",
  txt_glu: "Glu",
  txt_glucose: "กลูโคส",
  txt_graph: "กราฟ",
  txt_hba1c: "HbA1c",
  txt_hct: "ฮีมาโทคริต",
  txt_hdl: "ไขมันดี",
  txt_height: "ส่วนสูง",
  txt_hour: "ชั่วโมง",
  txt_hr: "HR",
  txt_image: "ภาพ",
  txt_input_your_text: "ป้อนข้อความของคุณที่นี่",
  txt_inr: "INR",
  txt_interval: "ช่วง",
  txt_invite_participants: "เชิญผู้เข้าร่วม",
  txt_ket: "Ket",
  txt_ketone: "คีโตน",
  txt_ketone_blood: "β-คีโตน (เลือด)",
  txt_ketones: "คีโตน",
  txt_ldl: "ไขมันเลว",
  txt_left_the_room: "ออกจากห้องแล้ว",
  txt_leu: "Leu",
  txt_leukocytes: "Leukocytes(WBC/㎕)",
  txt_line_budy: "การสนทนาทางวิดีโอ",
  txt_login_id: "หมายเลขผู้ใช้งาน",
  txt_logout: "ออกจากระบบ",
  txt_measured: "วัดค่า",
  txt_measured_cardiac_rythm_events: "การวัดการเต้นของหัวใจ",
  txt_medical_staff: "บุคลากรทางการเเพทย์",
  txt_message: "ข้อความ",
  txt_message_list: "รายการข้อความ",
  txt_min: "นาที",
  txt_name: "ชื่อ",
  txt_negative: "ผลลบ",
  txt_nit: "Nit",
  txt_nitrite: "ไนไตรต์",
  txt_no: "ลำดับที่",
  txt_no_appointment: "ไม่มีการนัดหมายในวันนี้",
  txt_no_matches: "ไม่พบข้อมูลที่ตรงกับการค้นหาของคุณ",
  txt_none: "ไม่มี",
  txt_normal: "ปกติ",
  txt_normal_sinus_rhythm: "จังหวะการเต้นของหัวใจปกติ",
  txt_not_answering_call: "ฉันไม่รับสายในตอนนี้ \nโปรดโทรอีกครั้งในภายหลัง",
  txt_ob: "OB",
  txt_occult_blood: "เม็ดเลือดแดงในอุจจาระ",
  txt_october: "ออฟไลน์",
  txt_offline: "ออฟไลน์",
  txt_others: "อื่น ๆ",
  txt_p_qrs_t_qrst_axis: "P/QRS/T/QRST axis",
  txt_pacemaker: "เครื่องกระตุ้นไฟฟ้าหัวใจ",
  txt_param: "Param",
  txt_participants: "ผู้เข้าร่วม",
  txt_patient: "ผู้ป่วย",
  txt_patient_data: "ข้อมูลผู้ป่วย",
  txt_pef: "PEF",
  txt_peft: "PEFt",
  txt_pft: "PFT",
  txt_ph: "pH",
  txt_phone_number: "หมายเลขโทรศัพท์",
  txt_phone_number_characters: "หมายเลขโทรศัพท์ไม่สามารถมีอักขระได้",
  txt_phone_settings: "การตั้งค่าโทรศัพท์",
  txt_phone_title: "รหัสขึ้นต้นโทรศัพท์",
  txt_photo: "ภาพถ่าย",
  txt_pick_time: "เลือกเวลา",
  txt_pif: "PIF",
  txt_positive: "ผลบวก",
  txt_pqrstqrst_axis: "P/QRS/T/QRST axis",
  txt_pr: "PR",
  txt_pr_interval: "ช่วง PR",
  txt_pred: "Pred",
  txt_pro: "Pro",
  txt_protein: "โปรตีน",
  txt_prt_axis: "P/R/T axis",
  txt_pt: "PT",
  txt_ptinr: "PT/INR",
  txt_pulse: "ชีพจร",
  txt_pulse_bpm: "ชีพจร(ครั้ง/นาที)",
  txt_pwd: "รหัสผ่าน",
  txt_pwd_change: "เปลี่ยนรหัสผ่าน",
  txt_pwd_confirmed: "ยืนยันรหัสผ่าน",
  txt_qrs: "QRS",
  txt_qrs_duration: "กลุ่มคลื่น QRS",
  txt_qt: "QT",
  txt_qt_qtc: "QT/QTc",
  txt_qtc: "QTc",
  txt_qtr: "QTr",
  txt_range: "ช่วง",
  txt_receiver: "ผู้รับ",
  txt_record: "บันทึก",
  txt_registratered: "ลงทะเบียน",
  txt_remember_me: "จำฉันไว้",
  txt_reminder_email: "อีเมลแจ้งเตือน",
  txt_reminder_sms: "SMSแจ้งเตือน",
  txt_respiratory_rate: "อัตราการหายใจ (ครั้งต่อนาที)",
  txt_result: "ผลลัพธ์",
  txt_result_actual_measurement:
    "ผลลัพธ์ของ pH (ความเป็นกรด), ความถ่วงจำเพาะและไนไตรต์เป็นค่าการวัดจริง",
  txt_result_type: "ประเภทผลลัพธ์",
  txt_rr: "RR",
  txt_rv5: "RV5",
  txt_service: "การบริการ",
  txt_setup_information: "คุณสามารถตั้งค่าข้อมูลสำหรับแอพ Hicare > โทรศัพท์",
  txt_sex: "เพศ",
  txt_sex_age: "เพศ / อายุ",
  txt_sg: "SG",
  txt_sign_in: "เข้าสู่ระบบ",
  txt_speed: "ความเร็ว",
  txt_spo2: "ความอิ่มตัวออกซิเจนในเลือด",
  txt_start_date: "วันที่เริ่ม",
  txt_start_time: "เวลาที่เริ่ม",
  txt_sv1: "SV1",
  txt_system_setup: "ติดตั้งระบบ",
  txt_systolic: "ความดันตัวบน",
  txt_table: "ตาราง",
  txt_tc: "TC",
  txt_test_date: "วันที่ทดสอบ",
  txt_test_item: "รายการทดสอบ (หน่วย)",
  txt_tg: "TG",
  txt_time: "เวลา",
  txt_title: "หัวข้อ",
  txt_title_check: "อ่านแล้ว",
  txt_today: "วันนี้",
  txt_total_cholesterol: "โคเลสเตอรอลรวม (TC)",
  txt_trace: "เล็กน้อย",
  txt_transferred: "ส่งค่าการวัด",
  txt_trig: "ไตรกลีเซอไรด์",
  txt_type: "ประเภท",
  txt_uncheck: "ยังไม่อ่านข้อความ",
  txt_unchecked_only: "ยังไม่ได้อ่าน",
  txt_unconfirmed_report: "รายงานยังไม่ได้รับการตรวจสอบ",
  txt_unit_measure: "หน่วยการวัด",
  txt_units: "หน่วย",
  txt_upcoming_appointment: "คุณมีนัดหมายที่กำลังจะมาถึง",
  txt_uric_acid: "กรดยูริค",
  txt_urine_spot: "ปัสสาวะ (spot)",
  txt_urine_stick: "ปัสสาวะ (stick)",
  txt_uro: "Uro",
  txt_urobilinogen: "Urobilinogen",
  txt_val: "Val",
  txt_value: "ค่า",
  txt_vent_rate: "อัตราการเต้นของหัวใจห้องล่าง",
  txt_video: "วีดีโอ",
  txt_video_call: "การสนทนาทางวิดีโอ",
  txt_video_chat_stop: "หยุดการสนทนาทางวิดีโอ",
  txt_videocall_archives: "คลังเก็บการสนทนาทางวิดีโอ",
  txt_videos: "วิดีโอ",
  txt_weight: "น้ำหนัก",
  txt_with: "กับ",
};
