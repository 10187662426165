/* eslint-disable jsx-a11y/anchor-is-valid */
/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import { OTPublisher, OTSubscriber, createSession } from "opentok-react";
import $ from "jquery";
import "jquery-ui";
import * as _ from "lodash";
import { Common } from "../../lib/utils/common";
import moment from "moment";
import { alert, confirm } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import { setInterval } from "timers";
const OT = require("@opentok/client");
loadMessages(lang);
locale("en");
export const VideoCall: FunctionComponent<{}> = (props) => {
  const docotrDataProvider = new DocotrDataProvider();
  const [session, setSession] = useState("" as any);
  const [streams, setStreams] = useState([] as any);
  const [streamsList, setStreamsList] = useState([] as any);
  const [archiveId, setArchiveId] = useState("" as any);
  const [publishVideo, setPublishVideo] = useState(true);
  const [publishAudio, setPublishAudio] = useState(true);
  // const [apiKey, setApiKey] = useState("");
  const [sessionId, setSessionId] = useState("");
  // const [token, setToken] = useState("");

  const [timer, setTimer] = useState<number>(0);
  const [timer2, setTimer2] = useState<number>(0);
  const [timerStart, setTimerStart] = useState<NodeJS.Timeout | null>(null);
  const [chat, setChat] = useState([] as any);
  const [chatList, setChatList] = useState([] as any);
  const [name, setName] = useState([] as any);
  const [bname, setBname] = useState("" as any);
  const [sname, setSname] = useState("" as any);
  const [callSeq, setCallSeq] = useState<number>(0);
  const [height, setheight] = useState(true);
  /* componentDidMount && componentDidUpdate */
  const param: any = useParams();

  const common = new Common();

  useEffect(() => {
    const fetchArticles = async () => {
      let call;
      if (param.id.split("&").length > 1) {
        call = await docotrDataProvider.getVideoCallInfo(
          param.id.split("&")[0]
        );
        call = call.data;
        setCallSeq(param.id.split("&")[0]);
      } else {
        call = await docotrDataProvider.openCall(param.id);
        setCallSeq(call.callSeq);
      }
      common.ResizePage();
      setSessionId(call.sessionId);
      setName(
        param.id.split("&").length > 2 ? param.id.split("&")[2] : call.name
      );

      setSession(
        createSession({
          apiKey: call.apiKey,
          sessionId: call.sessionId,
          token: call.token,
          onStreamsUpdated: (streams: any) => {
            if (streams.length === 1) {
              $(".calling").css("display", "none");
              $("#ulThumb").removeClass("high");
              $("#ulThumb").addClass("low");
              chgLayout("type01");
              setheight(true);
            } else if (streams.length > 1) {
              $("#ulThumb").removeClass("low");
              $("#ulThumb").addClass("high");
              chgLayout("type04");
              setheight(false);
            }

            setBname(streams[0].name);
            setSname(streams[0].name);
            setStreams(streams);
          },
        })
      );
      setTimeout(() => {
        if (streamsList.length === 0) {
          common.openLayer("popVoice2");
        }
      }, 63000);
    };
    if (session === "") {
      fetchArticles();
    }
  }, [props]);

  const onEndClick = () => {
    let result = confirm(
      "<i>" + formatMessage("msg_want_end_call") + "</i>",
      "Confirm changes"
    );
    result.then((dialogResult) => {
      if (dialogResult) session.disconnect();
    });
  };
  const toggleVideo = () => {
    if (!publishVideo) {
      $("#toggleVideo").removeClass("disabled");
    } else {
      $("#toggleVideo").addClass("disabled");
    }

    setPublishVideo(!publishVideo);
  };

  const toggleAudio = () => {
    if (!publishAudio) {
      $("#toggleAudio").removeClass("disabled");
    } else {
      $("#toggleAudio").addClass("disabled");
    }

    setPublishAudio(!publishAudio);
  };
  const OnOffArchive = async () => {
    if ($("#spnRecord").text() === "Start Recording") {
      await docotrDataProvider.startArchive(sessionId).then((z: any) => {
        $("#divRec").css("display", "");
        $("#spnRecord").text("Stop Recording");
        startTimer();
        setArchiveId(z);
      });
    } else {
      const json = {
        archiveId: archiveId,
        callSeq: callSeq,
      };
      await docotrDataProvider.stopArchive(json).then((z: any) => {
        alert(formatMessage("msg_record_send"), "video");
        $("#divRec").css("display", "none");
        $("#spnRecord").text("Start Recording");
        pauseTimer();
        setArchiveId(z);
      });
    }
  };

  const chatText = async (e: any) => {
    setChat(e.target.value);
  };

  const publisherEventHandlers = {
    streamCreated: (event: any) => {
      fixDisplay();

      session.session.on("signal:chat", (d: any) => {
        if (_.isString(d.data)) {
          let dt = JSON.parse(d.data);

          dt["time"] = moment(new Date(), "s").format("HH:mm");
          chatList.push(dt);
          var isOn = $("#aChat").hasClass("on");
          if (isOn !== true) {
            $("#aChat").addClass("on");
            $("#secVideo").addClass("on");
          }
        } else {
          if (d.data.time === "X") {
            alert(formatMessage("txt_not_answer"), "video");
          } else {
            chatList.push(d.data);
          }
        }
      });
      setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);
    },
    streamDestroyed: (event: any) => {
      docotrDataProvider.endCall().then(async (d: any) => {
        window.close();
        window.open("about:blank", "_self")?.close();
      });
    },
  };
  const subscriberEventHandlers = {
    connected: (event: any) => {
      streamsList.push(event.target.stream.name);
      setTimeout(() => {
        chatList.push({
          name: event.target.stream.name,
          connectionId: "noti",
          date: moment().format("hh:mm"),
        });
      }, 2000);

      fixDisplay();
    },
    destroyed: (event: any) => {
      fixDisplay();
      const destroyedName = _.xor(_.map(streams, "name"), streamsList)[0];
      alert(destroyedName + " " + formatMessage("txt_left_the_room"), "video");
      chatList.push({
        name: destroyedName,
        connectionId: "out",
        date: moment().format("hh:mm"),
      });
    },
  };
  const startTimer = () => {
    const tick = () => setTimer2((prevTime) => prevTime + 1);
    const timerStart = setInterval(() => {
      tick();
    }, 1000);
    setTimerStart(timerStart);
  };
  const pauseTimer = () => {
    if (timerStart) {
      clearInterval(timerStart);
      setTimer2(0);
    }
  };
  const sendSignal = () => {
    const sendTxt = "" + chat;

    if (sendTxt.trim() !== "") {
      session.session.signal(
        {
          type: "chat",
          data: {
            sender: name,
            connectionId: "mine",
            msg: chat === null ? "" : chat,
            time: moment(new Date(), "s").format("HH:mm"),
          },
        },
        function (error: any) {
          if (error) {
          } else {
            $("#chatText").val("");
          }
        }
      );
    }
    setChat("");
  };
  const chageChat = () => {
    var isOn = $("#aChat").hasClass("on");
    if (isOn !== true) {
      $("#aChat").addClass("on");
      $("#secVideo").addClass("on");
    } else {
      $("#aChat").removeClass("on");
      $("#secVideo").removeClass("on");
    }
  };

  const fixDisplay = () => {
    const ht1: any = $("#video1").height();
    $("#video1").children("div").height(ht1);
    const ht2: any = $("#video2").height();
    $("#video2").children("div").height(ht2);
    const ht3: any = $("#video3").height();
    $("#video3").children("div").height(ht3);
    const ht4: any = $("#video4").height();
    $("#video4").children("div").height(ht4);
    const ht5: any = $("#video5").height();
    $("#video5").children("div").height(ht5);
    const ht6: any = $("#video6").height();
    $("#video6").children("div").height(ht6);
  };

  const chgLayout = (d: any) => {
    $("#secLayout").removeClass("type01 type02 type03 type04 type05 type06");
    $("#secLayout").addClass(d);
    if (d === "type01") {
      setBname(sname);
    } else {
      setBname("");
    }

    setTimeout(fixDisplay, 1000);
  };

  window.addEventListener("beforeunload", () => {
    alert("창을 닫습니다.", "Alert");
    docotrDataProvider.endCall();
  });
  window.addEventListener("unload", () => {
    docotrDataProvider.endCall();
  });
  return (
    <div className="comHeight">
      {session !== "" ? (
        <div className="wrap">
          <div className="video-call">
            <header className="header">
              <button type="button" className="menu-all">
                <span className="blind">전체메뉴</span>
                <div className="icon icon--menu">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>

              <div className="tit">
                <div className="name on">
                  <span>{$("#secLayout").hasClass("type01") ? bname : ""}</span>
                </div>

                <div className="count">
                  <i className="fa fa-users" aria-hidden="true"></i>
                  <span>3</span>
                </div>
              </div>

              <nav className="gnb">
                <ul>
                  <li>
                    <a onClick={OnOffArchive} style={{ cursor: "pointer" }}>
                      <i
                        className="fa fa-circle icon-rec"
                        aria-hidden="true"
                      ></i>
                      <span id="spnRecord">Start Recording</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => common.openLayer("popAddCall")}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-user-plus" aria-hidden="true"></i>
                      <span>Add to call</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => common.openLayer("popLayout")}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-th-large" aria-hidden="true"></i>
                      <span>Change Layout</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </header>

            <main id="content" className="video-call__wrap">
              <section id="secVideo" className="video-call__cont on">
                <section className="items">
                  <div className="controller">
                    <ul>
                      <li>
                        <a
                          id="toggleVideo"
                          className=""
                          onClick={toggleVideo}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fa fa-video-camera"
                            aria-hidden="true"
                          ></i>
                          <span className="blind">영상</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="toggleAudio"
                          className=""
                          onClick={toggleAudio}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fa fa-microphone"
                            aria-hidden="true"
                          ></i>
                          <span className="blind">마이크</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="aChat"
                          className="icon-chat on"
                          onClick={chageChat}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-comments" aria-hidden="true"></i>
                          <span className="blind">채팅</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id="divRec" className="rec" style={{ display: "none" }}>
                    <div className="rec-icon">
                      <span>REC</span>
                    </div>
                    <span className="rec-time">
                      {moment()
                        .hour(0)
                        .minute(0)
                        .second(timer2)
                        .format("mm:ss")}
                    </span>
                  </div>

                  <p id="pCalling" className="calling">
                    Calling...
                  </p>

                  <div className="call-info">
                    <div className="duration">
                      <span>
                        {moment()
                          .hour(0)
                          .minute(0)
                          .second(timer)
                          .format("mm:ss")}
                      </span>
                    </div>
                    <div className="endcall" style={{ cursor: "pointer" }}>
                      <a onClick={onEndClick}>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span className="blind">통화종료</span>
                      </a>
                    </div>
                  </div>
                </section>

                <section id="secLayout" className="layout type01">
                  <article className="block">
                    <header>{name}</header>
                    <div id="video1" className="video">
                      <OTPublisher
                        session={session.session}
                        eventHandlers={publisherEventHandlers}
                        properties={{
                          name: name,
                          publishVideo,
                          publishAudio,
                          width: "100%",
                          height: "100%",
                          style: {
                            archiveStatusDisplayMode: "off",
                            audioLevelDisplayMode: "off",
                            buttonDisplayMode: "off",
                            nameDisplayMode: "off",
                          },
                        }}
                      />
                    </div>
                  </article>
                  {streams.map((stream: any, i: any) => {
                    return (
                      <article className="block">
                        <header>{stream.name}</header>
                        <div id={"video" + (i + 2)} className="video">
                          <OTSubscriber
                            key={stream.id}
                            eventHandlers={subscriberEventHandlers}
                            session={session.session}
                            stream={stream}
                            properties={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </article>
                    );
                  })}
                </section>

                <section className="chat">
                  <header>
                    <strong>Chat</strong>
                    <span
                      className="icon icon--close lg chat-close"
                      onClick={chageChat}
                    ></span>
                  </header>
                  <div className="chat__msg">
                    {chatList.map((d: any, i: any) => {
                      let connectionId = "";
                      if (d["connectionId"] === "mine") {
                        connectionId = "send";
                      } else if (d["connectionId"] === "noti") {
                        connectionId = "noti";
                      } else if (d["connectionId"] === "out") {
                        connectionId = "noti";
                      } else {
                        connectionId = "receive";
                      }
                      if (d["connectionId"] === "noti") {
                        return (
                          <article className={connectionId}>
                            <p>
                              {d["date"] +
                                " " +
                                d["name"] +
                                " has entered the room."}
                            </p>
                          </article>
                        );
                      } else if (d["connectionId"] === "out") {
                        return (
                          <article className={connectionId}>
                            <p>
                              {d["date"] +
                                " " +
                                d["name"] +
                                " " +
                                formatMessage("txt_left_the_room")}
                            </p>
                          </article>
                        );
                      } else {
                        return (
                          <article className={connectionId}>
                            <header>
                              <span>{d["time"]}</span>
                              <strong> {d["sender"]}</strong>
                            </header>
                            <div className="box">
                              <p>{d["msg"]}</p>
                            </div>
                          </article>
                        );
                      }
                    })}
                  </div>
                  <footer>
                    <textarea
                      name="chatText"
                      id="chatText"
                      placeholder="input your text here"
                      onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                          sendSignal();
                        }
                      }}
                      onChange={chatText}
                      style={{
                        paddingTop: 0,
                      }}
                    ></textarea>
                    <label htmlFor="chatText" className="blind">
                      text input
                    </label>

                    <button
                      type="button"
                      className="btn md blue"
                      onClick={sendSignal}
                      disabled={("" + chat).trim() === "" ? true : false}
                    >
                      <i
                        className="fa fa-paper-plane icon"
                        aria-hidden="true"
                      ></i>
                      <span className="blind">메세지 전송</span>
                    </button>
                  </footer>
                </section>
              </section>
            </main>
          </div>
        </div>
      ) : null}

      <div className="pop-layer pop-layer--layout" id="popLayout">
        <section className="pop-layer__wrap">
          <div className="cont">
            <div className="cont__wrap">
              <ul id="ulThumb" className="layout-thumb low">
                <li>
                  <a
                    onClick={() => {
                      if (height) {
                        chgLayout("type01");
                      }
                    }}
                  >
                    <span className="blind">레이아웃1</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (height) {
                        chgLayout("type02");
                      }
                    }}
                  >
                    <span className="blind">레이아웃2</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (height) {
                        chgLayout("type03");
                      }
                    }}
                  >
                    <span className="blind">레이아웃3</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (!height) {
                        chgLayout("type04");
                      }
                    }}
                  >
                    <span className="blind">레이아웃4</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (!height) {
                        chgLayout("type05");
                      }
                    }}
                  >
                    <span className="blind">레이아웃5</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (!height) {
                        chgLayout("type06");
                      }
                    }}
                  >
                    <span className="blind">레이아웃6</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      <div className="pop-layer pop-layer--xsm" id="popVoice1">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>VoiceChat</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <p>Are you sure you want to end the call?</p>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <button type="button" className="btn md grey popClose">
                <span>Cancel</span>
              </button>
              <button type="button" className="btn md blue">
                <span>END CALL</span>
              </button>
            </div>
          </footer>
        </section>
      </div>

      <div className="pop-layer pop-layer--xsm" id="popVoice2">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>VoiceChat</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <p>Not answering your call</p>
              <p>Please, check and call again later.</p>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <button
                type="button"
                className="btn md blue popClose"
                onClick={() => {
                  docotrDataProvider.endCall().then(async (d: any) => {
                    window.close();
                    window.open("about:blank", "_self")?.close();
                  });
                }}
              >
                <span>OK</span>
              </button>
            </div>
          </footer>
        </section>
      </div>

      <div className="pop-layer pop-layer--xsm" id="popAddCall">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>Add to Call</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <p className="mb5">Invite Participants</p>
              <div className="tf">
                <label htmlFor="add" id="add" className="blind">
                  Invite Participants
                </label>
                <input
                  type="text"
                  name="add"
                  id="add"
                  value={
                    "https://" +
                    window.location.hostname +
                    "/VideoCall/" +
                    callSeq +
                    "&D&GUEST" +
                    streams.length
                  }
                  // value={
                  //   "http://localhost:5000" +
                  //   "/VideoCall/" +
                  //   callSeq +
                  //   "&D&GUEST" +
                  //   streams.length
                  // }
                  readOnly
                />
              </div>
            </div>
          </div>

          {/* <footer>
            <div className="btn-wrap">
              <button type="button" className="btn md grey">
                <span>Copy meeting url</span>
              </button>
            </div>
          </footer> */}
        </section>
      </div>
    </div>
  );
};

export default VideoCall;
