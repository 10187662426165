import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import "jquery-ui";
import { Common, webPush } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import { LoadPanel, SelectBox } from "devextreme-react";
import * as _ from "lodash";
import { IMainProps } from "./IMain";
import moment from "moment";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);

export const Main: FunctionComponent<IMainProps> = (props) => {
  locale("" + localStorage.getItem("lang"));
  const history = useHistory();
  const common = new Common();
  const [appoint, setAppoint] = useState([] as any);
  const [patient, setPatient] = useState([] as any);
  const [measure, setMeasure] = useState([] as any);
  const [panShow, setPanShow] = useState(true as any);
  const [assessment, setAssessment] = useState([] as any);
  const [message, setMessage] = useState([] as any);
  const [file, setFile] = useState([] as any);
  const [appointLen, setAppointLen] = useState(0 as any);
  const [patientLen, setPatientLen] = useState(0 as any);
  const [measureLen, setMeasureLen] = useState(0 as any);
  const [assessmentLen, setAssessmentLen] = useState(0 as any);
  const [messageLen, setMessageLen] = useState(0 as any);
  const [fileLen, setFileLen] = useState(0 as any);
  /* componentDidMount && componentDidUpdate */

  useEffect(() => {
    common.ResizePage();
    const doctorService = new DocotrDataProvider();
    const fetchArticles = async () => {
      const today = new Date();
      const webpush = new webPush();
      webpush.send().catch((err) => webpush.send());
      const appoint = await doctorService.GetAppointment(
        new Date(today.getFullYear(), today.getMonth(), 1),
        new Date(today.getFullYear(), today.getMonth() + 1, 0)
      );
      const main = await doctorService.GetMainCount();

      Promise.all([appoint, main]).then((d) => {
        setAppoint(appoint.data.data);
        setMeasure(main.data.measure);
        setAssessment(main.data.assessment);
        setMessage(main.data.message);
        setPatient(main.data.patient);
        setFile(main.data.files);
        setPanShow(false);
      });
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const movePatient = (e: any) => {
    history.push("/patient");
  };
  const moveMeasure = (e: any) => {
    history.push("/measurement");
  };
  const moveAppointment = (e: any) => {
    history.push("/appointment");
  };
  const moveAssessment = (e: any) => {
    history.push("/assessment");
  };
  const moveFiles = (e: any) => {
    history.push("/files");
  };
  const moveMessage = (e: any) => {
    history.push("/message");
  };

  const onDateSelectChanged = (e: any, n: any) => {
    switch (n) {
      case 1:
        setPatientLen(e.value);
        break;
      case 2:
        console.log(e.value);
        setMeasureLen(e.value);
        break;
      case 3:
        setAppointLen(e.value);
        break;
      case 4:
        setFileLen(e.value);
        break;
      case 5:
        setAssessmentLen(e.value);
        break;
      case 6:
        setMessageLen(e.value);
        break;
    }
  };
  const dateDt = [
    {
      name: formatMessage("txt_today"),
      value: 0,
    },
    {
      name: "1W",
      value: 7,
    },
    {
      name: "1M",
      value: 30,
    },
  ];

  return (
    <main id="content" className="content">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: ".wrap" }}
        visible={panShow}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <section className="main-items">
        <article className="item">
          <header>
            <i className="fa fa-user-o" aria-hidden="true"></i>
            <strong>{formatMessage("txt_patient")}</strong>
          </header>
          <section>
            <div className="opt">
              <label htmlFor="period1" className="blind">
                기간선택
              </label>

              <SelectBox
                id="period1"
                items={dateDt}
                defaultValue={0}
                displayExpr="name"
                valueExpr="value"
                placeholder=""
                onValueChanged={(e: any) => onDateSelectChanged(e, 1)}
              />
            </div>
            <div className="num" onClick={movePatient}>
              <i className="fa fa-check" aria-hidden="true"></i>
              <span id="span1">
                {
                  _.filter(patient, function (o) {
                    return (
                      moment(o.inputUtcDt).format("YYYY-MM-DD") >=
                      moment()
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .subtract(patientLen, "days")
                        .format("YYYY-MM-DD")
                    );
                  }).length
                }
              </span>
            </div>
          </section>
        </article>

        <article className="item">
          <header>
            <i className="fa fa-heart" aria-hidden="true"></i>
            <strong>{formatMessage("txt_measurement")}</strong>
          </header>
          <section>
            <div className="opt">
              <label htmlFor="period2" className="blind">
                기간선택
              </label>
              <SelectBox
                id="period2"
                items={dateDt}
                defaultValue={0}
                displayExpr="name"
                valueExpr="value"
                placeholder=""
                onValueChanged={(e: any) => onDateSelectChanged(e, 2)}
              />
            </div>
            <div className="num" onClick={moveMeasure}>
              <i className="fa fa-check" aria-hidden="true"></i>
              <span className="bold">
                {" "}
                {
                  _.filter(measure, function (o) {
                    return (
                      moment(o.inputUtcDt).valueOf() >=
                        moment()
                          .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                          .subtract(measureLen, "days")
                          .valueOf() && o.normalYn === "N"
                    );
                  }).length
                }
              </span>
              <span>/</span>
              <span id="span2">
                {
                  _.filter(measure, function (o) {
                    return (
                      moment(o.inputUtcDt).valueOf() >=
                      moment()
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .subtract(measureLen, "days")
                        .valueOf()
                    );
                  }).length
                }
              </span>
            </div>
          </section>
        </article>

        <article className="item">
          <header>
            <i className="fa fa-calendar" aria-hidden="true"></i>
            <strong>{formatMessage("txt_appointment")}</strong>
          </header>
          <section>
            <div className="opt">
              <label htmlFor="period3" className="blind">
                기간선택
              </label>
              <SelectBox
                id="period3"
                items={dateDt}
                defaultValue={0}
                displayExpr="name"
                valueExpr="value"
                placeholder=""
                onValueChanged={(e: any) => onDateSelectChanged(e, 3)}
              />
            </div>
            <div className="num" onClick={moveAppointment}>
              <i className="fa fa-check" aria-hidden="true"></i>
              <span id="span3">
                {
                  _.filter(appoint, function (o) {
                    return (
                      moment(o["appoint_dt"]).format("YYYY-MM-DD") >=
                      moment()
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .subtract(appointLen, "days")
                        .format("YYYY-MM-DD")
                    );
                  }).length
                }
              </span>
            </div>
          </section>
        </article>

        <article className="item">
          <header>
            <i className="fa fa-picture-o" aria-hidden="true"></i>
            <strong>{formatMessage("mnu_files")}</strong>
          </header>
          <section>
            <div className="opt">
              <label htmlFor="period4" className="blind">
                기간선택
              </label>
              <SelectBox
                id="period4"
                items={dateDt}
                defaultValue={0}
                displayExpr="name"
                valueExpr="value"
                placeholder=""
                onValueChanged={(e: any) => onDateSelectChanged(e, 4)}
              />
            </div>
            <div className="num" onClick={moveFiles}>
              <i className="fa fa-check" aria-hidden="true"></i>
              <span id="span4">
                {
                  _.filter(file, function (o) {
                    return (
                      moment(
                        o["video_consultation_archives_input_utc_dt"]
                      ).format("YYYY-MM-DD") >=
                      moment()
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .subtract(fileLen, "days")
                        .format("YYYY-MM-DD")
                    );
                  }).length
                }
              </span>
            </div>
          </section>
        </article>

        <article className="item">
          <header>
            <i className="fa fa-list-alt" aria-hidden="true"></i>
            <strong>{formatMessage("txt_assessment")}</strong>
          </header>
          <section>
            <div className="opt">
              <label htmlFor="period5" className="blind">
                기간선택
              </label>
              <SelectBox
                id="period5"
                items={dateDt}
                defaultValue={0}
                displayExpr="name"
                valueExpr="value"
                placeholder=""
                onValueChanged={(e: any) => onDateSelectChanged(e, 5)}
              />
            </div>
            <div className="num" onClick={moveAssessment}>
              <i className="fa fa-check" aria-hidden="true"></i>
              <span id="span5">
                {" "}
                {
                  _.filter(assessment, function (o) {
                    return (
                      moment(o.inputUtcDt).format("YYYY-MM-DD") >=
                      moment()
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .subtract(assessmentLen, "days")
                        .format("YYYY-MM-DD")
                    );
                  }).length
                }
              </span>
            </div>
          </section>
        </article>

        <article className="item">
          <header>
            <i className="fa fa-comments-o" aria-hidden="true"></i>
            <strong>{formatMessage("txt_message")}</strong>
          </header>
          <section>
            <div className="opt">
              <label htmlFor="period6" className="blind">
                기간선택
              </label>
              <SelectBox
                id="period6"
                items={dateDt}
                defaultValue={0}
                displayExpr="name"
                valueExpr="value"
                placeholder=""
                onValueChanged={(e: any) => onDateSelectChanged(e, 6)}
              />
            </div>
            <div className="num" onClick={moveMessage}>
              <i className="fa fa-check" aria-hidden="true"></i>
              <span id="span6">
                {
                  _.filter(message, function (o) {
                    return (
                      moment(o.inputUtcDt).format("YYYY-MM-DD") >=
                      moment()
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .subtract(messageLen, "days")
                        .format("YYYY-MM-DD")
                    );
                  }).length
                }
              </span>
            </div>
          </section>
        </article>
      </section>
    </main>
  );
};

export default Main;
