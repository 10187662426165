/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import "jquery-ui/themes/base/jquery-ui.css";
import "../../css/font-awesome.min.css";
import "../../css/layout.css";
import { IMessageProps } from "./IMessage";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import {
  Button,
  CheckBox,
  DateBox,
  LoadPanel,
  RadioGroup,
  SelectBox,
  TextArea,
} from "devextreme-react";
import * as _ from "lodash";
import moment from "moment";
import { alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import cBox from "devextreme/ui/check_box";
import rGroup from "devextreme/ui/radio_group";
loadMessages(lang);
locale("en");
export const Message: FunctionComponent<IMessageProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [gridList, setGridList] = useState([] as any);
  const [gridListClone, setGridListClone] = useState([] as any);
  const [gridFilterValue, setGridFilterValue] = useState<any>([]);
  const [totalCnt, setTotalCnt] = useState(String);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [name, setName] = useState(String);
  const [msg, setMsg] = useState(String);
  const [msgInsungSeq, setMsgInsungSeq] = useState(String);
  const [msgList, setMsgList] = useState([] as any);
  const [nameList, setNameList] = useState([] as any);
  const [radio, setRadio] = useState("All" as any);
  const [panShow, setPanShow] = useState(true as any);
  /* componentDidMount && componentDidUpdate */

  const doctorService = new DocotrDataProvider();
  useEffect(() => {
    common.ResizePage();

    $(".dx-toolbar").css("display", "none");
    $(".tap").removeClass("on");
    $("#pageMessage").addClass("on");

    const fetchArticles = async () => {
      const gridDt = await doctorService.GetMessageList();
      console.log(gridDt.data.data);
      //데이터 초기화
      setNameList(_.uniqBy(gridDt.data.data, "name"));
      setGridList(gridDt.data.data);
      setGridListClone(gridDt.data.data);
      setMsgList(gridDt.data.data);
      setTotalCnt(gridDt.data.totalCount);
      setPanShow(false);
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  const showColumn = async () => {
    $(".dx-datagrid-column-chooser-button").click();
  };

  const onSelectChanged = async (e: any) => {
    if (e.value === "1W") {
      let cDate = new Date();
      cDate.setDate(cDate.getDate() - 7);
      setStartDate(cDate);
      setEndDate(new Date());
    } else if (e.value === "1M") {
      let cDate = new Date();
      var monthDate = new Date(cDate.setMonth(cDate.getMonth() - 1));
      setStartDate(monthDate);
      setEndDate(new Date());
    } else if (e.value === "Today") {
      setStartDate(new Date());
      setEndDate(new Date());
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };
  const onNameChanged = async (e: any) => {
    setName(e.value);
  };
  const onMsgNameChanged = async (e: any) => {
    setMsgList(_.filter(gridList, { insungSeq: e.value }));
    setMsgInsungSeq(e.value);
  };
  const onSearch = async (e: any) => {
    let filter: any;
    filter = [];
    filter.push("and");

    if (!common.valueChk(startDate) && common.valueChk(endDate)) {
      alert(formatMessage("msg_select_start_end_date"), "alert");
      return false;
    }

    if (common.valueChk(startDate) && !common.valueChk(endDate)) {
      alert(formatMessage("msg_select_start_end_date"), "alert");
      return false;
    }

    if (!common.valueChk(startDate) || !common.valueChk(endDate)) {
      setGridList(gridListClone);
    } else if (common.valueChk(startDate) && common.valueChk(endDate)) {
      let sValue = moment(startDate).format("YYYY-MM-DD 00:00:00");
      let eValue = moment(endDate).format("YYYY-MM-DD 23:59:59");
      const a = _.filter(gridListClone, function (dt) {
        return _.inRange(
          moment(dt.inputUtcDt).valueOf(),
          moment(sValue).valueOf(),
          moment(eValue).valueOf()
        );
      });
      setGridList(a);
    } else {
      setGridList(gridListClone);
    }

    if (common.valueChk(name)) {
      filter.push(
        common.valueChk(name) ? ["name", "contains", name] : ["name", "<>", ""]
      );
    } else {
      filter = [];
    }

    filter.push("and");
    if (e === "Uncheck") {
      filter.push(["check", "contains", radio === "All" ? "" : radio]);
    }
    filter.push(["senderFlag", "contains", radio === "All" ? "" : radio]);
    setGridFilterValue(filter);
  };
  const onRadio = async (e: any) => {
    let filter: any;
    filter = [];
    filter.push("and");

    if (_dev("cBoxUnCheck", cBox).option("value")) {
      filter.push(["check", "contains", "Uncheck"]);
      filter.push(["senderFlag", "contains", "D"]);
    } else {
      filter.push(["senderFlag", "contains", e === "All" ? "" : e]);
    }

    setGridFilterValue(filter);
  };
  const onSDateChanged = async (e: any) => {
    setStartDate(e.value);
  };
  const onEDateChanged = async (e: any) => {
    setEndDate(e.value);
  };
  const onFilterValue = async (e: any) => {
    setRadio($('input[name="msg"]:checked').val());
    onRadio($('input[name="msg"]:checked').val());
  };

  const onMsgChanged = async (e: any) => {
    setMsg(e.value);
  };
  const onSend = async (e: any) => {
    if (msgInsungSeq !== "") {
      const json = {
        message: msg,
        insungSeq: msgInsungSeq,
        doctorSeq: 28,
        senderFlag: "D",
      };
      doctorService.addMessage(json).then(async (d: any) => {
        const gridDt = await doctorService.GetMessageList();
        $(".popClose").trigger("click");
        setGridList(gridDt.data.data);
      });
    } else {
      alert(formatMessage("msg_select_patient"), "alert");
    }
  };
  const onPopupMsgFilter = async (e: any) => {
    let filter: any;
    const rdgVal = _dev("rdgMsg", rGroup).option("value");
    const chkVal = _dev("cBoxUnCheck2", cBox).option("value");

    if (rdgVal === "All") {
      filter = {};
    } else {
      filter = {
        senderFlag: rdgVal,
      };
    }
    if (msgInsungSeq !== "") {
      filter["insungSeq"] = msgInsungSeq;
    }

    if (chkVal) {
      filter["check"] = "Uncheck";
    }
    setMsgList(_.filter(gridList, filter));
  };

  return (
    <main id="content" className="content">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: ".wrap" }}
        visible={panShow}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <section className="sub-top">
        <header>
          <div className="tit-area">
            <i className="fa fa-comments-o icon-title" aria-hidden="true"></i>
            <div className="tit">
              <h2>{formatMessage("txt_message")}</h2>
              <span className="num">({totalCnt})</span>
              <button
                type="button"
                onClick={() => {
                  _dev("cBoxUnCheck2", cBox).option("value", false);
                  setMsg("");
                  setMsgList(gridList);
                  setMsgInsungSeq("");
                  common.openLayer("popMsg");
                }}
              >
                <i className="fa fa-pencil icon-link" aria-hidden="true"></i>
                <span className="blind">phone settings</span>
              </button>
            </div>
          </div>
        </header>

        <article className="search-form">
          <form action="" name="searchForm">
            <fieldset>
              <legend className="blind">검색폼</legend>
              <div className="form-item">
                <div className="opt short">
                  <label htmlFor="selDate" className="blind">
                    기간선택
                  </label>
                  <SelectBox
                    items={["All", "Today", "1W", "1M"]}
                    defaultValue={"All"}
                    onValueChanged={onSelectChanged}
                  />
                </div>
                <div className="form-wrap date-item">
                  <DateBox
                    value={startDate}
                    max={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onSDateChanged}
                  />
                  <span className="hyphen"> ~ </span>
                  <DateBox
                    value={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onEDateChanged}
                  />
                </div>
              </div>
              <div className="form-item mt10">
                <div className="opt">
                  <label htmlFor="selGraph" className="blind">
                    검색어
                  </label>
                  <SelectBox
                    dataSource={_.orderBy(
                      nameList,
                      [(e) => e.name.toLowerCase()],
                      ["asc"]
                    )}
                    placeholder="Name"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"name"}
                    displayExpr="name"
                    valueExpr="name"
                    onValueChanged={onNameChanged}
                  />
                </div>
                <button
                  type="button"
                  className="btn md white"
                  onClick={onSearch}
                >
                  <i className="fa fa-search icon" aria-hidden="true"></i>
                  <span className="blind">검색</span>
                </button>
              </div>
            </fieldset>
          </form>
        </article>
      </section>
      <section className="sub-cont mt30">
        <header>
          <button
            type="button"
            id="btnSave"
            className="btn sm blue flex-mla"
            onClick={showColumn}
          >
            <span>{formatMessage("btn_displayl")}</span>
          </button>
        </header>

        <div className="sort form-item">
          <div className="inp">
            <input
              type="radio"
              name="msg"
              value="All"
              id="All"
              onClick={onFilterValue}
              defaultChecked={true}
            />
            <label htmlFor="All">All</label>
          </div>
          <div className="inp">
            <input
              type="radio"
              name="msg"
              id="Received"
              value="P"
              onClick={onFilterValue}
            />
            <label htmlFor="Received">Received</label>
            <i className="fa fa-envelope-open-o" aria-hidden="true"></i>
          </div>
          <div className="inp">
            <input
              type="radio"
              name="msg"
              id="Sent"
              value="D"
              onClick={onFilterValue}
            />
            <label htmlFor="Sent">Sent</label>
            <i className="fa fa-envelope-o" aria-hidden="true"></i>
          </div>
          <div style={{ float: "left", marginLeft: "68%" }}>
            <CheckBox
              defaultValue={false}
              id="cBoxUnCheck"
              text="Unchecked Only"
              onValueChanged={onFilterValue}
            />
          </div>
        </div>

        <div className="tbl-wrap">
          <DataGrid
            dataSource={gridList}
            filterValue={gridFilterValue}
            noDataText="No data"
            columnMinWidth={50}
            wordWrapEnabled={false}
            cellHintEnabled={true}
            columnAutoWidth={true}
            showBorders={true}
            className="type-ct"
          >
            <ColumnChooser enabled={true} mode="select" />
            <Column
              caption="No"
              cellRender={(e) => {
                return (
                  e.component.pageIndex() * e.component.pageSize() +
                  e.row.rowIndex +
                  1
                );
              }}
            />
            <Column
              dataField="senderFlag"
              caption="Status"
              cellTemplate={(container: any, options: any) => {
                $("<span>")
                  .append(
                    $("<i>", {
                      class:
                        options.value === "D"
                          ? "fa fa-paper-plane"
                          : "fa fa-envelope",
                    })
                  )
                  .appendTo(container);
              }}
            />
            <Column
              dataField="name"
              caption={formatMessage("txt_name")}
              cellTemplate={(container: any, options: any) => {
                $(
                  '<a href="/measurement?insungSeq=' +
                    options.data.insungSeq +
                    '"class="link">'
                )
                  .append(options.value)
                  .appendTo(container);
              }}
            />
            <Column dataField="gender" caption={formatMessage("txt_sex")} />
            <Column dataField="age" caption={formatMessage("txt_age")} />
            <Column
              dataField="inputUtcDt"
              width={200}
              caption={formatMessage("txt_date")}
              cellRender={(e: any) => {
                return moment(e.value)
                  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format(
                    localStorage.getItem("lang") === "ko"
                      ? "YYYY-MM-DD"
                      : "L HH:mm:ss"
                  );
              }}
            />
            <Column
              dataField="message"
              caption="Detail"
              width={400}
              cellTemplate={(container: any, options: any) => {
                const chkYn =
                  options.data.senderFlag !== "D" &&
                  options.data.check === "Uncheck";
                $("<span>")
                  .on("click", () => {
                    if (chkYn) {
                      const json = {
                        messageSeq: options.data.messageSeq,
                      };

                      doctorService.updateMessage(json).then(async (d: any) => {
                        const gridDt = await doctorService.GetMessageList();
                        setGridList(gridDt.data.data);
                      });
                    }
                  })
                  .append(
                    chkYn
                      ? "<b style='cursor:pointer'>" + options.value + "</b>"
                      : options.value
                  )
                  .appendTo(container);
              }}
            />
            <Column
              dataField="check"
              caption={formatMessage("txt_title_check")}
              cellTemplate={(container: any, options: any) => {
                $("<span>")
                  .append(options.data.senderFlag === "D" ? options.value : "-")
                  .appendTo(container);
              }}
            />
            <Column
              dataField="message"
              caption={formatMessage("txt_message")}
              cellTemplate={(container: any, options: any) => {
                $("<a style='cursor: pointer' class='link'>")
                  .on("click", () => {
                    _dev("cBoxUnCheck2", cBox).option("value", false);
                    setMsg("");
                    setMsgList(_.filter(gridList, { name: options.data.name }));
                    setMsgInsungSeq(options.data.insungSeq);
                    common.openLayer("popMsg");
                  })
                  .append($("<i>", { class: "fa fa-pencil" }))
                  .appendTo(container);
              }}
            />
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 25, 50, 100]}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        </div>
      </section>
      <div className="pop-layer pop-layer--sm" id="popMsg">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>{formatMessage("txt_message")}</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <article>
                <div className="msg-top">
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <SelectBox
                    dataSource={_.orderBy(
                      nameList,
                      [(e) => e.name.toLowerCase()],
                      ["asc"]
                    )}
                    placeholder="Name"
                    searchEnabled={true}
                    searchMode={"contains"}
                    searchExpr={"name"}
                    displayExpr="name"
                    valueExpr="insungSeq"
                    value={msgInsungSeq}
                    onValueChanged={onMsgNameChanged}
                  />
                  {/* <Button
                    className="btn md blue flex-mla"
                    text="Rewrite"
                    type="success"
                    stylingMode="contained"
                  /> */}
                </div>
                <TextArea
                  height={90}
                  showClearButton={true}
                  value={msg}
                  onValueChanged={onMsgChanged}
                />
                <div className="btn-wrap mt20">
                  <Button
                    className="btn mlg blue"
                    text="Send"
                    type="success"
                    stylingMode="contained"
                    onClick={onSend}
                  />
                  <Button
                    className="btn mlg grey"
                    text="Cancel"
                    type="default"
                    stylingMode="contained"
                    onClick={() => {
                      _dev("cBoxUnCheck2", cBox).option("value", false);
                      $(".popClose").trigger("click");
                    }}
                  />
                </div>
              </article>

              <article className="mt30">
                <h4>
                  <strong>{formatMessage("txt_message_list")}</strong>
                </h4>
                <div className="sort form-item">
                  <RadioGroup
                    items={[
                      { name: "All", value: "All" },
                      { name: "Received", value: "P" },
                      { name: "Sent", value: "D" },
                    ]}
                    id="rdgMsg"
                    defaultValue={"All"}
                    displayExpr="name"
                    valueExpr="value"
                    layout="horizontal"
                    onValueChanged={onPopupMsgFilter}
                  />

                  <div style={{ float: "left", marginLeft: "30%" }}>
                    <CheckBox
                      defaultValue={false}
                      id="cBoxUnCheck2"
                      text="Unchecked Only"
                      onValueChanged={onPopupMsgFilter}
                    />
                  </div>
                </div>
                <div className="tbl-wrap">
                  <DataGrid
                    dataSource={msgList}
                    noDataText="No data"
                    className="type-ct"
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    wordWrapEnabled={false}
                    cellHintEnabled={true}
                  >
                    <Column
                      caption="No"
                      width={45}
                      cellRender={(e) => {
                        return (
                          e.component.pageIndex() * e.component.pageSize() +
                          e.row.rowIndex +
                          1
                        );
                      }}
                    />
                    <Column
                      dataField="senderFlag"
                      caption={formatMessage("txt_status")}
                      cellTemplate={(container: any, options: any) => {
                        $("<span>")
                          .append(
                            $("<i>", {
                              class:
                                options.value === "D"
                                  ? "fa fa-paper-plane"
                                  : "fa fa-envelope",
                            })
                          )
                          .appendTo(container);
                      }}
                    />
                    <Column
                      dataField="inputUtcDt"
                      caption={formatMessage("txt_date")}
                      cellRender={(e: any) => {
                        return moment(e.value)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format(
                            localStorage.getItem("lang") === "ko"
                              ? "YYYY-MM-DD"
                              : "L"
                          );
                      }}
                    />
                    <Column dataField="name" caption="Recipents" />
                    <Column
                      dataField="message"
                      width={100}
                      caption={formatMessage("txt_detail")}
                    />
                    <Column
                      dataField="check"
                      caption={formatMessage("txt_title_check")}
                      width={110}
                      cellTemplate={(container: any, options: any) => {
                        $("<span>")
                          .append(
                            options.data.senderFlag === "D"
                              ? options.value
                              : "-"
                          )
                          .appendTo(container);
                      }}
                    />
                    <Paging defaultPageSize={10} />
                  </DataGrid>
                </div>
              </article>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Message;
