import moment from "moment";
import "dotenv/config";

const server =
  window.location.hostname === "doc.hicare.net"
    ? "https://api.hicare.net"
    : window.location.hostname === "dev.doc.hicare.net"
    ? "https://dev.api.hicare.net"
    : "http://localhost:3000";
//const server = "http://localhost:3000";

export class DocotrDataProvider {
  public async alertVisibleSetting(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/setting", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();

    return data;
  }

  public async addAlert(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/alert", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addSubscribeInfo(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/addSubscribeInfo", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async updateSubscribeInfo(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/updateSubscribeInfo", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }

  public async chagePassword(newPwd: any): Promise<any> {
    const resp = await fetch(server + "/doctor/changePwd", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({
        newPwd: newPwd,
      }),
    });

    const data = await resp.json();
    return data;
  }
  public async loginDoctor(id: any, pwd: any): Promise<any> {
    const resp = await fetch(server + "/doctor/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginId: id,
        password: pwd,
      }),
    });

    const data = await resp.json();
    return data;
  }

  public async logoutDoctor(): Promise<any> {
    const resp = await fetch(server + "/doctor/logout", {
      method: "PUT",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();

    return data;
  }
  public async endCall(): Promise<any> {
    const resp = await fetch(server + "/videocall/endcall", {
      method: "PUT",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });
    const data = await resp.json();
    return data;
  }

  public async getVideoCallInfo(callSeq: any): Promise<any> {
    const resp = await fetch(server + "/videocall/doctor/" + callSeq, {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();

    return data;
  }

  public async GetVideoList(): Promise<any> {
    const resp = await fetch(server + "/doctor/getVideoList", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();

    return data;
  }
  public async GetAlertList(): Promise<any> {
    const resp = await fetch(server + "/doctor/getAlertList", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();

    return data;
  }
  public async openCall(insungSeq: any): Promise<any> {
    const resp = await fetch(server + "/videocall/openCall/" + insungSeq, {
      method: "GET",
    });

    const data = await resp.json();
    return data;
  }

  public async startArchive(sessionId: string): Promise<any> {
    const resp = await fetch(server + "/videocall/startArchive/" + sessionId, {
      method: "GET",
    });

    const data = await resp.json();
    return data;
  }

  public async stopArchive(body: any): Promise<any> {
    const resp = await fetch(server + "/videocall/stopArchive/", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async getArchive(archiveId: string): Promise<any> {
    const resp = await fetch(server + "/videocall/getArchive/" + archiveId, {
      method: "GET",
    });

    const data = await resp.json();
    return data;
  }
  public async GetAppointment(fromDt: any, toDt: any): Promise<any> {
    const resp = await fetch(
      server +
        "/doctor/getAppointment?fromDt=" +
        moment(fromDt).format("YYYY-MM-DD").toString() +
        "&toDt=" +
        moment(toDt).format("YYYY-MM-DD").toString(),
      {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      }
    );

    const data = await resp.json();
    return data;
  }

  public async GetSubscribeInfo(): Promise<any> {
    const resp = await fetch(server + "/doctor/getSubscribeInfo", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }
  public async GetMessageList(): Promise<any> {
    const resp = await fetch(server + "/doctor/getMessageList", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetAssessmentList(): Promise<any> {
    const resp = await fetch(server + "/doctor/getAssessmentList", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }
  public async GetPatientMeasureList(): Promise<any> {
    const resp = await fetch(server + "/doctor/getPatientMeasureList", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMainCount(): Promise<any> {
    const resp = await fetch(server + "/doctor/getMainCount", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }
  public async GetPatientList(): Promise<any> {
    const resp = await fetch(server + "/doctor/getPatientList", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }
  public async addAppointment(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/addAppointment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }

  public async addMessage(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/addMessage", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }

  public async updateMessage(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/updateMessage", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async deleteAppointment(seq: any): Promise<any> {
    const resp = await fetch(server + "/doctor/deleteAppointment/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }

  public async updateAppointment(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/updateAppointment", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }

  public async addPatientContact(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/addPatientContact", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async updateDocUnits(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/updateDocUnits", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }

  public async GetDoctors(): Promise<any> {
    const resp = await fetch(server + "/doctor/getDoctors", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }
  public async GetMeasureMicroscopeInfo(seq: any): Promise<any> {
    const resp = await fetch(server + "/doctor/measure/microscope/" + seq, {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMeasureStethoscopeInfo(seq: any): Promise<any> {
    const resp = await fetch(server + "/doctor/measure/stethoscope/" + seq, {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMeasureCapnographyInfo(seq: any): Promise<any> {
    const resp = await fetch(server + "/doctor/measure/capnography/" + seq, {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMeasureEcgInfo(seq: any): Promise<any> {
    const resp = await fetch(server + "/doctor/measure/ecg/" + seq, {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMeasureEcgCardipiaInfo(seq: any): Promise<any> {
    const resp = await fetch(server + "/doctor/measure/ecg/cardipia/" + seq, {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMeasureEcgGraphInfo(seq: any): Promise<any> {
    const resp = await fetch(server + "/doctor/measure/ecg/graph/" + seq, {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMeasureEcgCardipiaGraphInfo(seq: any): Promise<any> {
    const resp = await fetch(
      server + "/doctor/measure/ecg/cardipia/graph/" + seq,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      }
    );

    const data = await resp.json();
    return data;
  }

  public async GetPatientMeasureTable(
    insungSeq: any,
    measureType: any,
    deviceCode: any,
    startDate: any,
    endDate: any
  ): Promise<any> {
    const resp = await fetch(
      server +
        "/doctor/patient/" +
        insungSeq +
        "/measure/table2?measureType=" +
        measureType +
        "&deviceCode=" +
        deviceCode +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      }
    );

    const data = await resp.json();
    return data;
  }

  public async GetPatientSpirometerAsmaGraph(
    insungSeq: any,
    startDate: any,
    endDate: any
  ): Promise<any> {
    const resp = await fetch(
      server +
        "/doctor/measure/asma?insungSeq=" +
        insungSeq +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      }
    );

    const data = await resp.json();
    return data;
  }

  public async GetPatientSpirometerCopd6Graph(
    insungSeq: any,
    startDate: any,
    endDate: any
  ): Promise<any> {
    const resp = await fetch(
      server +
        "/doctor/measure/copd6?insungSeq=" +
        insungSeq +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      }
    );

    const data = await resp.json();
    return data;
  }

  public async GetPatientSpirometerLungMonitorGraph(
    insungSeq: any,
    startDate: any,
    endDate: any
  ): Promise<any> {
    const resp = await fetch(
      server + "/doctor/measure/lung-monitor?insungSeq=" + insungSeq,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      }
    );

    const data = await resp.json();
    return data;
  }

  public async GetPatientMeasureGraph(
    insungSeq: any,
    measureType: any,
    deviceCode: any,
    startDate: any,
    endDate: any
  ): Promise<any> {
    const resp = await fetch(
      server +
        "/doctor/patient/" +
        insungSeq +
        "/measure/graph?measureType=" +
        measureType +
        "&deviceCode=" +
        deviceCode +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      }
    );

    const data = await resp.json();

    return data;
  }

  public async GetPatientMeasureGraph2(
    insungSeq: any,
    measureType: any,
    deviceCode: any,
    startDate: any,
    endDate: any
  ): Promise<any> {
    const resp = await fetch(
      server +
        "/doctor/patient/" +
        insungSeq +
        "/measure/graph2?measureType=" +
        measureType +
        "&deviceCode=" +
        deviceCode +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      }
    );
    const data = await resp.json();
    return data;
  }

  public async GetMeasurePhotoContentInfo(fileName: any): Promise<any> {
    const resp = await fetch(server + "/doctor/image?fileName=" + fileName, {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async updatePatientMonitor(body: any): Promise<any> {
    const resp = await fetch(server + "/doctor/updatePatientMonitor", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
}
