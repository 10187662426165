/* eslint-disable react/jsx-no-comment-textnodes */
/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { IAppointmentProps, tData } from "./IAppointment";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DocotrDataProvider } from "../../lib/service/DocotrDataProvider";
import {
  Button,
  Calendar,
  DateBox,
  LoadPanel,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import * as _ from "lodash";
import moment from "moment";
import { useInterval } from "react-use";
import { confirm, alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);

export const Appointment: FunctionComponent<IAppointmentProps> = (props) => {
  locale("" + localStorage.getItem("lang"));

  const history = useHistory();
  const common = new Common();
  const [dtList, setDtList] = useState([] as any);
  const [dtListAll, setDtListAll] = useState([] as any);
  const [patientRev, setPatientRev] = useState([] as any);

  const [calDate, setCalDate] = useState<Date | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [resDate, setResDate] = useState<Date | undefined>(undefined);
  const [startDate2, setStartDate2] = useState(String as any);
  const [endDate2, setEndDate2] = useState(String as any);

  const [timeValue, setTimeValue] = useState(String as any);
  const [resNameList, setResNameList] = useState([]);
  const [resName, setResName] = useState(String as any);
  const [title, setTitle] = useState(String as any);
  const [agenda, setAgenda] = useState("" as any);
  const [timeData, setTimeData] = useState([] as any);
  const [newWrite, setNewWrite] = useState(false);
  const [appoint, setAppoint] = useState(String as any);
  const [panShow, setPanShow] = useState(true as any);
  const [realTime, setRealTime] = useState(Date.now());
  /* componentDidMount && componentDidUpdate */
  const doctorService = new DocotrDataProvider();
  useInterval(() => {
    setRealTime(Date.now());
  }, 1000);

  useEffect(() => {
    const fetchArticles = async () => {
      setStartDate(new Date());
      setEndDate(new Date());
      setResDate(new Date());
      setCalDate(new Date());
      setTimeData(tData);
      const today = new Date();

      const gridDt = await doctorService.GetAppointment(new Date(), new Date());
      const gridDtAll = await doctorService.GetAppointment(
        new Date(today.getFullYear(), today.getMonth(), 1),
        new Date(today.getFullYear(), today.getMonth() + 1, 0)
      );
      const name = await doctorService.GetPatientList();
      const rev = _.filter(gridDt.data.data, {
        confirm_yn: "N",
      });
      $(".tap").removeClass("on");
      $("#pageAppointment").addClass("on");
      console.log(name.data.data);
      //데이터 초기화
      setResNameList(name.data.data);
      setDtListAll(gridDtAll.data.data);
      setDtList(gridDt.data.data);
      setPatientRev(rev);

      $("#liWait").removeClass("new");
      $("#liWait").addClass(rev.length > 0 ? "new" : "");

      common.ResizePage();
      setPanShow(false);
    };
    if (common.accountChk()) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const onDateSelectChanged = async (e: any) => {
    let cDate = new Date();
    setEndDate(new Date());
    if (e.value === "1W") {
      cDate.setDate(cDate.getDate() - 7);
      setStartDate(cDate);
    } else if (e.value === "1M") {
      var monthDate = new Date(cDate.setMonth(cDate.getMonth() - 1));
      setStartDate(monthDate);
    } else {
      setStartDate(cDate);
    }
  };
  const onSearch = async () => {
    updateData();
  };
  const onSDateChanged = async (e: any) => {
    setStartDate(e.value);
  };
  const onEDateChanged = async (e: any) => {
    setEndDate(e.value);
  };
  const fixTime = (e: any, d: any) => {
    const isOn = $(e.currentTarget).hasClass("on");

    let dt = d;
    let et;
    let st;

    if (endDate2 === "") {
      et = moment(d).add(20, "minutes");
    } else if (startDate2.format("HH:mm") === dt.format("HH:mm")) {
      et = endDate2;
    } else if (moment(d).add(20, "minutes") < endDate2) {
      et = endDate2;
    } else if (
      endDate2.format("HH:mm") === moment(d).add(20, "minutes").format("HH:mm")
    ) {
      et = dt;
    } else {
      et = moment(d).add(20, "minutes");
    }

    if (startDate2 === "") {
      st = dt;
    } else if (startDate2.format("HH:mm") === dt.format("HH:mm")) {
      st = moment(dt).add(20, "minutes");
    } else if (moment(d) < startDate2) {
      st = dt;
    } else {
      st = startDate2;
    }

    let diff = moment.duration(et.diff(endDate2)).asMinutes();

    if (
      diff >= 40 ||
      timeValue === st.format("HH:mm") + " ~ " + et.format("HH:mm")
    ) {
      alert(
        "You can only select the time available for consultation. Times can be selected for 20 minutes each. To extend the time, select a continuous time.",
        "Error"
      );
    } else {
      if (isOn) {
        $(e.currentTarget).removeClass("on");
      } else {
        $(e.currentTarget).addClass("on");
      }
      if (st.format("HH:mm") === et.format("HH:mm")) {
        setStartDate2("");
        setEndDate2("");
        setTimeValue("");
      } else {
        setStartDate2(st);
        setEndDate2(et);
        setTimeValue(st.format("HH:mm") + " ~ " + et.format("HH:mm"));
      }
    }
  };
  const onResNameChanged = async (e: any) => {
    if (e.value.length > 2) {
      const newValue = e.value.slice(0, 2);
      e.component.option("value", newValue);
    } else {
      setResName(e.value);
    }
  };
  const onResSave = async (e: any) => {
    if (agenda === "" || title === "" || resName === "" || startDate2 === "") {
      alert("value is required", "Error");
    } else {
      const json = {
        appointDt: moment(resDate).format("YYYY-MM-DD"),
        startTime: startDate2.format("HH:mm"),
        endTime: endDate2.format("HH:mm"),
        agenda: agenda,
        insungSeq: resName,
        title: title,
        timezoneOffset: -540,
      };

      doctorService.addAppointment(json).then(async (d: any) => {
        if (d.statusCode === 200) {
          updateData();
          $(".popClose").trigger("click");
        }
      });
    }
    // if (e.validationGroup.validate()) {
    //   const json = {
    //     appointDt: moment(resDate).format("YYYY-MM-DD"),
    //     startTime: startDate2.format("HH:mm"),
    //     endTime: endDate2.format("HH:mm"),
    //     agenda: agenda,
    //     insungSeq: resName,
    //     title: title,
    //     timezoneOffset: -540,
    //   };
    //   const doctorService = new DocotrDataProvider();
    //   doctorService.addAppointment(json).then(async (d: any) => {
    //     updateData();
    //     $(".popClose").trigger("click");
    //   });
    // }
  };

  const onClose = (e: any) => {
    $(".popClose").trigger("click");
  };
  const openVideo = (e: any) => {
    window.open("/videoCall/" + e, "_blank");
  };

  const deleteAppointment = (d: any) => {
    doctorService.deleteAppointment(d).then(async (d: any) => {
      updateData();
    });
  };
  const updateAppointment = (d: any, set: any) => {
    let json;

    if (set === 1) {
      json = {
        appointSeq: d["appoint_seq"],
        confirmYn: "Y",
        appointDt: d["appoint_dt"],
        startTime: d["start_time"],
        endTime: d["end_time"],
        agenda: d["appointment_agenda"],
        title: d["appointment_title"],
      };

      doctorService.updateAppointment(json).then(async (d: any) => {
        updateData();
      });
    } else {
      if (d.validationGroup.validate()) {
        json = {
          appointSeq: appoint["appointSeq"],
          appointDt: appoint["appointDt"],
          confirmYn: "Y",
          startTime: common.valueChk(startDate2)
            ? startDate2.format("HH:mm")
            : appoint["startTime"],
          endTime: common.valueChk(endDate2)
            ? endDate2.format("HH:mm")
            : appoint["endTime"],
          agenda: agenda,
          title: title,
        };

        doctorService.updateAppointment(json).then(async (d: any) => {
          updateData();
          $(".popClose").trigger("click");
        });
      }
    }
  };
  const ResInit = () => {
    setTimeValue("");
    setResDate(new Date());
    setAgenda("");
    setTitle("");
    setResName("");
    setStartDate2("");
    setEndDate2("");
    $(".li-time").removeClass("on");
    $("#content").children("div").removeClass("dx-invalid");
  };
  const updateData = async () => {
    const today = new Date();

    const gridDt = await doctorService.GetAppointment(startDate, endDate);
    const gridDtAll = await doctorService.GetAppointment(
      new Date(today.getFullYear(), today.getMonth(), 1),
      new Date(today.getFullYear(), today.getMonth() + 1, 0)
    );
    setDtListAll(gridDtAll.data.data);
    setDtList(gridDt.data.data);
    setPatientRev(
      _.filter(gridDt.data.data, {
        confirm_yn: "N",
      })
    );
    setTimeData(tData);
    ResInit();
    $("#liWait").removeClass("new");
    $("#liWait").addClass(
      _.filter(gridDt.data.data, {
        confirm_yn: "N",
      }).length > 0
        ? "new"
        : ""
    );
  };

  const rel1 = { rel: "tab1" };
  const rel2 = { rel: "tab2" };
  return (
    <main id="content" className="content">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: ".wrap" }}
        visible={panShow}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <section className="sub-top">
        <header>
          <div className="tit-area">
            <i className="fa fa-calendar icon-title" aria-hidden="true"></i>
            <div className="tit">
              <h2>{formatMessage("txt_appointment")}</h2>
              <span className="num">({dtList.length})</span>
            </div>
          </div>
        </header>

        <article className="search-form">
          <form action="" name="searchForm">
            <fieldset>
              <legend className="blind">검색폼</legend>
              <div className="form-item">
                <div className="opt short">
                  <label htmlFor="selDate" className="blind">
                    기간선택
                  </label>

                  <SelectBox
                    items={["All", "Today", "1W", "1M"]}
                    defaultValue={"All"}
                    onValueChanged={onDateSelectChanged}
                  />
                </div>
                <div className="form-wrap date-item">
                  <DateBox
                    value={startDate}
                    max={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onSDateChanged}
                  />
                  <span className="hyphen"> ~ </span>
                  <DateBox
                    value={endDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                    onValueChanged={onEDateChanged}
                  />
                </div>
              </div>
              <div className="btn-wrap mt10">
                <button
                  type="button"
                  className="btn md white"
                  onClick={onSearch}
                >
                  <i className="fa fa-search icon" aria-hidden="true"></i>
                  <span className="blind">검색</span>
                </button>
              </div>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont mt30">
        <div className="appointment">
          <Calendar
            width={650}
            height={590}
            value={calDate}
            firstDayOfWeek={0}
            zoomLevel={"month"}
            onValueChanged={async (e: any) => {
              setCalDate(e.value);
              setStartDate(e.value);
              setEndDate(e.value);

              const gridDt = await doctorService.GetAppointment(
                e.value,
                e.value
              );

              setDtList(gridDt.data.data);

              //WatingList 클래스 관련
              const rev = _.filter(gridDt.data.data, {
                confirm_yn: "N",
              });
              setPatientRev(rev);
              $("#liWait").removeClass("new");
              $("#liWait").addClass(rev.length > 0 ? "new" : "");
            }}
            cellRender={(e) => {
              const dtLength = _.filter(dtListAll, {
                appoint_dt: moment(e.date).format("YYYY-MM-DD").toString(),
              }).length;
              if (dtLength > 0) {
                return <span className="weekend">{e.text}</span>;
              } else {
                return <span>{e.text}</span>;
              }
            }}
          />

          <div className="chk-list">
            <button
              type="button"
              className="btn md white flex-mla"
              onClick={() => {
                setNewWrite(true);
                ResInit();
                common.openLayer("popReservation");
              }}
            >
              <i className="fa fa-plus icon" aria-hidden="true"></i>
              <span>{formatMessage("btn_reservation")}</span>
            </button>
            <article className="tab-wrap mt30">
              <ul className="tab-nav tab-nav--type1">
                <li className="on" {...rel1} style={{ cursor: "pointer" }}>
                  <a>
                    <span>{formatMessage("txt_my_appointments")}</span>
                  </a>
                </li>

                <li id="liWait" {...rel2} style={{ cursor: "pointer" }}>
                  <a>
                    <span>{formatMessage("txt_waiting_list")}</span>
                  </a>
                </li>
              </ul>

              <div className="tab-cont on" id="tab1">
                <div className="appointment__detail">
                  <p className="period">
                    {moment(startDate).format("MM/DD/YYYY").toString()} ~{" "}
                    {moment(endDate).format("MM/DD/YYYY").toString()}
                  </p>
                  <p className="noti">
                    You can enter from 10 minutes before the reservation time.
                  </p>
                  {dtList.map((d: any, i: any) => {
                    if (d["confirm_yn"] !== "N") {
                      return (
                        <article className="appointment__list has">
                          <header>
                            <span>{d["appoint_dt"]}</span>
                            <div className="header-wrap">
                              <button
                                type="button"
                                className="btn sm blue"
                                onClick={async () => {
                                  let chkLog =
                                    await doctorService.GetPatientList();
                                  chkLog = _.filter(chkLog.data.data, {
                                    insungSeq: d["insung_seq"],
                                  })[0];
                                  chkLog.expireDt !== null
                                    ? openVideo(d["appoint_seq"])
                                    : alert(
                                        formatMessage("txt_not_answer"),
                                        "alert"
                                      );
                                }}
                                disabled={
                                  moment(
                                    d["appoint_dt"] + " " + d["start_time"]
                                  )
                                    .subtract(10, "minutes")
                                    .valueOf() <= moment(realTime).valueOf() &&
                                  moment(
                                    d["appoint_dt"] + " " + d["end_time"]
                                  ).valueOf() >= moment(realTime).valueOf()
                                    ? false
                                    : true
                                }
                              >
                                <strong>
                                  {formatMessage("btn_video_call")}
                                </strong>
                              </button>
                              <button
                                type="button"
                                className="btn sm blue"
                                onClick={() => {
                                  setNewWrite(false);
                                  const dt = {
                                    appointSeq: d["appoint_seq"],
                                    appointDt: d["appoint_dt"],
                                    startTime: d["start_time"],
                                    endTime: d["end_time"],
                                    agenda: d["appointment_agenda"],
                                    title: d["appointment_title"],
                                    insungSeq: d["insung_seq"],
                                  };
                                  setAppoint(dt);
                                  setTimeValue(
                                    d["start_time"] + " ~ " + d["end_time"]
                                  );
                                  setResDate(new Date(d["appoint_dt"]));
                                  setAgenda(d["appointment_agenda"]);
                                  setTitle(d["appointment_title"]);
                                  setResName(d["insung_seq"]);
                                  $(".li-time").removeClass("on");
                                  common.openLayer("popReservation");
                                }}
                              >
                                <i
                                  className="fa fa-pencil-square-o icon"
                                  aria-hidden="true"
                                ></i>
                                <span className="blind">
                                  {formatMessage("btn_video_call")}
                                </span>
                              </button>
                              <button
                                type="button"
                                className="btn sm grey"
                                onClick={() => {
                                  let result = confirm(
                                    "<i>" +
                                      formatMessage("msg_cancel_appointment") +
                                      "</i>",
                                    "Confirm changes"
                                  );
                                  result.then((dialogResult) => {
                                    if (dialogResult) {
                                      deleteAppointment(d["appoint_seq"]);
                                    } else {
                                      alert(
                                        formatMessage("msg_canceled"),
                                        formatMessage("btn_cancel")
                                      );
                                    }
                                  });
                                }}
                              >
                                <i
                                  className="fa fa-trash icon"
                                  aria-hidden="true"
                                ></i>
                                <span className="blind">remove</span>
                              </button>
                            </div>
                          </header>
                          <div className="cont">
                            <div className="cont__top">
                              <i
                                className="fa fa-user-circle-o"
                                aria-hidden="true"
                              ></i>
                              <span className="time">
                                {d["start_time"]} ~ {d["end_time"]}
                              </span>
                            </div>
                            <div className="cont__bottom">
                              <p className="name">
                                {(d["firstName"] === null
                                  ? ""
                                  : d["firstName"]) +
                                  (d["middleName"] === null
                                    ? ""
                                    : " " + d["middleName"]) +
                                  (d["lastName"] === null
                                    ? ""
                                    : " " + d["lastName"])}
                              </p>
                              <button
                                id={"btnAgenda" + i}
                                type="button"
                                className="slide-open flex-mla"
                                onClick={() => {
                                  const agendaId = "#btnAgenda" + i;

                                  let isOn = $(agendaId).hasClass("on");
                                  let $slideCont = $(agendaId)
                                    .parents(".cont")
                                    .siblings("footer");

                                  $(agendaId).toggleClass("on");
                                  if (isOn !== true) {
                                    $slideCont.slideDown(500);
                                  } else {
                                    $slideCont.slideUp(500);
                                  }
                                }}
                              >
                                <i
                                  className="fa fa-angle-down icon-arrow"
                                  aria-hidden="true"
                                ></i>
                                <span className="blind">내용 열림</span>
                              </button>
                            </div>
                          </div>
                          <footer>
                            <p>{d["appointment_agenda"]}</p>
                          </footer>
                        </article>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              <div className="tab-cont" id="tab2">
                <div className="appointment__detail">
                  <p className="period">
                    {moment(startDate).format("MM/DD/YYYY").toString()} ~
                    {moment(endDate).format("MM/DD/YYYY").toString()}
                  </p>

                  {patientRev.map((d: any, i: any) => {
                    return (
                      <article className="appointment__list has">
                        <header>
                          <span>{d["appoint_dt"]}</span>
                          <div className="header-wrap">
                            <button
                              type="button"
                              className="btn sm blue"
                              onClick={() => updateAppointment(d, 1)}
                            >
                              <span>{formatMessage("txt_confirm")}</span>
                            </button>
                            <button
                              type="button"
                              className="btn sm red"
                              onClick={() => {
                                let result = confirm(
                                  "<i>" +
                                    formatMessage("msg_cancel_appointment") +
                                    "</i>",
                                  "Confirm changes"
                                );
                                result.then((dialogResult) => {
                                  if (dialogResult) {
                                    deleteAppointment(d["appoint_seq"]);
                                  } else {
                                    alert("Canceled", "Canceled");
                                  }
                                });
                              }}
                            >
                              <span>Reject</span>
                            </button>
                          </div>
                        </header>
                        <div className="cont">
                          <div className="cont__top">
                            <i
                              className="fa fa-user-circle-o"
                              aria-hidden="true"
                            ></i>
                            <span className="time">
                              {" "}
                              {d["start_time"]} ~ {d["end_time"]}
                            </span>
                            <p className="name">
                              {(d["firstName"] === null ? "" : d["firstName"]) +
                                (d["middleName"] === null
                                  ? ""
                                  : " " + d["middleName"]) +
                                (d["lastName"] === null
                                  ? ""
                                  : " " + d["lastName"])}
                            </p>
                          </div>
                        </div>
                      </article>
                    );
                  })}
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
      <div className="pop-layer pop-layer--sm" id="popReservation">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>{formatMessage("btn_reservation")}</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <article>
                <div className="tbl-wrap">
                  <table className="type-pop type-th">
                    <caption>{formatMessage("btn_reservation")}</caption>
                    <colgroup>
                      <col width="30%" />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row" className="fst">
                          <strong className="req">
                            {formatMessage("txt_date")}
                          </strong>
                        </th>
                        <td>
                          <DateBox
                            value={resDate}
                            type="date"
                            onValueChanged={(e: any) => {
                              setTimeData(tData);
                              setResDate(e.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="fst">
                          <strong className="req">
                            {formatMessage("txt_time")}
                          </strong>
                        </th>
                        <td>
                          <TextBox id="time" value={timeValue}>
                            {/* <Validator>
                              <RequiredRule message="time is required" />
                            </Validator> */}
                          </TextBox>
                          <ul className="time-list">
                            {timeData.map((d: any, i: any) => {
                              const t =
                                moment(resDate).format("YYYY-MM-DD") + " " + d;
                              let disabled = "";

                              const timeLength = _.filter(dtListAll, {
                                appoint_dt:
                                  moment(resDate).format("YYYY-MM-DD"),
                                start_time: d,
                              }).length;

                              const resLength = _.filter(
                                dtListAll,
                                function (o) {
                                  return (
                                    moment(
                                      o.appoint_dt + " " + o.start_time
                                    ).valueOf() < moment(t).valueOf() &&
                                    moment(
                                      o.appoint_dt + " " + o.end_time
                                    ).valueOf() > moment(t).valueOf()
                                  );
                                }
                              );

                              if (timeLength > 0) {
                                disabled = "disabled";
                              } else if (resLength.length > 0) {
                                disabled = "disabled";
                              } else if (moment(t) >= moment()) {
                                disabled = "";
                              } else {
                                disabled = "disabled";
                              }

                              return (
                                <li
                                  className={"li-time " + disabled}
                                  onClick={(e: any) =>
                                    disabled === "disabled"
                                      ? ""
                                      : fixTime(e, moment(t))
                                  }
                                >
                                  <span>{d}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="fst">
                          <strong className="req">
                            {formatMessage("txt_title")}
                          </strong>
                        </th>
                        <td>
                          <TextBox
                            id="title"
                            value={title}
                            onValueChanged={(e: any) => {
                              setTitle(e.value);
                            }}
                          >
                            {/* <Validator>
                              <RequiredRule message="title is required" />
                            </Validator> */}
                          </TextBox>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="fst">
                          <strong className="req">
                            {formatMessage("txt_agenda")}
                          </strong>
                        </th>
                        <td>
                          <TextArea
                            id="agenda"
                            value={agenda}
                            height={90}
                            onValueChanged={(e: any) => setAgenda(e.value)}
                          >
                            {/* <Validator>
                              <RequiredRule message="agenda is required" />
                            </Validator> */}
                          </TextArea>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="fst">
                          <strong className="req">
                            {formatMessage("txt_participants")}
                          </strong>
                        </th>
                        <td>
                          <div className="tf type type--02">
                            <SelectBox
                              id="selPatent"
                              items={["Patient"]}
                              value={"Patient"}
                            />
                            {/* 
                            <TagBox
                              dataSource={resNameList}
                              placeholder="Name"
                              //value={resName}
                              maxDisplayedTags={2}
                              displayExpr="name"
                              searchEnabled={true}
                              valueExpr="insungSeq"
                              width={$("#selPatent").width()}
                              height={$("#selPatent").height()}
                              onValueChanged={onResNameChanged}
                            >
                              <Validator>
                                <RequiredRule message="Name is required" />
                              </Validator>
                            </TagBox> */}
                            <SelectBox
                              id="selName"
                              dataSource={resNameList}
                              placeholder="Name"
                              value={resName}
                              searchEnabled={true}
                              searchMode={"contains"}
                              searchExpr={"name"}
                              displayExpr="name"
                              valueExpr="insungSeq"
                              onValueChanged={onResNameChanged}
                            >
                              {/* <Validator>
                                <RequiredRule message="Name is required" />
                              </Validator> */}
                            </SelectBox>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <Button
                className="btn mlg blue"
                text={formatMessage("btn_save")}
                type="success"
                stylingMode="contained"
                height={49}
                useSubmitBehavior={true}
                onClick={(e: any) => {
                  if (newWrite) {
                    onResSave(e);
                  } else {
                    updateAppointment(e, 2);
                  }
                }}
              />
              <Button
                className="btn mlg grey"
                text={formatMessage("btn_closel")}
                type="default"
                stylingMode="contained"
                onClick={onClose}
              />
            </div>
          </footer>
        </section>
      </div>
    </main>
  );
};

export default Appointment;
