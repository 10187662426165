export interface IMeasurementProps {
  location: any;
}

export const measurementType = [
  {
    measureType: "",
    name: "All",
  },
  {
    measureType: "PF",
    name: "Spirometer",
  },
  {
    measureType: "IN",
    name: "PT/INR",
  },
  {
    measureType: "PH",
    name: "Photos",
  },
  {
    measureType: "HB",
    name: "HbA1c",
  },
  {
    measureType: "GL",
    name: "Glucose",
  },
  {
    measureType: "EC",
    name: "ECG",
  },
  {
    measureType: "MS",
    name: "Digital Microscope",
  },
  {
    measureType: "CH",
    name: "Cholesterol",
  },
  {
    measureType: "CG",
    name: "Capnography",
  },
  {
    measureType: "TE",
    name: "Body Temperature",
  },
  {
    measureType: "BP",
    name: "Blood Pressure",
  },
  {
    measureType: "PF",
    name: "Spirometer",
  },
  {
    measureType: "OX",
    name: "SpO₂",
  },
  {
    measureType: "WR",
    name: "Steps",
  },
  {
    measureType: "ST",
    name: "Stethoscope",
  },
  {
    measureType: "UR",
    name: "Urinalysis",
  },
  {
    measureType: "WA",
    name: "Waist",
  },
  {
    measureType: "WE",
    name: "Weight",
  },
  {
    measureType: "AC",
    name: "Activity",
  },
  {
    measureType: "HR",
    name: "Heart",
  },
  {
    measureType: "BC",
    name: "Body Composition",
  },
  {
    measureType: "LI",
    name: "LifeMonito",
  },
  {
    measureType: "BM",
    name: "Stadiometer",
  },
];
