import { en } from "./languageEn";
import { te } from "./languageTe";
import { it } from "./languageIt";
import { de } from "./languageDe";
import { ko } from "./languageKo";

export const lang = {
  en: en,
  te: te,
  it: it,
  de: de,
  ko: ko,
};
