export const ko = {
  btn_add_to_call: "화상통화 초대",
  btn_cancel: "취소",
  btn_change_layout: "모드변경",
  btn_close: "닫기",
  btn_copy_url: "주소 복사",
  btn_display: "보기설정",
  btn_end_call: "전화 끊기",
  btn_graph: "그래프보기",
  btn_join_call_now: "지금 통화",
  btn_reservation: "예약",
  btn_rewrite: "다시쓰기",
  btn_save: "저장",
  btn_save_changes: "변경사항저장",
  btn_send: "보내기",
  btn_start_recording: "녹화시작",
  btn_stop_recording: "녹화중지",
  btn_table: "표보기",
  btn_trend_chart: "차트(추이)",
  btn_trend_table: "표(추이)",
  btn_video_call: "화상통화",
  datatables_language: "Korean.json",
  html_privacy: "privacy_korean.html",
  html_terms: "termsofservice_korean.html",
  mnu_appointment: "상담예약",
  mnu_assessment: "건강문진",
  mnu_files: "파일",
  mnu_image: "이미지",
  mnu_measurement: "건강측정",
  mnu_message: "메시지",
  mnu_patient: "환자관리",
  msg_appointment_cancelled: "예약이 취소되었습니다. ",
  msg_appointment_changed: "예약이 변경되었습니다.",
  msg_appointment_created: "예약되었습니다. ",
  msg_call_ended: "전화가 끊겼습니다.",
  msg_call_setting_saved: "통화 설정이 저장되었습니다.",
  msg_cancel_appointment: "정말 예약취소를 하시겠습니까?",
  msg_canceled: "취소되었습니다.",
  msg_choose_photos_videos: "적어도 한 개의 사진 또는 동영상을 선택해 주세요. ",
  msg_delete_appoint: "상담예약을 취소하시겠습니까?",
  msg_enter_agenda: "내용을 입력하세요.",
  msg_enter_id: "ID를 입력하세요.",
  msg_enter_message: "전송할 메세지를 입력하세요.",
  msg_enter_phone_number: "전화번호를 입력하세요.",
  msg_enter_phone_title: "표시할 이름을 입력하세요.",
  msg_enter_pwd: "비밀번호를 입력하세요.",
  msg_enter_pwd_confirmed: "비밀번호를 한 번 더 입력하세요.",
  msg_enter_title: "제목을 입력하세요.",
  msg_login_fail:
    "입력한 ID를 찾을 수 없거나 비밀번호가 잘못되었습니다. 확인 후 다시 시도해 주세요.",
  msg_max_length:
    "최대 ${length}byte를 초과할 수 없습니다. 초과 한 글자는 자동으로 삭제됩니다",
  msg_message_sent: "메시지가 전송되었습니다.",
  msg_missed_call: "전화를 받을 수 없습니다.<br/> 잠시 후 다시 걸어 주세요.",
  msg_modified: "수정되었습니다.",
  msg_not_support_graph: "선택하신 항목은 그래프 보기를 지원하지 않습니다.",
  msg_offline:
    "전화를 받지 않습니다.<br/>(수신자가 오프라인 상태일 수 있습니다)<br/>잠시 후 다시 걸어 주세요.",
  msg_offline2: "님이 부재중입니다. 잠시 후 다시 걸어 주세요.",
  msg_pwd_changed: "비밀번호가 변경되었습니다.",
  msg_pwd_not_match: "비밀번호가 일치하지 않습니다. \n비밀번호를 확인하세요.",
  msg_record_send: "녹화파일이 전송되었습니다.",
  msg_select_appointment_date: "예약일을 선택하세요.",
  msg_select_appointment_time: "예약시간을 선택하세요.",
  msg_select_measure_type: "측정항목을 선택하세요.",
  msg_select_participant: "참가자를 선택하세요.",
  msg_select_patient: "환자를 선택하세요. ",
  msg_select_start_end_date: "시작/종료 시간을 선택하세요.",
  msg_send_msg: "전송할 메세지를 입력하세요.",
  msg_want_change_pwd: "비밀번호를 변경하겠습니까?",
  msg_want_end_call: "전화를 끊으시겠습니까?",
  txt_2hours_after_meal: "식후 2시간",
  txt_add_to_call: "화상통화 초대",
  txt_age: "나이",
  txt_agenda: "주제",
  txt_agreement: "동의",
  txt_albumin: "알부민",
  txt_all: "전체",
  txt_all_consent: "전체 동의",
  txt_april: "4월",
  txt_assessment: "건강문진",
  txt_august: "9월",
  txt_before_5mins: "5분전",
  txt_before15mins: "15분전",
  txt_before1hour: "1시간전",
  txt_before30mins: "30분전",
  txt_bil: "Bil",
  txt_bilirubin: "빌리루빈",
  txt_blood_pressure: "혈압",
  txt_blood_rbc: "적혈구",
  txt_blood_sugar: "포도당",
  txt_bmi: "BMI",
  txt_body_fat: "체지방",
  txt_body_temperature: "체온",
  txt_bodyfat: "체지방",
  txt_bpm: "bpm",
  txt_bytes: "Bytes",
  txt_calling: "전화 거는 중",
  txt_camera_connected: "카메라가 연결되었습니다. -  ${name}카메라",
  txt_camera_disconnected:
    "카메라 연결이 끊어졌습니다 현재 연결된 카메라가 없습니다.",
  txt_capnography: "CapnoGraphy",
  txt_check: "읽음",
  txt_cholesterol: "콜레스테롤",
  txt_cholesterol_cardiochek: "콜레스테롤(CardioChek)",
  txt_comparative_results: "Comparative Results",
  txt_confirm: "확인",
  txt_copd: "COPD",
  txt_creatinine: "크레아티닌",
  txt_date: "날짜",
  txt_date_measured: "측정일자",
  txt_date_transferred: "전송일자",
  txt_december: "12월",
  txt_detail: "내용",
  txt_detection_result: "Detection Result",
  txt_device: "기기",
  txt_diagnosis_result: "Diagnosis Result",
  txt_diastolic: "이완기",
  txt_digital_microscope: "디지털 스코프",
  txt_digital_microscope_contents: "디지털 스코프 내용",
  txt_dob: "생년월일",
  txt_ecg: "ECG",
  txt_edit: "편집",
  txt_ela: "ELA",
  txt_end_date: "종료일자",
  txt_end_time: "종료시간",
  txt_entered_the_room: "님이 방에 입장하였습니다.",
  txt_entries: "개",
  txt_etco2: "EtCO₂",
  txt_ev: "EV",
  txt_fasting: "공복",
  txt_february: "2월",
  txt_fef: "FEF",
  txt_fet: "FET",
  txt_fev: "FEV",
  txt_filter: "Filter",
  txt_fit: "FIT",
  txt_fiv: "FIV",
  txt_fivc: "FIVC",
  txt_full_text_view: "전문보기",
  txt_fvc: "FVC",
  txt_gain: "Gain",
  txt_glu: "Glu",
  txt_glucose: "혈당",
  txt_graph: "그래프",
  txt_hba1c: "HbA1c",
  txt_hct: "HCT",
  txt_hdl: "HDL",
  txt_height: "신장",
  txt_hour: "hour",
  txt_hr: "HR",
  txt_image: "이미지",
  txt_input_your_text: "메시지를 입력하세요",
  txt_inr: "INR",
  txt_interval: "Interval",
  txt_invite_participants: "참가자 초대",
  txt_january: "1월",
  txt_july: "6월",
  txt_june: "7월",
  txt_ket: "Ket",
  txt_ketone: "케톤",
  txt_ketone_blood: "β-케톤(혈중)",
  txt_ketones: "케톤",
  txt_ldl: "LDL",
  txt_left_the_room: "님이 방에서 나갔습니다.",
  txt_leu: "Leu",
  txt_leukocytes: "백혈구",
  txt_login_id: "아이디",
  txt_logout: "로그아웃",
  txt_march: "3월",
  txt_may: "5월",
  txt_measured: "측정",
  txt_measured_cardiac_rythm_events: "Measured Cardiac Rythm Events",
  txt_medical_staff: "MEDICAL STAFF",
  txt_message: "메시지",
  txt_message_list: "전송 메시지 목록",
  txt_min: "min",
  txt_missed_call: "부재중통화",
  txt_month: "한달",
  txt_name: "이름",
  txt_negative: "음성",
  txt_nit: "Nit",
  txt_nitrite: "아질산염",
  txt_no: "번호",
  txt_no_appointment: "오늘 예약 건이 없습니다.",
  txt_no_matches: "검색 조건과 일치하는 항목이 없습니다",
  txt_none: "없음",
  txt_normal: "정상",
  txt_normal_sinus_rhythm: "Normal Sinus Rhythm",
  txt_not_answering_call:
    "지금은 전화를 받을 수 없습니다. <br/>잠시 후 다시 걸어주세요.",
  txt_not_answering_phone:
    "지금은 전화를 받을 수 없습니다. 잠시 후 다시 걸어주세요.",
  txt_november: "11월 ",
  txt_ob: "OB",
  txt_occult_blood: "잠혈",
  txt_october: "10월",
  txt_offline: "부재중 통화",
  txt_others: "기타",
  txt_p_qrs_t_qrst_axis: "P/QRS/T/QRST axis",
  txt_pacemaker: "Pacemaker",
  txt_param: "Param",
  txt_participants: "참가자",
  txt_patient: "환자",
  txt_patient_data: "환자 데이터",
  txt_pef: "PEF",
  txt_peft: "PEFt",
  txt_pft: "PFT",
  txt_ph: "pH",
  txt_phone_number: "전화번호",
  txt_phone_number_characters: "전화번호에는 문자를 포함할 수 없습니다.",
  txt_phone_settings: "전화설정",
  txt_phone_title: "표시이름",
  txt_photo: "사진",
  txt_pick_time: "시간선택",
  txt_pif: "PIF",
  txt_positive: "양성",
  txt_pqrstqrst_axis: "P/QRS/T/QRST axis",
  txt_pr: "PR",
  txt_pr_interval: "PR Interval",
  txt_pred: "Pred",
  txt_privacy_policy: "개인정보 처리방침",
  txt_pro: "Pro",
  txt_protein: "단백질",
  txt_prt_axis: "P/R/T axis",
  txt_pt: "PT",
  txt_ptinr: "PT/INR",
  txt_pulse: "맥박",
  txt_pulse_bpm: "맥박(bpm)",
  txt_pwd: "비밀번호",
  txt_pwd_change: "비밀번호변경",
  txt_pwd_confirmed: "비밀번호확인",
  txt_qrs: "QRS",
  txt_qrs_duration: "QRS Duration",
  txt_qt: "QT",
  txt_qt_qtc: "QT/QTc",
  txt_qtc: "QTc",
  txt_qtr: "QTr",
  txt_range: "범위",
  txt_receiver: "받는사람",
  txt_record: "기록",
  txt_registratered: "등록일",
  txt_remember_me: "로그인계정 저장",
  txt_reminder_email: "이메일 알림",
  txt_reminder_sms: "SMS 알림",
  txt_required: "필수",
  txt_respiratory_rate: "Respiratory Rate(bpm)",
  txt_result: "결과",
  txt_result_actual_measurement:
    "pH (산도), 비중, 아질산염의 결과는 실제 측정 값입니다.",
  txt_result_type: "결과항목",
  txt_rr: "RR",
  txt_rv5: "RV5",
  txt_september: "9월",
  txt_service: "서비스",
  txt_setup_information: "Hicare 앱>전화 에서 정보를 설정할 수 있습니다.",
  txt_sex: "성별",
  txt_sex_age: "성별 / 나이",
  txt_sg: "SG",
  txt_show: "목록",
  txt_sign_in: "로그인",
  txt_speed: "Speed",
  txt_spo2: "SpO₂",
  txt_start_date: "시작일자",
  txt_start_time: "시작시각",
  txt_steps: "걸음수",
  txt_stethoscope: "청진기",
  txt_sv1: "SV1",
  txt_svc_agree: "서비스 이용동의",
  txt_system_setup: "시스템설정",
  txt_systolic: "수축기",
  txt_table: "표보기",
  txt_tc: "TC",
  txt_terms_of_service: "서비스 이용약관",
  txt_test_date: "검사일",
  txt_test_item: "검사항목(unit)",
  txt_tg: "TG",
  txt_time: "시간",
  txt_title: "제목",
  txt_title_check: "확인",
  txt_title_patient: "등록환자",
  txt_today: "오늘",
  txt_total_cholesterol: "총콜레스테롤(TC)",
  txt_trace: "trace",
  txt_transferred: "전송",
  txt_trig: "Trig.",
  txt_type: "분류",
  txt_uncheck: "읽지않음",
  txt_unchecked_only: "읽지않은 메시지만 보기",
  txt_unconfirmed_report: "Unconfirmed Report To Be Reviewed",
  txt_unit_measure: "측정단위 설정",
  txt_units: "단위",
  txt_upcoming_appointment: "예정된 상담예약이 있습니다.",
  txt_uric_acid: "Uric Acid",
  txt_urine_spot: "Urine(spot)",
  txt_urine_stick: "Urine(stick)",
  txt_uro: "Uro",
  txt_urobilinogen: "우로빌리노겐",
  txt_val: "Val",
  txt_value: "측정수치",
  txt_vent_rate: "Vent. Rate",
  txt_video: "화상",
  txt_video_call: "화상통화",
  txt_video_chat_stop: "화상통화 종료",
  txt_videocall_archives: "화상통화 녹화기록",
  txt_videos: "화상",
  txt_waist: "허리둘레",
  txt_week: "주",
  txt_weight: "체중",
};
