export const de = {
  btn_add_to_call: "Zum Anruf hinzufügen",
  btn_cancel: "Abbrechen",
  btn_change_layout: "Layout ändern",
  btn_close: "Schließen",
  btn_copy_url: "URL vom Meeting kopieren",
  btn_display: "Anzeigen",
  btn_end_call: "Anruf beenden",
  btn_graph: "Diagramm anzeigen",
  btn_join_call_now: "Am Gespräch teilnehmen",
  btn_reservation: "Termin vereinbaren",
  btn_rewrite: "Neu schreiben",
  btn_save: "Speichern",
  btn_save_changes: "Änderungen speichern",
  btn_send: "Senden",
  btn_start_recording: "Aufnahme starten",
  btn_stop_recording: "Aufnahme beenden",
  btn_table: "Tabelle anzeigen",
  btn_trend_chart: "Grafik (Trend)",
  btn_trend_table: "Tabelle (Trend)",
  btn_video_call: "Videoanruf",
  datatables_language: "German.json",
  mnu_appointment: "Termin",
  mnu_assessment: "Auswertung",
  mnu_files: "Datei",
  mnu_image: "Bild",
  mnu_measurement: "Messung",
  mnu_message: "Nachricht",
  mnu_patient: "Patient",
  msg_appointment_cancelled: "Ihr Termin wurde abgesagt.",
  msg_appointment_changed: "Ihr Termin wurde geändert.",
  msg_appointment_created: "Ihr Termin wurde angelegt.",
  msg_call_ended: "Anruf beendet",
  msg_call_setting_saved: "Die Anrufeinstellungen wurden gespeichert.",
  msg_cancel_appointment:
    "Sind Sie sicher, dass Sie diesen Termin absagen wollen?",
  msg_canceled: "wurde abgebrochen.",
  msg_choose_photos_videos:
    "Bitte wählen Sie mindestens eines der Fotos oder Videos aus.",
  msg_delete_appoint: "Sind Sie sicher, dass Sie diesen Termin absagen wollen?",
  msg_end_date_after_start_date:
    'Das "Enddatum" muss hinter dem "Startdatum" liegen',
  msg_enter_agenda: "Bitte geben Sie die Agenda ein.",
  msg_enter_id: "Bitte geben Sie Ihre ID ein.",
  msg_enter_message: "Bitte geben Sie eine Nachricht zum Senden ein.",
  msg_enter_phone_number: "Bitte geben Sie Ihre Telefonnummer ein.",
  msg_enter_phone_title: "Geben Sie den Namen ein, der angezeigt werden soll.",
  msg_enter_pwd: "Bitte Passwort eingeben.",
  msg_enter_pwd_confirmed: "Passwort erneut eingeben.",
  msg_enter_title: "Bitte geben Sie einen Titel ein.",
  msg_line_busy:
    "Die Leitung ist besetzt. Bitte versuchen Sie es später noch einmal.",
  msg_login_fail:
    "Die von Ihnen eingegebene ID wurde nicht gefunden oder das Passwort ist falsch.\nBitte überprüfen Sie es und versuchen Sie es erneut.",
  msg_max_length:
    "Die maximale Größe beträgt ${length}byte. Überschüssige Zeichen werden automatisch gelöscht.",
  msg_message_sent: "Die Nachricht wurde gesendet.",
  msg_missed_call:
    "Ich kann den Anruf gerade nicht entgegennehmen.\nBitte versuchen Sie es später erneut.",
  msg_modified: "Die Änderung wurde vorgenommen.",
  msg_offline:
    "Ihr Anruf wird nicht entgegengenommen.\n(Der Teilnehmer ist möglicherweise offline)\nBitte versuchen Sie es später noch einmal.",
  msg_pwd_changed: "Ihr Passwort wurde geändert.",
  msg_pwd_not_match:
    "Passwörter stimmen nicht überein. \nBitte überprüfen Sie Ihr Passwort.",
  msg_select_appointment_date:
    "Bitte wählen Sie ein Datum für Ihren Termin aus.",
  msg_select_appointment_time:
    "Bitte wählen Sie die Zeit für Ihren Termin aus.",
  msg_select_participant: "Bitte wählen Sie einen Teilnehmer aus.",
  msg_select_patient: "Bitte wählen Sie einen Patienten aus.",
  msg_select_start_end_date: "Bitte wählen Sie ein Start-/Enddatum.",
  msg_want_change_pwd: "Wollen Sie das Passwort wirklich ändern?",
  msg_want_end_call: "Sind Sie sicher, dass Sie den Anruf beenden möchten?",
  txt_2hours_after_meal: "2 Stunden nach dem Essen",
  txt_add_to_call: "Dem Anruf hinzufügen",
  txt_age: "Alter",
  txt_agenda: "Betreff",
  txt_albumin: "Albumin",
  txt_all: "Alle",
  txt_april: "April",
  txt_assessment: "Auswertung",
  txt_august: "August",
  txt_before_5mins: "Vor 5 Minuten",
  txt_before15mins: "Vor 15 Minuten",
  txt_before1hour: "Vor 1 Stunde",
  txt_before30mins: "Vor 30 Minuten",
  txt_bil: "Bil",
  txt_bilirubin: "Bilirubin",
  txt_blood_pressure: "Blutdruck",
  txt_blood_rbc: "Rote Blutzellen (RBC/㎕)",
  txt_blood_sugar: "Blutzucker",
  txt_bmi: "BMI",
  txt_body_fat: "Körperfett",
  txt_body_temperature: "Körpertemperatur",
  txt_bodyfat: "Körperfett",
  txt_bpm: "bpm (Schläge pro Minute)",
  txt_bytes: "Bytes",
  txt_calling: "ruft an",
  txt_camera_connected: "Kamera angeschlossen. - ${name} Kamera",
  txt_camera_disconnected:
    "Kamera nicht angeschlossen. Es ist keine Kamera angeschlossen.",
  txt_capnography: "Kapnographie",
  txt_check: "Gelesen",
  txt_cholesterol: "Cholesterin",
  txt_cholesterol_cardiochek: "Cholesterin (CardioChek)",
  txt_comparative_results: "Vergleichsresultate",
  txt_copd: "COPD",
  txt_creatinie: "Kreatinin",
  txt_date: "Daten",
  txt_date_measured: "Daten (gemessen)",
  txt_date_transferred: "Daten (übertragen)",
  txt_december: "Dezember",
  txt_detail: "Detail",
  txt_detection_result: "Ergebnis der Untersuchung",
  txt_device: "Messgerät",
  txt_device_lower: "Messgerät",
  txt_diagnosis_result: "Diagnose-Ergebnis",
  txt_diastolic: "diastolisch",
  txt_digital_microscope: "Digitales Mikroskop",
  txt_digital_microscope_contents: "Inhalt des digitalen Mikroskops",
  txt_dob: "Geburtsdatum",
  txt_ecg: "EKG",
  txt_edit: "Bearbeiten",
  txt_ela: "ELA",
  txt_end_date: "Enddatum",
  txt_end_time: "Endzeit",
  txt_entered_the_room: "hat den Raum betreten.",
  txt_entries: "anzeigen",
  txt_etco2: "EtCO₂",
  txt_ev: "EV",
  txt_fasting: "nüchtern",
  txt_february: "Februar",
  txt_fef: "FEF",
  txt_fet: "FET",
  txt_fev: "FEV",
  txt_filter: "Filter",
  txt_fit: "FIT",
  txt_fiv: "FIV",
  txt_fivc: "FIVC",
  txt_fvc: "FVC",
  txt_gain: "Gain",
  txt_glu: "Glu",
  txt_glucose: "Glukose",
  txt_graph: "Grafik",
  txt_hba1c: "HbA1c",
  txt_hct: "HCT",
  txt_hdl: "HDL",
  txt_height: "Größe",
  txt_hour: "Stunde",
  txt_hr: "HR",
  txt_image: "Bild",
  txt_input_your_text: "Bitte geben Sie eine Nachricht ein",
  txt_inr: "INR",
  txt_interval: "Intervall",
  txt_invite_participants: "Teilnehmer einladen",
  txt_january: "Januar",
  txt_july: "Juli",
  txt_june: "Juni",
  txt_ket: "Ket",
  txt_ketone: "Keton",
  txt_ketone_blood: "β-Keton (im Blut)",
  txt_ketones: "Ketone",
  txt_ldl: "LDL",
  txt_left_the_room: "hat den Raum verlassen.",
  txt_leu: "Leu",
  txt_leukocytes: "Leukozyten(WBC/㎕)",
  txt_line_budy: "Videoanruf",
  txt_login_id: "Anmelde-ID",
  txt_logout: "Abmeldung",
  txt_march: "März",
  txt_may: "Mai",
  txt_measured: "gemessen",
  txt_measured_cardiac_rythm_events: "Messung von Herzrhythmus-Ereignissen",
  txt_measurement_item: "Messung",
  txt_medical_staff: "Medizinisches Personal",
  txt_message: "Nachricht",
  txt_message_list: "Liste der gesendeten Nachrichten",
  txt_min: "min",
  txt_name: "Name",
  txt_negative: "negativ",
  txt_nit: "Nit",
  txt_nitrite: "Nitrit",
  txt_no: "Nr.",
  txt_no_appointment: "Keine Termine für diesen Tag",
  txt_no_matches: "Keine Ergebnisse für Ihre Suche gefunden",
  txt_none: "Keine",
  txt_normal: "Normal",
  txt_normal_sinus_rhythm: "Normaler Sinusrhythmus",
  txt_not_answering_call:
    "Ich kann derzeit keine Anrufe entgegennehmen. \nBitte versuchen Sie es später noch einmal.",
  txt_november: "November",
  txt_ob: "OB",
  txt_occult_blood: "Okkultes Blut",
  txt_october: "Oktober",
  txt_offline: "Offline",
  txt_others: "Andere",
  txt_p_qrs_t_qrst_axis: "P/QRS/T/QRST Achse",
  txt_pacemaker: "Herzschrittmacher",
  txt_param: "Param",
  txt_participants: "Teilnehmer",
  txt_patient: "Patient",
  txt_patient_data: "Patientendaten",
  txt_pef: "PEF",
  txt_peft: "PEFt",
  txt_pft: "PFT",
  txt_ph: "pH",
  txt_phone_number: "Telefonnummer",
  txt_phone_number_characters: "Telefonnummern dürfen keinen Text enthalten.",
  txt_phone_settings: "Telefoneinstellungen",
  txt_phone_title: "Anzeigename",
  txt_photo: "Fotos",
  txt_pick_time: "Zeitauswahl",
  txt_pif: "PIF",
  txt_positive: "positiv",
  txt_pqrstqrst_axis: "P/QRS/T/QRST Achse",
  txt_pr: "PR",
  txt_pr_interval: "PR-Intervall",
  txt_pred: "Prognose",
  txt_pro: "Pro",
  txt_protein: "Protein",
  txt_prt_axis: "P/T/R Achse",
  txt_pt: "PT",
  txt_ptinr: "PT/INR",
  txt_pulse: "Puls",
  txt_pulse_bpm: "Puls (bpm)",
  txt_pwd: "Passwort",
  txt_pwd_change: "Passwort ändern",
  txt_pwd_confirmed: "Password bestätigt",
  txt_qrs: "QRS",
  txt_qrs_duration: "QRS Dauer",
  txt_qt: "QT",
  txt_qt_qtc: "QT/QTc",
  txt_qtc: "QTc",
  txt_qtr: "QTr",
  txt_range: "Bereich",
  txt_received: "Empfangen",
  txt_receiver: "Empfänger",
  txt_record: "Aufnehmen",
  txt_registratered: "Angemeldet",
  txt_remember_me: "Daten speichern",
  txt_reminder_email: "Erinnerung per E-Mail",
  txt_reminder_sms: "Erinnerung per SMS",
  txt_respiratory_rate: "Atemfrequenz (bpm)",
  txt_result: "Ergebnis",
  txt_result_actual_measurement:
    "Die Ergebnisse von pH (Säuregehalt), spezifischem Gewicht (SG) und Nitrit sind tatsächliche Messungen.",
  txt_result_type: "Art des Ergebnisses",
  txt_rr: "RR",
  txt_rv5: "RV5",
  txt_sent: "Gesendet",
  txt_september: "September",
  txt_service: "Service",
  txt_setup_information:
    "Sie können über die Hicare-App > Telefon > Telefonnummer einstellen.",
  txt_sex: "Geschlecht",
  txt_sex_age: "Geschlecht/Alter",
  txt_sg: "SG",
  txt_show: "Einträge",
  txt_sign_in: "Anmelden",
  txt_speed: "Geschwindigkeit",
  txt_spo2: "SpO₂",
  txt_start_date: "Startdatum",
  txt_start_time: "Startzeit",
  txt_steps: "Schritte",
  txt_sv1: "SV1",
  txt_system_setup: "Systemeinstellung",
  txt_systolic: "systolisch",
  txt_table: "Tabelle",
  txt_tc: "TC",
  txt_test_date: "Test-Datum",
  txt_test_item: "Testeinheit",
  txt_tg: "TG",
  txt_time: "Zeit",
  txt_title: "Titel",
  txt_title_check: "Prüfen",
  txt_today: "Heute",
  txt_total_cholesterol: "Gesamtcholesterin (TC)",
  txt_trace: "Spur",
  txt_transferred: "übertragen",
  txt_trig: "Trig.",
  txt_type: "Typ",
  txt_uncheck: "Ungelesen",
  txt_unchecked_only: "Nur ungelesene Nachrichten anzeigen",
  txt_unconfirmed_report: "Unbestätigter Bericht muss überprüft werden",
};
