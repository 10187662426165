export const it = {
  btn_add_to_call: "Aggiungi alla chiamata",
  btn_cancel: "Annulla",
  btn_change_layout: "Cambia Layout",
  btn_close: "Chiudi",
  btn_copy_url: "Copia url meeting",
  btn_display: "Display",
  btn_end_call: "Fine chiamata",
  btn_graph: "Visualizza grafico",
  btn_join_call_now: "Partecipa alla chiamata",
  btn_reservation: "Prenotazione",
  btn_rewrite: "Riscrivi",
  btn_save: "Salva",
  btn_save_changes: "Salva le modifiche",
  btn_send: "Invia",
  btn_start_recording: "Inizia registrazione",
  btn_stop_recording: "Fine registrazione",
  btn_table: "Visualizza tabella",
  btn_trend_chart: "Visualizza trend su grafico",
  btn_trend_table: "Visualizza trend su tabella",
  btn_video_call: "Videochiamata",
  datatables_language: "Italian.json",
  mnu_appointment: "Appuntamento",
  mnu_assessment: "Valutazione",
  mnu_files: "File",
  mnu_image: "Immagine",
  mnu_measurement: "Misurazione",
  mnu_message: "Messaggio",
  mnu_patient: "Paziente",
  msg_appointment_cancelled: "Il tuo appuntamento è stato cancellato.",
  msg_appointment_changed: "Il tuo appuntamento ha subito una modifica.",
  msg_appointment_created: "Appuntamento programmato con successo.",
  msg_call_setting_saved: "Impostazione di chiamata salvata.",
  msg_cancel_appointment: "Sei sicuro di voler annullare questo appuntamento?",
  msg_canceled: "è stato cancellato",
  msg_choose_photos_videos: "Per favore, scegli almeno una foto o video.",
  msg_end_date_after_start_date:
    "La data di fine deve essere successiva alla data di inizio",
  msg_enter_agenda: "Per favore, inserisci l'agenda.",
  msg_enter_id: "Inserisci il codice identificativo (ID)",
  msg_enter_phone_number: "Inserisci numero di telefono.",
  msg_enter_phone_title: "Inserisci ID numero di telefono.",
  msg_enter_pwd: "Per favore, inserisci la password.",
  msg_enter_pwd_confirmed: "Conferma password",
  msg_enter_title: "Per favore, inserisci il titolo.",
  msg_max_length:
    "Dimensione massima del file è ${length} byte. Le lettere in eccesso verranno automaticamente cancellate.",
  msg_message_sent: "Il messaggio è stato inviato.",
  msg_modified: "La modifica è stata apportata.",
  msg_pwd_changed: "La password è stata cambiata.",
  msg_pwd_not_match:
    "La password non corrisponde.\nPer piacere, controllare la password.",
  msg_select_appointment_date:
    "Per favore, selezionare una data per l'appuntamento.",
  msg_select_appointment_time:
    "Per favore, selezionare l'orario per l'appuntamento.",
  msg_select_participant: "Per favore, seleziona un partecipante.",
  msg_select_patient: "Per favore, seleziona un paziente.",
  msg_select_start_end_date: "Per favore, seleziona data di inizio/fine.",
  msg_want_change_pwd: "Vuoi davvero cambiare la password?",
  msg_want_end_call: "Sei sicuro di voler chiudere la chiamata?",
  txt_2hours_after_meal: "2 ore dopo i pasti",
  txt_add_to_call: "Aggiungi alla chiamata",
  txt_age: "Età",
  txt_agenda: "Note",
  txt_all: "Tutti",
  txt_april: "Aprile",
  txt_assessment: "Valutazione",
  txt_august: "Agosto",
  txt_before_5mins: "5 minuti prima",
  txt_before15mins: "15 minuti prima",
  txt_before1hour: "1 ora prima",
  txt_before30mins: "30 minuti prima",
  txt_bil: "Bil",
  txt_bilirubin: "Bilirubina",
  txt_blood_pressure: "Pressione sanguigna",
  txt_blood_rbc: "Globuli rossi (RBC/㎕)",
  txt_blood_sugar: "Glicemia",
  txt_bmi: "Indice di massa corporea (IMC/BMI)",
  txt_body_fat: "Massa grassa",
  txt_body_temperature: "Temperatura corporea",
  txt_bodyfat: "Massa grassa",
  txt_bpm: "Battiti per minuto/bpm",
  txt_bytes: "Byte",
  txt_calling: "In chiamata",
  txt_camera_connected: "Videocamera connessa.",
  txt_camera_disconnected:
    "Videocamera disconnessa. Non risulta alcuna videocamera connessa.",
  txt_capnography: "Capnografo",
  txt_check: "Controlla",
  txt_cholesterol: "Colesterolo",
  txt_cholesterol_cardiochek: "Colesterolo (Cardiochek)",
  txt_comparative_results: "Risultati comparati",
  txt_copd: "Broncopneumopatia cronica ostruttiva ?",
  txt_date: "Data",
  txt_date_measured: "Data (di misurazione)",
  txt_date_transferred: "Data (di trasferimento)",
  txt_december: "Dicembre",
  txt_detail: "Dettaglio",
  txt_detection_result: "Risultato rilevazione",
  txt_device: "Strumento",
  txt_diagnosis_result: "Risultato diagnosi",
  txt_diastolic: "Diastolica",
  txt_digital_microscope: "Microscopio digitale",
  txt_digital_microscope_contents: "Contenuti microscopio digitale",
  txt_dob: "Data di nascita",
  txt_ecg: "ECG",
  txt_edit: "Modifica",
  txt_ela: "ELA",
  txt_end_date: "Data di fine",
  txt_end_time: "Ora di fine.",
  txt_entered_the_room: "Si è aggiunto alla chiamata.",
  txt_entries: "elementi",
  txt_etco2: "EtCO₂",
  txt_ev: "EV",
  txt_fasting: "",
  txt_february: "Febbraio",
  txt_fef: "FEF",
  txt_fet: "FET",
  txt_fev: "FEV",
  txt_filter: "Filtra",
  txt_fit: "FIT",
  txt_fiv: "FIV",
  txt_fivc: "FIVC",
  txt_fvc: "FVC",
  txt_gain: "Ottieni",
  txt_glu: "Glu",
  txt_glucose: "Glucosio",
  txt_graph: "Grafico",
  txt_hba1c: "HbA1c",
  txt_hct: "HCT",
  txt_hdl: "HDL",
  txt_height: "Altezza",
  txt_hour: "Ora",
  txt_hr: "HR",
  txt_image: "Immagine",
  txt_inr: "INR",
  txt_interval: "Intervallo",
  txt_invite_participants: "Invita partecipanti",
  txt_january: "Gennaio",
  txt_july: "Luglio",
  txt_june: "Giugno",
  txt_ket: "Ket",
  txt_ketone: "Ketone",
  txt_ketone_blood: "β-Ketone(Sangue)",
  txt_ketones: "Ketones",
  txt_ldl: "LDL",
  txt_left_the_room: "Ha abbandonato la conversazione.",
  txt_leu: "Leu",
  txt_leukocytes: "Leucociti (WBC/㎕)",
  txt_login_id: "Accedi con ID",
  txt_logout: "Logout",
  txt_march: "Marzo",
  txt_may: "Maggio",
  txt_measured: "di misurazione",
  txt_measured_cardiac_rythm_events: "Misura degli eventi del ritmo cardiaco",
  txt_medical_staff: "Staff medico",
  txt_message: "Messaggio",
  txt_message_list: "Lista dei messaggi",
  txt_min: "minuto",
  txt_name: "Nome",
  txt_negative: "Negativo",
  txt_nit: "Nit",
  txt_nitrite: "Nitrite",
  txt_no: "No",
  txt_no_appointment: "Nessun appuntamento per oggi.",
  txt_no_matches: "Nessuna corrispondenza trovata per la tua ricerca",
  txt_none: "Nessuno",
  txt_normal: "Normale",
  txt_normal_sinus_rhythm: "Ritmo cardiaco normale.",
  txt_not_answering_phone:
    "Non posso rispondere al momento. Per favore, richiamare più tardi.",
  txt_november: "Novembre",
  txt_ob: "OB",
  txt_october: "Ottobre",
  txt_others: "Altri",
  txt_p_qrs_t_qrst_axis: "P/QRS/T/QRST asse",
  txt_pacemaker: "Pacemaker",
  txt_param: "Param",
  txt_participants: "Partecipanti.",
  txt_patient: "Paziente",
  txt_patient_data: "Dati paziente.",
  txt_pef: "PEF",
  txt_peft: "PEFt",
  txt_pft: "PFT",
  txt_ph: "pH",
  txt_phone_number: "Numero di telefono.",
  txt_phone_number_characters:
    "Il  numero di telefono non può includere caratteri speciali.",
  txt_phone_settings: "Impostazioni telefono.",
  txt_phone_title: "Numero di telefono.",
  txt_photo: "Foto.",
  txt_pick_time: "Scegli ora.",
  txt_pif: "PIF",
  txt_positive: "Positivo",
  txt_pqrstqrst_axis: "P/QRS/T/QRST axis",
  txt_pr: "PR",
  txt_pr_interval: "Intervallo PR",
  txt_pred: "prediction",
  txt_pro: "Pro",
  txt_protein: "Proteine",
  txt_prt_axis: "asse P/R/T",
  txt_pt: "PT",
  txt_ptinr: "PT/INR",
  txt_pulse: "Battito",
  txt_pulse_bpm: "Battito (Bpm)",
  txt_pwd: "Password",
  txt_pwd_change: "Cambia password",
  txt_pwd_confirmed: "Password Confermata",
  txt_qrs: "QRS",
  txt_qrs_duration: "QRS Durata",
  txt_qt: "QT",
  txt_qt_qtc: "QT/QTc",
  txt_qtc: "QTc",
  txt_qtr: "QTr",
  txt_range: "Intervallo",
  txt_record: "Registra",
  txt_registratered: "Registrato",
  txt_remember_me: "Memorizza dati",
  txt_reminder_email: "Promemoria via email",
  txt_reminder_sms: "Promemoria via SMS",
  txt_respiratory_rate: "Frequenza respiratoria (bpm)",
  txt_result: "Risultato",
  txt_result_actual_measurement:
    "Il risultato di pH (acidità), gravità specifica (densità relativa?) e nitrito è un valore di misurazione effettivo.",
  txt_result_type: "Tipo di risultato.",
  txt_rr: "Frequenza respiratoria",
  txt_rv5: "RV5",
  txt_september: "Settembre",
  txt_service: "Servizio",
  txt_setup_information:
    "Tu puoi impostare le informazioniper l'app Hicare. - telefono",
  txt_sex: "Sesso",
  txt_sex_age: "Sesso / Età",
  txt_sg: "SG",
  txt_show: "Visualizza",
  txt_sign_in: "Accedi",
  txt_speed: "Velocità",
  txt_spo2: "SpO₂",
  txt_start_date: "Data di inizio",
  txt_start_time: "Ora di inizio",
  txt_sv1: "SV1",
  txt_system_setup: "Impostazioni di sistema",
  txt_systolic: "Sistolico",
  txt_table: "Tabella",
  txt_tc: "TC",
  txt_test_date: "Data del test",
  txt_test_item: "Test unità",
  txt_tg: "TG",
  txt_time: "Ora",
  txt_title: "Titolo",
  txt_title_patient: "Paziente",
  txt_today: "Oggi",
  txt_total_cholesterol: "Colesterolo totale (TC)",
  txt_trace: "Traccia",
  txt_transferred: "di trasferimento",
  txt_trig: "Trig.",
  txt_type: "Tipo",
  txt_uncheck: "Deseleziona",
  txt_unconfirmed_report: "Rapporto non confermato da rivedere",
  txt_unit_measure: "Unità di misura",
  txt_units: "Unità",
  txt_upcoming_appointment: "Hai un appuntamento in programma",
  txt_uric_acid: "Acido urico",
  txt_urine_spot: "Urine(spot)",
  txt_urine_stick: "Urine(stick)",
  txt_uro: "Uro",
  txt_urobilinogen: "Urobilinogeno",
  txt_val: "Val",
  txt_value: "Valore",
  txt_vent_rate: "Ventilazione",
  txt_video: "Video",
  txt_video_call: "Videochiamata",
  txt_video_chat_stop: "Interrompere video chat",
  txt_videocall_archives: "Archivio videochiamate",
  txt_videos: "Video",
  txt_weight: "Peso",
  txt_with: "Con",
};
